import React, { useState } from 'react';
import logo from "../../assets/images/Weje-logo.png";
import walletIcons from "../../assets/images/header/supported-black.png";
import { Helmet } from "react-helmet";
import Layout from "../layout/layout";
import Register from "./register";
import { Modal } from "react-bootstrap";
import Lottie from "react-lottie";
import { isMobile, isSafari } from "react-device-detect";
import confirm from "../../assets/animation/confirm.json";
import "./index.css";

function Landing() {

    const [activeClass, setActiveClass] = useState('');
    const [idToken] = useState(localStorage.getItem("xtkn"));

    const handleMouseEnter = (className) => {
        setActiveClass(`active-item ${className}`);
    };

    const handleMouseLeave = () => {
        setActiveClass('');
    };

    const confirmLottie = {
        loop: true,
        autoplay: true,
        animationData: confirm,
    };

    return (
        <>
            <Helmet>
                <html lang='en' className='remove-header-padding' />
            </Helmet>
            <Layout>
                <div className='login account-create'>
                    <div className='login-section'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-12 mb-12'>
                                    <div className='signup-box'>
                                        <div className='login-form rigister-tab'>
                                            <img src={logo} alt="logo" className="title-mobile" />
                                            <div className='rigister-tab'>
                                                <div className='appstore-grid'>
                                                    <Register />
                                                    {/* {isMobile && isSafari ? (
                                                        <Modal
                                                            show={true}
                                                            centered
                                                            className="locked-popup better-experience" >
                                                            <Modal.Header>
                                                                <small className='p-experience'>We noticed that you're currently using Safari, which isn't fully supported. For the best experience, please use Google Chrome.</small>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <div className="locked-content">
                                                                    <div className="locked-loading">
                                                                        <Lottie options={confirmLottie} width={150} height={150} />
                                                                    </div>
                                                                </div>
                                                            </Modal.Body>
                                                        </Modal>
                                                    ) : (
                                                        <Register />
                                                    )} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`advantages ${activeClass}`}>
                                <div className="item-overlay item-overlay-top"></div>
                                <div className="item-overlay item-overlay-bottom"></div>
                                <div className="item dapp" data-id="dapp" onMouseEnter={() => handleMouseEnter('dapp')} onMouseLeave={handleMouseLeave}>
                                    <div className="icon-image"></div>
                                    <div className="text">
                                        <div className="top">
                                            <span>Live</span>
                                        </div>
                                        <div className="bottom">
                                            <span>Video chat</span>
                                        </div>
                                    </div>
                                    <div className="item-content-wrap">
                                        <div className="item-content">
                                            <div className="content-icon"></div>
                                            <div className="content-title">
                                                <span>Live</span>
                                            </div>
                                            <ol className="content-list">
                                                <li><span>Live Video & Audio Gaming.</span></li>
                                                <li><span>Real Players Worldwide.</span></li>
                                                <li><span>Join Weje Poker Community.</span></li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                                <div className="item secure" data-id="secure" onMouseEnter={() => handleMouseEnter('secure')} onMouseLeave={handleMouseLeave}>
                                    <div className="icon-image"></div>
                                    <div className="text">
                                        <div className="top">
                                            <span>Secure</span>
                                        </div>
                                        <div className="bottom">
                                            <span>No deposit</span>
                                        </div>
                                    </div>
                                    <div className="item-content-wrap">
                                        <div className="item-content">
                                            <div className="content-icon"></div>
                                            <div className="content-title">
                                                <span>Secure</span>
                                            </div>
                                            <ol className="content-list">
                                                <li><span>No Deposits Needed.</span></li>
                                                <li><span>No Registration Needed.</span></li>
                                                <li><span>Encrypted WEB3 Transactions.</span></li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                                <div className="item decentralized" data-id="decentralized" onMouseEnter={() => handleMouseEnter('decentralized')} onMouseLeave={handleMouseLeave}>
                                    <div className="icon-image"></div>
                                    <div className="text">
                                        <div className="top">
                                            <span>Decentralized</span>
                                        </div>
                                        <div className="bottom">
                                            <span>100% transparency</span>
                                        </div>
                                    </div>
                                    <div className="item-content-wrap">
                                        <div className="item-content">
                                            <div className="content-icon"></div>
                                            <div className="content-title">
                                                <span>100% Decentralized</span>
                                            </div>
                                            <ol className="content-list">
                                                <li><span>Peer to Peer Game.</span></li>
                                                <li><span>Trustless Transactions, No Intermediaries.</span></li>
                                                <li><span>Complete Control Over your Crypto.</span></li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                                <div className="item blockchain" data-id="blockchain" onMouseEnter={() => handleMouseEnter('blockchain')} onMouseLeave={handleMouseLeave}>
                                    <div className="icon-image"></div>
                                    <div className="text">
                                        <div className="top">
                                            <span>Blockchain</span>
                                        </div>
                                        <div className="bottom">
                                            <span>Polygon matic</span>
                                        </div>
                                    </div>
                                    <div className="item-content-wrap">
                                        <div className="item-content">
                                            <div className="content-icon">
                                            </div>
                                            <div className="content-title">
                                                <span>Polygon MATIC</span>
                                            </div>
                                            <div className="content-text">
                                                <ol className="content-list">
                                                    <li><span>Polygon Ensures Transactions Applications Run Smoothly.</span></li>
                                                    <li><span>You Need Polygon Matic Tokens To Play.</span></li>
                                                    <li><span>Low Gas Fees.</span></li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="appstore-grid">
                                <div className="wallet-icons">
                                    <img className="img-responsive" src={walletIcons} alt="ios" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default Landing;
