import React, { Component } from "react";
import { Button, Modal, Spinner} from "react-bootstrap";
import Follome from "../../followme/followme";
import UrlConfig from "../../../utils/ApiConfig";
import axios from "axios";
import toast from "react-hot-toast";
import Lottie from "react-lottie";
import loading from "../../../assets/animation/main_loader.json";
import Loader from "../../loader/loader";
import "./recently.css";
import { getUserInfo } from "../../../dbFetchFunctions";
import { getUserStats } from "../../../dbFetchFunctions";
import { cookieSetter } from '../../../dbFetchFunctions';

const loadingLottie = {
  loop: true,
  autoplay: true,
  animationData: loading,
};

class RecentlyPlayedWith extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signedin: this.props.signedin,
      pending: false,
      idToken: this.props.idToken,
      friendsyouknow: [],
      followmeShow: false,
      followId: "",
      followNick: "",
      followAvatar: "",
      disableBtn: "",
      userInfos: [],
      userStats: {},
      Hplayed: "",
      Friends: "",
      Level: "",
      Games: "",
      Wins: "",
      Losses: "",
    };
    this.handleConnect = this.handleConnect.bind(this);
    this.handleFollowMe = this.handleFollowMe.bind(this);
    this.followmeClose = this.followmeClose.bind(this);
  }

  fetchUserInfos = async () => {
    const userPromises = this.props.recPlayedWith.map(f => getUserInfo(f.uid));
    const fetchedUserInfos = await Promise.all(userPromises);
    this.setState({ userInfos: fetchedUserInfos });
  };

  fetchUserStats = async (Uid) => {
    const fetchedUserStats = await getUserStats(Uid);
    this.setState(prevState => ({
      userStats: {
        ...prevState.userStats,
        [Uid]: fetchedUserStats,
      },
      Hplayed: fetchedUserStats.total.HoursPlayed.toFixed(2) || 0, // Update Hplayed with HoursPlayed value
      Friends: fetchedUserStats.social.friends.toLocaleString() || 0,
      Level: fetchedUserStats.total.Level.toLocaleString() || 0,
      Games: fetchedUserStats.total.games.toLocaleString() || 0,
      Wins: fetchedUserStats.total.win.toLocaleString() || 0,
      Losses: fetchedUserStats.total.lose || 0,
    }));
  };

  async componentDidMount() {
    const { RecentlyPlayedWith: usersplayedWith } = this.props;
    this.setState({
      friendsyouknow: usersplayedWith || [], // Use an empty array if usersplayedWith is undefined
      pending: usersplayedWith && usersplayedWith.length > 0, // Check if usersplayedWith is defined
    });
    this.fetchUserInfos();
  }

  componentDidUpdate(prevProps) {
    if (this.props.recPlayedWith !== prevProps.recPlayedWith) {
      this.fetchUserInfos();
    }
  }


  refreshCookieAlert() {
      toast.success("Oops! Something went wrong", {
        duration: 4000,
        icon: '⚠️',
        style: {
          maxWidth: 800,
          fontSize: "14px",
          borderRadius: "15px",
        },
      });
  }

  
  handleConnect = async (event) => {
    var res = String(event);
    const param = res.split(",");
    const FUid = param[0];
    this.setState({
      disableBtn: FUid,
    });
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
      id: "toast-loading",
      style: {
        borderRadius: "15px",
      },
    });
    const context = this.context;
    const { baseStats } = context;
    if (baseStats) {
      this.setState({
        idToken: context.idToken,
      });
    }
    try {
      const response = await axios.get(UrlConfig.sendFriendRequest, {
        params: { frId: FUid },
        headers: { wid: localStorage.getItem("walletid") },
        withCredentials: true,
        credentials: "include",
      });
        if (response.data) {
          this.setState({
            disableBtn: "",
          });
        if (response.data.error === "missing auth token") {
            const account = localStorage.getItem("walletid");
            await cookieSetter(account, this.refreshCookieAlert, (idToken) => this.setState({ idToken }));
        } else if (response.data.error === "no error") {
          toast.success("Friend request sent!", {
            id: "toast-error",
            style: {
              maxWidth: 800,
              fontSize: "14px",
              borderRadius: "15px",
            },
          });
        } else {
          toast.error(response.data.error, {
            id: "toast-error",
            style: {
              maxWidth: 800,
              fontSize: "14px",
              borderRadius: "15px",
            },
          });
        }
  
        this.setState({
          pending: false,
        });
      }
    } catch (error) {
      console.log("Error req", error);
      this.setState({
        disableBtn: "",
      });
    }
  };  
  

  handleFollowMe(event) {
    var res = String(event);
    const param = res.split(",");
    const Uid = param[0];
    const nickname = param[1];
    const resFollowing = param[2];
    const avatar = param[3];

    this.fetchUserStats(Uid);

    this.setState({
      followmeShow: !this.state.followmeShow,
      followId: Uid,
      followNick: nickname,
      isFollowing: resFollowing,
      followAvatar: avatar,
    });
    
  }

  followmeClose() {
    this.setState({
      followmeShow: false,
    });
  }

  render() {
    const { recPlayedWith } = this.props;
    if (this.state.pending) {
      return <Loader />;
    }

    // Limit the number of users to 9
    const limitedRecPlayedWith = recPlayedWith.slice(0, 9);

    return (
      <>
        {limitedRecPlayedWith?.length > 0 && (
          <div className="recently-played">
            <div className="columns">
              <p>Recently Played With</p>
              <div className="recent-section">
                {limitedRecPlayedWith.map((f, index) => {
                  let user = this.state.userInfos.find(el => el.uid === f.uid);
                  return (
                    <div className="column" id={f.id} key={index}>
                      <div className="rcard rcard-1 card">
                        <div
                          className="rcard-top"
                          onClick={() =>
                            this.handleFollowMe([
                              f.uid,
                              user?.nickname,
                              f.isFollowing,
                              user?.photoURI,
                            ])
                          }
                        >
                          {user?.photoURI ? (
                            <img
                              src={user.photoURI}
                              alt="Avatar"
                              className="rounded--rpw"
                            />
                          ) : (
                            <Spinner animation="border" />
                          )}
                        </div>
                        <div className="rcard-info">
                          <div className="rcard-cost">
                            <div className="rcard-value">{user?.nickname}</div>
                            <small>
                              {f.game === "pokerCP_Tables" ? "Poker" : f.game}
                            </small>
                            <br />
                            <div className="rcard-month">
                              {!f.isFriend && (
                                <Button
                                  className={`btn btn-gold rpw-btn ${
                                    this.state.disableBtn === f.uid
                                      ? "disable-btn"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    this.handleConnect([f.uid, user.nickname])
                                  }
                                >
                                  Connect
                                </Button>
                              )}
                            </div>

                            <small className="ftime">
                              {f.time.toLocaleString()}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <Modal
              show={this.state.followmeShow}
              onHide={this.followmeClose}
              centered
              className="friends-popup"
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <Follome
                  followId={this.state.followId}
                  nick={this.state.followNick}
                  isFollowing={this.state.isFollowing}
                  avatar={this.state.followAvatar}
                  Hplayed={this.state.Hplayed}
                  Friends={this.state.Friends}
                  Level={this.state.Level}
                  Games={this.state.Games}
                  Wins={this.state.Wins}
                  Losses={this.state.Losses}
                />
              </Modal.Body>
            </Modal>
          </div>
        )}
      </>
    );
  }
}

export default RecentlyPlayedWith;