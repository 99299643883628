import React, { Component } from "react";
import { Modal, Table } from "react-bootstrap";
// import Pagination from 'react-bootstrap/Pagination';
import firebase from "../../firebase";
import toast from "react-hot-toast";
import Lottie from "react-lottie";
import loading from "../../assets/animation/loading.json";
import axios from "axios";
import "./tablelist.css";
import { MainContext } from "../../context";
import { Link } from "react-router-dom";

const loadingLottie = {
    loop: true,
    autoplay: true,
    animationData: loading,
  };

class Followers extends Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);
    this.state = {
      Uid: this.props.uid,
      followers: [],
      following: [],
      search: "",
      isFollowers: this.props.isFollowers,
      isActive: this.props.isFollowers,
      sureRemove: false,
      sureRemoveNick: "",
      removeUid: "",
      isClick: "",
    };
  }

  idToken;

  async componentDidMount() {
    const context = this.context;
    this.setState({
      following: context.following,
      followers: context.followers,
    });
    this.idToken = await firebase.auth().currentUser.getIdToken(true);

    if (this.state.followers.length === 0) {
      this.setState({
        isFollowers: false,
      });
    } else {
      this.setState({
        isActive: true,
      });
    }
  }

  hendlelFollow(event) {
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
        id: "toast-loading",
        duration: 6000,
        style: {
          borderRadius: "5px",
          background: "#333",
          color: "#fff",
        },
    });

    var res = String(event);
    const param = res.split(",");
    const Uid = param[0];
    const nickname = param[1];
    axios
      .get("https://follow-t3e66zpola-lz.a.run.app", {
        params: { frId: Uid },
        headers: { idtoken: this.idToken },
      })
      .then(async (response) => {
        this.setState({ isClick: "" });
        if (response.data) {
          if (
            response.data.error === "no error" &&
            response.data.success === true
          ) {
            toast.success("You're now following @" + nickname, {
                id: "toast-success",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
              });
            await axios
              .get("https://followers-table-t3e66zpola-ez.a.run.app", {
                params: { type: "follower" },
                headers: { idtoken: this.idToken },
              })
              .then((response) => {
                if (response.data.success) {
                  const context = this.context;
                  context.setFollowers(response.data.followers);
                  this.setState({
                    followers: response.data.followers,
                  });
                } else if(response.data.error !== "no error") {
					toast.error(response.data.error, {
						id: "toast-error",
						style: {
                            maxWidth: 800,
                            fontSize: "14px",
                            borderRadius: "15px",
                        },
					  });
                }
              })
              .catch((error) => {
                console.log("Error req", error);
              });
            await axios
              .get("https://followers-table-t3e66zpola-ez.a.run.app", {
                params: { type: "following" },
                headers: { idtoken: this.idToken },
              })
              .then((response) => {
                const context = this.context;
                if (response.data.success) {
                  context.setFollowing(response.data.followers);
                  this.setState({
                    following: response.data.followers,
                  });
                } else {
					toast.error(response.data.error, {
						id: "toast-error",
						style: {
                            maxWidth: 800,
                            fontSize: "14px",
                            borderRadius: "15px",
                        },
					  });
                 }
              })
              .catch((error) => {
                console.log("Error req", error);
              });
          }
          if (response.data.error === "already follower or rejected follower") {
            toast.error("You're already following @" + nickname, {
                id: "toast-error",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
            });
          }
          if (response.data.error === "You are rejected follower") {
            toast.error("@" + nickname + " has gracefully declined your request.", {
                id: "toast-error",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
            });
          }
          if (response.data.error === "already following him") {
            toast.error("You're already following @" + nickname, {
                id: "toast-error",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
            });
          }
          if (response.data.error === "you can not follow yourself") {
            toast.error("Oops! You can't follow yourself. You're already the most awesome version of you!", {
                id: "toast-error",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
            });
          }
        } else if(response.data.error !== "no error"){
            toast.error(response.data.error, {
                id: "toast-error",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
              });
          }
      })
      .catch((error) => {
        this.setState({ isClick: "" });
        console.log("Error req", error);
      });
  }

  sureRemoveClose() {
    this.setState({
      sureRemove: !this.state.sureRemove,
    });
  }

  sureRemove(event) {
    var res = String(event);
    const param = res.split(",");
    const Uid = param[0];
    const nickname = param[1];
    this.setState({
      sureRemoveNick: nickname,
      sureRemove: !this.state.sureRemove,
      removeUid: Uid,
    });
  }

  removeFollower(event) {
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
        id: "toast-loading",
        duration: 6000,
        style: {
          borderRadius: "5px",
          background: "#333",
          color: "#fff",
        },
    });

    var res = String(event);
    const param = res.split(",");
    const Uid = param[0];
    const nickname = param[1];
    axios
      .get("https://remove-follower-t3e66zpola-ew.a.run.app", {
        params: { frId: Uid },
        headers: { idtoken: this.idToken },
      })
      .then(async (response) => {
        this.setState({ isClick: "" });
        if (response.data) {
          if (
            response.data.error === "no error" &&
            response.data.success === true
          ) {
            toast.success("You have removed @" + nickname + "from your followers list.", {
                id: "toast-success",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
              }
            );
            this.sureRemoveClose();
            await axios
              .get("https://followers-table-t3e66zpola-ez.a.run.app", {
                params: { type: "follower" },
                headers: { idtoken: this.idToken },
              })
              .then((response) => {
                if (response.data.success) {
                  const context = this.context;
                  context.setFollowers(response.data.followers);
                  this.setState({
                    followers: response.data.followers,
                  });
                } else if(response.data.error !== "no error"){
					toast.error(response.data.error, {
						id: "toast-error",
						style: {
                            maxWidth: 800,
                            fontSize: "14px",
                            borderRadius: "15px",
                        },
					  });
                 }
              })
              .catch((error) => {
                console.log("Error req", error);
              });
          }

          if (response.data.error === "is not follower OR is already removed") {
            toast.error("@" + nickname + " has already been removed from your followers list.", {
                id: "toast-error",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
            });
          }
        } else if(response.data.error !== "no error"){
            toast.error(response.data.error, {
                id: "toast-error",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
              });}
      })
      .catch((error) => {
        this.setState({ isClick: "" });
        console.log("Error req", error);
      });
  }

  handleChange(event) {
    // Get event search value
    let searchValue = event.target.value;
    // Set the state to trigger a re-rendering
    this.setState({ search: searchValue });
  }

  render() {
    // Searching in follow => Filter results by nick.
    let followers = this.state.followers,
      searchString = this.state.search.trim().toLowerCase();
    if (searchString.length > 0) {
      followers = this.state.followers.filter((e) =>
        e.nick.toLowerCase().match(searchString)
      );
    }

    return (
      <div className="tablelist-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {!this.state.isFollowers ? (
                <small>You have no followers</small>
              ) : (
                <div
                  className={
                    this.state.isActive ? "follow-search" : "disable-search"
                  }
                >
                  <input
                    id="mySearchFollowers"
                    className="form-control"
                    type="text"
                    name="search"
                    autoComplete="off"
                    placeholder="Searching..."
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>
              )}
              <div className="tablelist-content">
                <div className="tablelist-table">
                  <Table striped bordered hover variant="dark">
                  <thead>
                        <th>Avatar</th>
                        <th>Name</th>
                        <th></th>
                        <th></th>
                    </thead>
                    <tbody>
                      {followers.map((f, index) => (
                        <tr key={f.uid}>
                          <td className="followtd">
                            <Link to={`/userprofile/${f.uid}`}>
							<img
                              className="followingImg"
                              src={f.avatar}
                              alt="followingImg"
                            />
							</Link>
                          </td>
                          <td className="followtd">{f.nick}</td>
                          <td className="followtd text-center" style={{ color: '#8461dc' }} >
                            {this.state.following?.find(
                              (el) => el.uid === f.uid
                            ) ? (
                              "Following"
                            ) : (
                              <button
                                className="followBtn btn btn-outline-warning btn-sm"
                                disabled={this.state.isClick === f.uid}
                                onClick={() => {
                                  this.setState({ isClick: f.uid });
                                  this.hendlelFollow([f.uid, f.nick]);
                                }}
                              >
                                Follow
                              </button>
                            )}
                          </td>
                          <td className="followtd text-center">
                            <button
                              className="followBtn btn btn-outline-secondary btn-sm"
                              disabled={this.state.isClick === f.uid}
                              onClick={() => {
                                this.setState({ isClick: f.uid });
                                this.sureRemove([f.uid, f.nick]);
                              }}
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <Modal
                    show={this.state.sureRemove}
                    onHide={() => this.sureRemoveClose()}
                    centered
                  >
                    <Modal.Header closeButton className="action-popup">
                      <Modal.Body>
                        <p style={{ color: "#000000" }}>
                        <b>Are you sure?</b>
                        </p>
                
                        <small style={{ color: "#000000" }}>
                          Weje will keep in secret @
                          <b>@{this.state.sureRemoveNick}</b> has been removed from your
                          followers list, however he could not be your follower ever again.
                        </small>
                        <br></br>
                        <br></br>
                        <button
                          className="followBtn btn btn-outline-secondary btn-sm"
                          onClick={() =>
                            this.removeFollower([
                              this.state.removeUid,
                              this.state.sureRemoveNick,
                            ])
                          }
                        >
                          Remove
                        </button>
                      </Modal.Body>
                    </Modal.Header>
                  </Modal>

                                {/* 
                                    <div className="pagination-view">
                                        <Pagination>
                                            <Pagination.First />
                                            <Pagination.Prev />
                                            <Pagination.Item>{1}</Pagination.Item>
                                            <Pagination.Item>{2}</Pagination.Item>
                                            <Pagination.Item active>{3}</Pagination.Item>
                                            <Pagination.Item>{4}</Pagination.Item>
                                            <Pagination.Item>{5}</Pagination.Item>
                                            <Pagination.Next />
                                            <Pagination.Last />
                                        </Pagination>
                                    </div>
                                */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Followers;
