import React from "react";
import { Spinner, DropdownButton, Dropdown, Modal } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { timeDifference } from "../../../dbFetchFunctions";
import ReportPostPopup from "../../reportpopup/reportpost";
import OriginalPost from "./originalPost";
import like from "../../../assets/images/profile/like.svg";
import share from "../../../assets/images/profile/share.svg";
import comments from "../../../assets/images/profile/comments.svg";
import EmojiPopup from "./emojiPopup";
import MaticPriceChart from "./MaticPriceChart";
import { MainContext } from "../../../context";
import axios from "axios";
import toast from "react-hot-toast";
import Lottie from "react-lottie";
import loading from "../../../assets/animation/loading.json";

const loadingLottie = {
    loop: true,
    autoplay: true,
    animationData: loading,
  };

class Post extends React.Component {
  static contextType = MainContext;

  constructor() {
    super();
    this.state = {
      bool: "",
      date_start: "",
      champions: [],
      europa: [],
      myGames: [],
      newcomment: "",
      postLike: "",
      commentLike: "",
      replyLike: "",
      replyDislike: "",
      commentDislike: "",
      countValue: 0,
      redirect: "",
      newreply: "",
      view: false,
      reportOwnerId: "",
      reportFeedId: "",
      emojiPopup: false,
	  disableBtn: '',
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  async componentDidMount() {
    // Print out today date in format dd-mm-yyyy
    var dateObj = new Date()
      .toISOString()
      .replace(/T/, " ")
      .replace(/\..+/, "");
    dateObj = dateObj.split(" ")[0];
    this.date_start = dateObj;
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef?.current?.contains(event.target)) {
      this.setState({ emojiPopup: false });
    }
  }

  async handlePostLike(value, likers, postUserId) {
	this.setState({disableBtn: value})
    if (likers.find((el) => el.uid === localStorage.getItem("uid"))) {
      this.setState({ countValue: -1 });
    } else {
      this.setState({ countValue: 1 });
    }
    if (this.state.postLike === value) {
      this.setState({ postLike: "" });
    } else {
      this.setState({ postLike: value });
    }
    const result = await this.props.handleLikeCommentReply(
      "like",
      value,
      undefined,
      undefined,
      postUserId
    );
    if (result) {
      this.setState({ postLike: "" });
	  this.setState({disableBtn: ''})
    }
  }

  handleClose() {
    this.setState({ view: false });
  }

  handleEmoji = () => {
    this.setState({ emojiPopup: this.props.replyShow ? "reply" : "comment" });
  };

  handleSelectEmojiReply = (emoji) => {
    this.setState({ newreply: this.state.newreply + emoji });
  };

  handleSelectEmojiComment = (emoji) => {
    this.setState({ newcomment: this.state.newcomment + emoji });
  };

  handleShow(feedId, ownerId) {
    this.setState({ view: true, reportFeedId: feedId, reportOwnerId: ownerId });
  }

  async handleCommentLike(postId, commentId, likers, postUserId) {
	this.setState({disableBtn: commentId})
    if (likers.find((el) => el.uid === localStorage.getItem("uid"))) {
      this.setState({ countValue: -1 });
    } else {
      this.setState({ countValue: 1 });
    }
    if (this.state.commentLike === commentId) {
      this.setState({ commentLike: "" });
    } else {
      this.setState({ commentLike: commentId });
    }
    const result = await this.props.handleLikeCommentReply(
      "like",
      postId,
      commentId,
      undefined,
      postUserId
    );
    if (result) {
      this.setState({ commentLike: "" });
	  this.setState({disableBtn: ''})
    }
  }

  async handleReplyLike(postId, commentId, replyId, likers, postUserId) {
	this.setState({disableBtn: replyId})
    if (likers.find((el) => el.uid === localStorage.getItem("uid"))) {
      this.setState({ countValue: -1 });
    } else {
      this.setState({ countValue: 1 });
    }
    if (this.state.replyLike === replyId) {
      this.setState({ replyLike: "" });
    } else {
      this.setState({ replyLike: replyId });
    }
    const result = await this.props.handleLikeCommentReply(
      "like",
      postId,
      commentId,
      replyId,
      postUserId
    );
    if (result) {
      this.setState({ replyLike: "" });
	  this.setState({disableBtn: ''})
    }
  }
  async handleReplyDislike(postId, commentId, replyId, dislikers,postUserId) {
	this.setState({disableBtn: replyId})
    if (dislikers.find((el) => el.uid === localStorage.getItem("uid"))) {
      this.setState({ countValue: -1 });
    } else {
      this.setState({ countValue: 1 });
    }
    if (this.state.replyDislike === replyId) {
      this.setState({ replyDislike: "" });
    } else {
      this.setState({ replyDislike: replyId });
    }
    const result = await this.props.handleLikeCommentReply(
      "dislike",
      postId,
      commentId,
      replyId,
      postUserId
    );
    if (result) {
      this.setState({ replyDislike: "" });
	  this.setState({disableBtn: ''})
    }
  }

  async handleCommentDislike(postId, commentId, dislikers,postUserId) {
	this.setState({disableBtn: commentId})
    if (dislikers.find((el) => el.uid === localStorage.getItem("uid"))) {
      this.setState({ countValue: -1 });
    } else {
      this.setState({ countValue: 1 });
    }
    if (this.state.commentDislike === commentId) {
      this.setState({ commentDislike: "" });
    } else {
      this.setState({ commentDislike: commentId });
    }
    const result = await this.props.handleLikeCommentReply(
      "dislike",
      postId,
      commentId,
      undefined,
      postUserId
    );
    if (result) {
      this.setState({ commentDislike: "" });
	  this.setState({disableBtn: ''})
    }
  }

  async hidePostComntReply(
    postId,
    commentId,
    replyId,
    postInd,
    commentInd,
    replyInd
  ) {
    const { post } = this.context;
    let copyPost = JSON.parse(JSON.stringify(post));
    let parmsObj = { postId };

    // for hiding the post after response success.
    if (!commentId && !replyId) {
      copyPost[postInd].isHide = true;
    }
    // for hiding the comment- query and state manage
    if (commentId && !replyId) {
      parmsObj.comId = commentId;
      copyPost[postInd].commentsArr[commentInd].isHide = true;
    }
    // for hiding the reply - query and state manage
    if (commentId && replyId) {
      parmsObj.comId = commentId;
      parmsObj.repId = replyId;
      copyPost[postInd].commentsArr[commentInd].repliesArr[
        replyInd
      ].isHide = true;
    }
    const successMsg =
      commentId && !replyId
        ? "Comment"
        : commentId && replyId
        ? "Reply"
        : "Post";
        toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
            id: "toast-loading",
            duration: 6000,
            style: {
              borderRadius: "5px",
              background: "#333",
              color: "#fff",
            },
        });
    const res = await axios.get(
      "https://del-user-feed-t3e66zpola-uc.a.run.app/hideContent",
      {
        params: parmsObj,
        headers: {
          idtoken: this.props.idToken,
        },
      }
    );
    if (res.data.success) {
      // setting the copy where data manuplate in above checks
      const context = this.context;
      context.setPostData(copyPost);
      toast.success(`${successMsg} hide`, {
        id: "toast-success",
        style: {
        	maxWidth: 800,
  			fontSize: "14px",
  			borderRadius: "15px",
        },
      });
    } else if (res.data.error !== "no error") {
      toast.error(res.data.error, {
        id: "toast-error",
        style: {
        	maxWidth: 800,
  			fontSize: "14px",
  			borderRadius: "15px",
        },
      });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <div className='post-page'>
        {this.context.post.length ? (
          this.context.post?.map((post, postInd) => (
            <>
              {!post?.isHide ? (
                <>
                  <div className='filter-card' key={post.id}>
                    <div className='filter-matches'>
                      <div className='report-post-box'>
                        <div
                          className='post-user'
                          onClick={() =>
                            this.setState({
                              redirect: "/userprofile/" + post.uid,
                            })
                          }>
                          <img src={post.photo} alt='user-profile' />
                          <div className='user-name'>
                            {post.nickname}{" "}
                            <p className='post-time'>
                              {timeDifference(post.date)} Updated
                            </p>
                          </div>
                        </div>
                        <div className='report-post'>
                          <DropdownButton
                            id='dropdown-basic-button'
                            title={<i className='las la-ellipsis-h'></i>}>
                            <Dropdown.Item
                              onClick={() =>
                                this.handleShow(post.id, post.uid)
                              }>
                              <div className='reort-post-item'>
                                <i
                                  className='fa fa-exclamation-triangle'
                                  aria-hidden='true'></i>
                                <div className='reort-post-item-content'>
                                  <h6>Report Post</h6>
                                  <p>I'm concerned about this post</p>
                                </div>
                              </div>
                            </Dropdown.Item>
                            {post.uid === this.props.uid ? (
                              <Dropdown.Item
                                onClick={() => {
                                  if (post.uid === this.props.uid)
                                    this.props.handleDeletePost(post.id);
                                }}>
                                <div className='reort-post-item'>
                                  <i
                                    className='fa fa-trash-o'
                                    aria-hidden='true'></i>
                                  <div className='reort-post-item-content'>
                                    <h6>Delete Post</h6>
                                  </div>
                                </div>
                              </Dropdown.Item>
                            ) : (
                              ""
                            )}
                            {post?.uid !== this.props.uid ? (
                              <>
                                {" "}
                                <Dropdown.Item
                                  onClick={() =>
                                    this.hidePostComntReply(
                                      post.id,
                                      undefined,
                                      undefined,
                                      postInd
                                    )
                                  }>
                                  <div className='reort-post-item'>
                                    <i
                                      className='fa fa-eye-slash'
                                      aria-hidden='true'></i>
                                    <div className='reort-post-item-content'>
                                      <h6>Hide Post</h6>
                                    </div>
                                  </div>
                                </Dropdown.Item>
                              </>
                            ) : (
                              ""
                            )}
                          </DropdownButton>
                        </div>
                      </div>
                      <div className='post-content'>
                        {post.text.split(": ")[1] ? (
                          <div>
                            <p
                              onClick={() =>
                                this.setState({
                                  redirect: `/feed-detail/${post.id}`,
                                })
                              }>
                              {post.text.split(": ")[0]}
                            </p>
                            <div className='original-post'>
                              <OriginalPost
                                uid={this.props.uid}
                                postId={post.text.split(": /feed-detail/")[1]}
                              />
                            </div>
                          </div>
                        ) : (
                          <p
                            onClick={() =>
                              this.setState({
                                redirect: `/feed-detail/${post.id}`,
                              })
                            }>
                            {post.text}
                          </p>
                        )}
                      </div>
                      <div className='post-activity'>
                        <div className={`post-likes ${this.state.disableBtn === post.id ? 'disable-btn' : ''}`}>
                          <span
                            onClick={() =>
                              this.handlePostLike(
                                post.id,
                                post.likers,
                                post.uid
                              )
                            }>
                            {/*<i
                      className={
                        post.likers.find((el) => el.uid === this.props.uid)
                          ? `fa fa-heart`
                          : `fa fa-heart-o`
                      }
                      aria-hidden="true"
                    ></i>*/}
                            <img src={like} alt='like' />
                            {this.state.postLike === post.id
                              ? post.likes + this.state.countValue
                              : post.likes}
                          </span>
                        </div>
                        <div
                          className='post-comments'
                          style={
                            !post.allowCom ? { visibility: "hidden" } : {}
                          }>
                          <span
                            onClick={() => {
                              this.props.handleCommentShow(post.id);
                            }}>
                            {/*<i className="fa fa-comment" aria-hidden="true"></i>*/}
                            <img src={comments} alt='like' />
                            {post.comments}
                          </span>
                        </div>
                        <div className='post-share'>
                          <span
                            onClick={() =>
                              this.props.addPost("share", post.id)
                            }>
                            {/*<i className="fa fa-share-square-o" aria-hidden="true"></i>{" "}*/}
                            <img src={share} alt='like' />0
                          </span>
                        </div>
                      </div>
                    </div>

                    {this.props.show === post.id ? (
                      <div className='feed-comments'>
                        <div className='body_comment'>
                          <div className='row'>
                            <div className='avatar_comment col-md-1'>
                              <img src={this.props.img} alt='avatar' />
                            </div>
                            <div className='box_comment col-md-11'>
                              <textarea
                                className='commentar'
                                placeholder='Add a comment...'
                                onChange={(e) =>
                                  this.setState({ newcomment: e.target.value })
                                }
                                value={this.state.newcomment}></textarea>
                              <div className='emoji-section'>
                                <div className='emoji-button'>
                                  <i
                                    className='las la-grin-beam'
                                    onClick={this.handleEmoji}></i>
                                </div>
                                {this.state.emojiPopup === "comment" && (
                                  <EmojiPopup
                                    ref={this.wrapperRef}
                                    handleSelectEmoji={
                                      this.handleSelectEmojiComment
                                    }
                                  />
                                )}
                              </div>

                              <div className='box_post'>
                                <button
                                  type='button'
                                  value='1'
                                  onClick={async () => {
                                    let res = await this.props.handleComment(
                                      post.id,
                                      this.state.newcomment
                                    );
                                    if (res) this.setState({ newcomment: "" });
                                  }}
                                  disabled={this.props.rotating}>
                                  {this.props.rotating === "comment" ? (
                                    <Spinner animation='border' />
                                  ) : (
                                    <i className='lab la-telegram-plane'></i>
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <ul id='list_comment' className='col-md-12'>
                              {post.commentsArr
                                .sort(
                                  (a, b) => new Date(b.date) - new Date(a.date)
                                )
                                .map((comment, commentInd) => (
                                  <>
                                    {!comment?.isHide ? (
                                      <>
                                        <li
                                          className='box_result row'
                                          key={comment.id}>
                                          <div className='user-comments-details'>
                                            <div
                                              className='avatar_comment'
                                              onClick={() =>
                                                this.setState({
                                                  redirect: `/userProfile/${comment.uid}`,
                                                })
                                              }>
                                              <img
                                                src={comment.photo}
                                                alt='avatar'
                                              />
                                              <p
                                                onClick={() =>
                                                  this.setState({
                                                    redirect: `/userProfile/${comment.uid}`,
                                                  })
                                                }>
                                                {comment.nickname}
                                              </p>
                                            </div>
                                            {comment?.uid !== this.props.uid ? (
                                              <div className='report-post'>
                                                <DropdownButton
                                                  id='dropdown-basic-button'
                                                  title={
                                                    <i
                                                      className='fa fa-ellipsis-v'
                                                      aria-hidden='true'></i>
                                                  }>
                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      this.hidePostComntReply(
                                                        post.id,
                                                        comment.id,
                                                        undefined,
                                                        postInd,
                                                        commentInd
                                                      )
                                                    }>
                                                    <div className='reort-post-item'>
                                                      <i
                                                        className='fa fa-eye-slash'
                                                        aria-hidden='true'></i>
                                                      <div className='reort-post-item-content'>
                                                        <h6>Hide Comment</h6>
                                                      </div>
                                                    </div>
                                                  </Dropdown.Item>
                                                </DropdownButton>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <div className='result_comment'>
                                            <p>{comment.text}</p>
                                            <div className='tools_comment'>
                                              <div className='likes-box'>
                                                <div className={`like-section ${this.state.disableBtn === comment.id ? 'disable-btn' : ''}`}>
                                                  <span
                                                    className='like'
                                                    onClick={() =>
                                                      this.handleCommentLike(
                                                        post.id,
                                                        comment.id,
                                                        comment.likers,
                                                        post.uid
                                                      )
                                                    }>
                                                    <i
                                                      className={
                                                        comment.likers.find(
                                                          (el) =>
                                                            el.uid ===
                                                            this.props.uid
                                                        )
                                                          ? `fa fa-thumbs-up`
                                                          : `fa fa-thumbs-o-up`
                                                      }></i>{" "}
                                                    <span className='count'>
                                                      {this.state
                                                        .commentLike ===
                                                      comment.id
                                                        ? comment.likes +
                                                          this.state.countValue
                                                        : comment.likes}
                                                    </span>
                                                  </span>{" "}
                                                </div>

                                                <div className={`like-section ${this.state.disableBtn === comment.id ? 'disable-btn' : ''}`}>
                                                  <span
                                                    className='like'
                                                    onClick={() =>
                                                      this.handleCommentDislike(
                                                        post.id,
                                                        comment.id,
                                                        comment.dislikers,
                                                        post.uid
                                                      )
                                                    }>
                                                    <i
                                                      className={
                                                        comment.dislikers.find(
                                                          (el) =>
                                                            el.uid ===
                                                            this.props.uid
                                                        )
                                                          ? `fa fa-thumbs-down`
                                                          : `fa fa-thumbs-o-down`
                                                      }></i>{" "}
                                                    <span className='count'>
                                                      {this.state
                                                        .commentDislike ===
                                                      comment.id
                                                        ? comment.dislikes +
                                                          this.state.countValue
                                                        : comment.dislikes}
                                                    </span>
                                                  </span>{" "}
                                                </div>
                                                <div
                                                  className='like-section'
                                                  onClick={() =>
                                                    this.props.handleReplyShow(
                                                      comment.id
                                                    )
                                                  }>
                                                  <span className='like'></span>
                                                  <i className='fa fa-comment'></i>{" "}
                                                  <span className='count'>
                                                    {comment.replies}
                                                  </span>
                                                </div>
                                                <div className='like-section'>
                                                  <span>
                                                    {" " +
                                                      timeDifference(
                                                        comment.date
                                                      )}
                                                  </span>
                                                </div>
                                              </div>
                                              {this.props.replyShow ===
                                              comment.id ? (
                                                <>
                                                  <div className='row'>
                                                    <div className='avatar_comment col-md-1'>
                                                      <img
                                                        src={this.props.img}
                                                        alt='avatar'
                                                      />
                                                    </div>
                                                    <div className='box_comment col-md-11'>
                                                      <textarea
                                                        className='commentar'
                                                        placeholder='Reply to comment...'
                                                        onChange={(e) =>
                                                          this.setState({
                                                            newreply:
                                                              e.target.value,
                                                          })
                                                        }
                                                        value={
                                                          this.state.newreply
                                                        }
                                                        maxLength={
                                                          150
                                                        }></textarea>
                                                      <div className='emoji-section'>
                                                        <div className='emoji-button'>
                                                          <i
                                                            className='las la-grin-beam'
                                                            onClick={
                                                              this.handleEmoji
                                                            }></i>
                                                        </div>
                                                        {this.state
                                                          .emojiPopup ===
                                                          "reply" && (
                                                          <EmojiPopup
                                                            ref={
                                                              this.wrapperRef
                                                            }
                                                            handleSelectEmoji={
                                                              this
                                                                .handleSelectEmojiReply
                                                            }
                                                          />
                                                        )}
                                                      </div>
                                                      <div className='box_post'>
                                                        <button
                                                          type='button'
                                                          value='1'
                                                          onClick={async () => {
                                                            let res =
                                                              await this.props.handleComment(
                                                                post.id,
                                                                this.state
                                                                  .newreply,
                                                                comment.id,
                                                                "reply"
                                                              );
                                                            if (res)
                                                              this.setState({
                                                                newreply: "",
                                                              });
                                                          }}
                                                          disabled={
                                                            this.props.rotating
                                                          }>
                                                          {this.props
                                                            .rotating ===
                                                          "reply" ? (
                                                            <Spinner animation='border' />
                                                          ) : (
                                                            <i className='lab la-telegram-plane'></i>
                                                          )}
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <ul className='child_replay'>
                                                    {comment?.repliesArr
                                                      ?.sort(
                                                        (a, b) =>
                                                          new Date(b.date) -
                                                          new Date(a.date)
                                                      )
                                                      .map(
                                                        (reply, replyInd) => (
                                                          <>
                                                            {!reply?.isHide ? (
                                                              <>
                                                                <li
                                                                  className='box_reply row'
                                                                  key={
                                                                    reply.id
                                                                  }>
                                                                  <div className='user-comments-details'>
                                                                    <div
                                                                      className='avatar_comment'
                                                                      onClick={() =>
                                                                        this.setState(
                                                                          {
                                                                            redirect: `/userProfile/${reply.uid}`,
                                                                          }
                                                                        )
                                                                      }>
                                                                      <img
                                                                        src={
                                                                          reply.photo
                                                                        }
                                                                        alt='avatar'
                                                                      />
                                                                      <p
                                                                        onClick={() =>
                                                                          this.setState(
                                                                            {
                                                                              redirect: `/userProfile/${reply.uid}`,
                                                                            }
                                                                          )
                                                                        }>
                                                                        {
                                                                          reply.nickname
                                                                        }
                                                                      </p>
                                                                    </div>
                                                                    {reply?.uid !==
                                                                    this.props
                                                                      .uid ? (
                                                                      <div className='report-post'>
                                                                        <DropdownButton
                                                                          id='dropdown-basic-button'
                                                                          title={
                                                                            <i
                                                                              className='fa fa-ellipsis-v'
                                                                              aria-hidden='true'></i>
                                                                          }>
                                                                          <Dropdown.Item
                                                                            onClick={() =>
                                                                              this.hidePostComntReply(
                                                                                post.id,
                                                                                comment.id,
                                                                                reply.id,
                                                                                postInd,
                                                                                commentInd,
                                                                                replyInd
                                                                              )
                                                                            }>
                                                                            <div className='reort-post-item'>
                                                                              <i
                                                                                className='fa fa-eye-slash'
                                                                                aria-hidden='true'></i>
                                                                              <div className='reort-post-item-content'>
                                                                                <h6>
                                                                                  Hide
                                                                                  Reply
                                                                                </h6>
                                                                              </div>
                                                                            </div>
                                                                          </Dropdown.Item>
                                                                        </DropdownButton>
                                                                      </div>
                                                                    ) : (
                                                                      ""
                                                                    )}
                                                                  </div>
                                                                  <div className='result_comment'>
                                                                    <p>
                                                                      {
                                                                        reply.text
                                                                      }
                                                                    </p>
                                                                    <div className='tools_comment'>
                                                                      <div className='likes-box'>
                                                                        <div
																		  className={`like-section ${this.state.disableBtn === reply.id ? 'disable-btn' : ''}`}
                                                                          onClick={() =>
                                                                            this.handleReplyLike(
                                                                              post.id,
                                                                              comment.id,
                                                                              reply.id,
                                                                              reply.likers,
                                                                              post.uid
                                                                            )
                                                                          }>
                                                                          <i
                                                                            className={
                                                                              comment.likers.find(
                                                                                (
                                                                                  el
                                                                                ) =>
                                                                                  el.uid ===
                                                                                  this
                                                                                    .props
                                                                                    .uid
                                                                              )
                                                                                ? `fa fa-thumbs-up`
                                                                                : `fa fa-thumbs-o-up`
                                                                            }></i>{" "}
                                                                          <span className='like'>
                                                                            {this
                                                                              .state
                                                                              .replyLike ===
                                                                            reply.id
                                                                              ? reply.likes +
                                                                                this
                                                                                  .state
                                                                                  .countValue
                                                                              : reply.likes}
                                                                          </span>
                                                                        </div>
                                                                        <div className={`like-section ${this.state.disableBtn === reply.id ? 'disable-btn' : ''}`}  onClick={() =>
                                                                              this.handleReplyDislike(
                                                                                post.id,
                                                                                comment.id,
                                                                                reply.id,
                                                                                reply.dislikers,
                                                                                post.uid
                                                                              )
                                                                            }>
                                                                          <i
                                                                            className={
                                                                              reply.dislikers.find(
                                                                                (
                                                                                  el
                                                                                ) =>
                                                                                  el.uid ===
                                                                                  this
                                                                                    .props
                                                                                    .uid
                                                                              )
                                                                                ? `fa fa-thumbs-down`
                                                                                : `fa fa-thumbs-o-down`
                                                                            }></i>{" "}
                                                                          <span
                                                                            className='like'
                                                                           >
                                                                            {this
                                                                              .state
                                                                              .replyDislike ===
                                                                            reply.id
                                                                              ? reply.dislikes +
                                                                                this
                                                                                  .state
                                                                                  .countValue
                                                                              : reply.dislikes}
                                                                          </span>
                                                                        </div>
                                                                        <div className='like-section'>
                                                                          <span>
                                                                            {" " +
                                                                              timeDifference(
                                                                                reply.date
                                                                              )}
                                                                          </span>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    <ul className='child_replay'></ul>
                                                                  </div>
                                                                </li>
                                                              </>
                                                            ) : (
                                                              ""
                                                            )}
                                                          </>
                                                        )
                                                      )}
                                                  </ul>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </li>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          ))
        ) : (
          <div className='filter-card no-post'>
            <div className='filter-matches'>
              <div className='post-content'>
                <MaticPriceChart />
              </div>
            </div>
          </div>
        )}
        <Modal
          show={this.state.view}
          onHide={this.handleClose}
          centered
          className='report-popup'>
          <Modal.Header closeButton> </Modal.Header>
          <Modal.Body>
            <ReportPostPopup
              feedId={this.state.reportFeedId}
              ownerId={this.state.reportOwnerId}
              idToken={this.props.idToken}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Post;
