import React, { useRef, useEffect, useState, useContext } from "react";
import {
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  Spinner,
} from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import Layout from "../../layout/layout";
import { getFeed, timeDifference } from "../../../dbFetchFunctions";
import firebase from "../../../firebase";
import Loader from "../../loader/loader";
import axios from "axios";
import toast from "react-hot-toast";
import ReportPostPopup from "../../reportpopup/reportpost";
import EmojiPopup from "./emojiPopup";
import like from "../../../assets/images/profile/like.svg";
import share from "../../../assets/images/profile/share.svg";
import comments from "../../../assets/images/profile/comments.svg";
import Lottie from "react-lottie";
import loading from "../../../assets/animation/loading.json";
import { visibilityIcon } from "../../../helpers/formatters";
import { MainContext } from "../../../context";

const loadingLottie = {
  loop: true,
  autoplay: true,
  animationData: loading,
};


const FeedDetails = () => {
  const history = useHistory();
  const { token, firebaseUser: user } = useContext(MainContext);
  const { uid } = user || {};
  const { postId, uid: feedUid } = useParams();
  const [myImg, setMyImg] = useState();
  const [post, setPost] = useState();
  const [loading, setLoading] = useState(true);
  const [postLike, setPostLike] = useState("");
  const [commentLike, setCommentLike] = useState("");
  const [replyLike, setReplyLike] = useState("");
  const [replyDislike, setreplyDislike] = useState("");
  const [commentDislike, setcommentDislike] = useState("");
  const [countValue, setCountValue] = useState(0);
  const [replyShow, setReplyShow] = useState("");
  const [rotating, setRotating] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [newReply, setNewReply] = useState("");
  const [closeCreatePost, setCloseCreatePost] = useState("");
  const [privacyShow, setPrivacyShow] = useState(false);
  const [privacy, setPrivacy] = useState({ type: "all", label: "Anyone" });
  const [newpost, setNewPost] = useState("");
  const [allowCom, setAllowCom] = useState(true);
  const [selectedPost, setSelectedPost] = useState("");
  const [gameCount, setGameCount] = useState(0);
  const [view, setView] = useState(false);
  const [reportFeedId, setReportFeedId] = useState("");
  const [reportOwnerId, setReportOwnerId] = useState("");
  const [emojiPopup, setEmojiPopup] = useState("");
  const [disableBtn, setDisableBtn] = useState("");

  const wrapperRef = useRef();

  useEffect(() => {
    const checkIsLoggedIn = async () => {
      if (user) {
        const hdd = firebase.storage();
        const gsRef = hdd.refFromURL(user.photoURL);
        await gsRef.getDownloadURL().then((image) => setMyImg(image));
      }
    };
    if (localStorage.getItem("xtkn") && localStorage.getItem("deviceId"))
      checkIsLoggedIn();
  }, [user]);

  useEffect(() => {
    const data = async () => {
      const { post, games } = await getFeed(uid, postId);
      setLoading(false);
      setPost(post);
      setGameCount(games);
    };
    if (uid && postId) data();
  }, [postId, feedUid, uid, token]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef && !wrapperRef?.current?.contains(event.target)) {
        setEmojiPopup(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleComment = async (id, text, commentId, action = "com") => {
    if (text.length && text.length < 151) {
      if (commentId) setRotating("reply");
      else setRotating("comment");
      const res = await axios.get(
        "https://create-comment-reply-t3e66zpola-uc.a.run.app/",
        {
          params: {
            action,
            text,
            comId: commentId,
            feedUid: post.uid,
          },
          headers: {
            Idtoken: token,
            Postid: id,
          },
        }
      );
      if (res.data.error === "no error") {
        const { post, games } = await getFeed(uid, postId);
        setPost(post);
        setGameCount(games);
      } else {
        toast.error(res.data.error, {
          id: "toast-error",
          style: {
        	maxWidth: 800,
  			fontSize: "14px",
  			borderRadius: "15px",
           	},
        });
      }
      setReplyShow("");
      setNewComment("");
      setNewReply("");
      setRotating(false);
      return true;
    } else {
      toast.error(
        action === "com"
          ? "Comment text is required and length must less than 150"
          : "Reply text is required and length must less than 150",
        {
          id: action,
          style: {
        	maxWidth: 800,
  			fontSize: "14px",
  			borderRadius: "15px",
           	},
        }
      );
    }
  };

  const handleLikeCommentReply = async (
    action,
    id,
    commentId,
    replyId,
    postUserId
  ) => {
    const res = await axios.get(
      "https://like-setter-t3e66zpola-ue.a.run.app/",
      {
        params: {
          action,
          comId: commentId,
          replyId,
          feedUid: postUserId,
        },
        headers: {
          Idtoken: token,
          Postid: id,
        },
      }
    );
    if (res.data.error === "no error") {
      const { post, games } = await getFeed(uid, postId);
      setPost(post);
      setGameCount(games);
    } else {
      toast.error(res.data.error, {
        id: "toast-error",
        style: {
        	maxWidth: 800,
  			fontSize: "14px",
  			borderRadius: "15px",
        },
      });
    }
    return true;
  };

  const handlePostLike = async (value, likers, postUserId) => {
    setDisableBtn(value);
    if (likers.find((el) => el.uid === localStorage.getItem("uid"))) {
      setCountValue(-1);
    } else {
      setCountValue(1);
    }
    if (postLike === value) {
      setPostLike("");
    } else {
      setPostLike(value);
    }
    const result = await handleLikeCommentReply(
      "like",
      value,
      undefined,
      undefined,
      postUserId
    );
    if (result) {
      setPostLike("");
      setDisableBtn("");
    }
  };
  const handleCommentLike = async (postId, commentId, likers, postUserId) => {
    setDisableBtn(commentId);
    if (likers.find((el) => el.uid === localStorage.getItem("uid"))) {
      setCountValue(-1);
    } else {
      setCountValue(1);
    }
    if (commentLike === commentId) {
      setCommentLike("");
    } else {
      setCommentLike(commentId);
    }
    const result = await handleLikeCommentReply(
      "like",
      postId,
      commentId,
      undefined,
      postUserId
    );
    if (result) {
      setCommentLike("");
      setDisableBtn("");
    }
  };
  const handleCommentDislike = async (
    postId,
    commentId,
    dislikers,
    postUserId
  ) => {
    setDisableBtn(commentId);
    if (dislikers.find((el) => el.uid === localStorage.getItem("uid"))) {
      setCountValue(-1);
    } else {
      setCountValue(1);
    }
    if (commentDislike === commentId) {
      setcommentDislike("");
    } else {
      setcommentDislike(commentId);
    }
    const result = await handleLikeCommentReply(
      "dislike",
      postId,
      commentId,
      undefined,
      postUserId
    );
    if (result) {
      setcommentDislike("");
      setDisableBtn("");
    }
  };
  const handleReplyLike = async (
    postId,
    commentId,
    replyId,
    likers,
    postUserId
  ) => {
    setDisableBtn(replyId);
    if (likers.find((el) => el.uid === localStorage.getItem("uid"))) {
      setCountValue(-1);
    } else {
      setCountValue(1);
    }
    if (replyLike === replyId) {
      setReplyLike("");
    } else {
      setReplyLike(replyId);
    }
    const result = await handleLikeCommentReply(
      "like",
      postId,
      commentId,
      replyId,
      postUserId
    );
    if (result) {
      setReplyLike("");
      setDisableBtn("");
    }
  };
  const handleReplyDislike = async (
    postId,
    commentId,
    replyId,
    dislikers,
    postUserId
  ) => {
    setDisableBtn(replyId);
    if (dislikers.find((el) => el.uid === localStorage.getItem("uid"))) {
      setCountValue(-1);
    } else {
      setCountValue(1);
    }
    if (replyDislike === replyId) {
      setreplyDislike("");
    } else {
      setreplyDislike(replyId);
      const result = await handleLikeCommentReply(
        "dislike",
        postId,
        commentId,
        replyId,
        postUserId
      );
      if (result) {
        setreplyDislike("");
        setDisableBtn("");
      }
    }
  };

  const handleNewPost = async () => {
    if (newpost === "" && newpost.length < 50) {
      return toast.error("Post must contain at least 50 characters.", {
        id: "toast-error",
        style: {
        	maxWidth: 800,
  			fontSize: "14px",
  			borderRadius: "15px",
        },
      });
    }
    setRotating(true);
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
      id: "toast-loading",
      duration: 6000,
      style: {
        borderRadius: "5px",
        background: "#333",
        color: "#fff",
      },
    });
    const res = await axios.get(
      "https://create-post-t3e66zpola-uc.a.run.app/",
      {
        params: {
          text: newpost,
          privacy: privacy.type,
          allowCom: allowCom ? "yes" : "no",
          action: "newPost",
          feedUid: post.uid,
        },
        headers: {
          Idtoken: token,
        },
      }
    );
    setRotating(false);
    setNewPost("");
    if (res.data.error === "no error") {
      toast.success("Thank you for sharing", {
        id: "toast-success",
        style: {
        	maxWidth: 800,
  			fontSize: "14px",
  			borderRadius: "15px",
        },
      });
      setCloseCreatePost(false);
      setPrivacyShow(false);
      history.push("/feed");
    } else {
      toast.error(res.data.error, {
        id: "toast-error",
        style: {
        	maxWidth: 800,
  			fontSize: "14px",
  			borderRadius: "15px",
        },
      });
    }
  };

  const handleShare = async () => {
    setRotating(true);
    const res = await axios.get(
      "https://create-post-t3e66zpola-uc.a.run.app/share",
      {
        params: {
          text: newpost,
          postId: selectedPost,
          //  shareLink: `/feed-detail/${post.uid}/${selectedPost}`,
          shareOri: `/feed-detail/${selectedPost}`,
          privacy: privacy.type,
          allowCom: allowCom,
          feedUid: post.uid,
        },
        headers: {
          Idtoken: token,
        },
      }
    );
    setRotating(false);
    setNewPost("");
    if (res.data.error === "no error") {
      const { post, games } = await getFeed(uid, postId);
      setLoading(false);
      setPost(post);
      setGameCount(games);
      toast.success("Thank you for sharing", {
        id: "toast-success",
        style: {
        	maxWidth: 800,
  			fontSize: "14px",
  			borderRadius: "15px",
        },
      });
      setPrivacyShow(false);
      setCloseCreatePost("");
      setNewPost("");
    } else {
      toast.error(res.data.error, {
        id: "toast-error",
        style: {
        	maxWidth: 800,
  			fontSize: "14px",
  			borderRadius: "15px",
        },
      });
    }
  };

  const selectedPostType = (event) => {
    setPrivacy(JSON.parse(event.target.value));
  };

  const handleClose = () => {
    setView(false);
  };

  const handleShow = (feedId, ownerId) => {
    setView(true);
    setReportFeedId(feedId);
    setReportOwnerId(ownerId);
  };

  const handleSelectEmojiComment = (emoji) => {
    setNewComment((old) => old + emoji);
  };
  const handleSelectEmojiReply = (emoji) => {
    setNewReply((old) => old + emoji);
  };

  const handleEmoji = () => {
    if (replyShow) {
      setEmojiPopup("reply");
    } else {
      setEmojiPopup("comment");
    }
  };

  const handleDeletePost = async (postId) => {
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
      id: "toast-loading",
      duration: 6000,
      style: {
        borderRadius: "5px",
        background: "#333",
        color: "#fff",
      },
    });
    try {
      const res = await axios.get(
        `https://del-user-feed-t3e66zpola-uc.a.run.app/deleteSinglePost`,
        { headers: { idToken: token }, params: { postId } }
      );
      if (res.data.error === "no error") {
        if (post) {
          toast.success("Deleted.", {
            id: "toast-success",
            style: {
        	    maxWidth: 800,
  			    fontSize: "14px",
  			    borderRadius: "15px",
           },
          });
          history.push("/feed");
        }
      } else {
        toast.error(res.data.error, {
          id: "backend-error",
          style: {
        	maxWidth: 800,
  			fontSize: "14px",
  			borderRadius: "15px",
           },
        });
      }

      return true;
    } catch (error) {
      console.log("Error in handleDeletePost api =>", error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  const hidePostComntReply = async (
    postId,
    commentId,
    replyId,
    post,
    commentInd,
    replyInd
  ) => {
    // let copyPost = JSON.parse(JSON.stringify(post));
    let parmsObj = { postId };

    // for hiding the post after response success.
    // if (!commentId && !replyId) {
    //   copyPost.isHide = true;
    // }
    // for hiding the comment- query and state manage
    if (commentId && !replyId) {
      parmsObj.comId = commentId;
      // copyPost.commentsArr[commentInd].isHide = true;
    }
    // for hiding the reply - query and state manage
    if (replyId && replyId) {
      parmsObj.comId = commentId;
      parmsObj.repId = replyId;
      // copyPost.commentsArr[commentInd].repliesArr[replyInd].isHide = true;
    }
    const successMsg =
      commentId && !replyId
        ? "Comment"
        : commentId && replyId
        ? "Reply"
        : "Post";
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
      id: "toast-loading",
      duration: 6000,
      style: {
        borderRadius: "5px",
        background: "#333",
        color: "#fff",
      },
    });
    const res = await axios.get(
      "https://del-user-feed-t3e66zpola-uc.a.run.app/hideContent",
      {
        params: parmsObj,
        headers: {
          idtoken: token,
        },
      }
    );
    if (res.data.success) {
      const { post } = await getFeed(uid, postId);
      setPost(post);
      toast.success(`${successMsg} hide`, {
        id: "toast-success",
        style: {
        	maxWidth: 800,
  			fontSize: "14px",
  			borderRadius: "15px",
        },
      });
    } else if (res.data.error !== "no error") {
      toast.error(res.data.error, {
        id: "toast-error",
        style: {
        	maxWidth: 800,
  			fontSize: "14px",
  			borderRadius: "15px",
        },
      });
    }
  };
  return (
    <Layout>
      <div className="feed-detail-page">
        <div className="container">
          <div className="feed-box-form">
            <Form>
              <div className="row">
                <div className="col-md-3">
                  <div className="feed-card">
                    <div className="feed-box">
                      <img src={myImg} alt="Admin" />
                      <div className="feed-info">
                        <h4>{user?.displayName}</h4>
                        <p>
                          Back to <Link to="/feed">Feed</Link>
                        </p>

                        <button
                          type="button"
                          className="btn btn-outline-dark share-box"
                          onClick={() => setCloseCreatePost("newPost")}
                        >
                          Start a post
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="web-game-cards">
                    <div className="feed-card blackjack">
                      <div className="play-game">
                        <h4>Are you want to play Blackjack?</h4>
                        <button
                          type="button"
                          className="btn btn-outline-dark share-box"
                          onClick={() => history.push(`/jointable/blackjack`)}
                        >
                          Play
                        </button>
                      </div>
                    </div>

                    <div className="feed-card">
                      <div className="play-game">
                        <h4>
                          {gameCount} people are playing Poker, do you want too?
                        </h4>
                        <button
                          type="button"
                          className="btn btn-outline-dark share-box"
                          onClick={() => history.push(`/jointable/poker`)}
                        >
                          Play
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="feed-detail-post">
                    <div className="post-page">
                      <div className="filter-card">
                        {post && !post?.isHide ? (
                          <>
                            <div className="filter-matches">
                              <div className="report-post-box">
                                <div
                                  className="post-user"
                                  onClick={() =>
                                    history.push(`/userProfile/${post.uid}`)
                                  }
                                >
                                  <img src={post?.photo} alt="user-profile" />
                                  <div className="user-name">
                                    {post?.nickname}
                                    <span className="post-time">
                                      {timeDifference(post?.date)}
                                    </span>
                                  </div>
                                </div>
                                <div className="report-post">
                                  <DropdownButton
                                    id="dropdown-basic-button"
                                    title={
                                      <i
                                        className="fa fa-ellipsis-v"
                                        aria-hidden="true"
                                      ></i>
                                    }
                                  >
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleShow(post.id, post.uid)
                                      }
                                    >
                                      <div className="reort-post-item">
                                        <i
                                          className="fa fa-exclamation-triangle"
                                          aria-hidden="true"
                                        ></i>
                                        <div className="reort-post-item-content">
                                          <h6>Report Post</h6>
                                          <p>I'm concerned about this post</p>
                                        </div>
                                      </div>
                                    </Dropdown.Item>
                                    {post?.uid === uid ? (
                                      <Dropdown.Item
                                        onClick={() => {
                                          if (post?.uid === uid)
                                            handleDeletePost(post.id);
                                        }}
                                      >
                                        <div className="reort-post-item">
                                          <i
                                            className="fa fa-trash-o"
                                            aria-hidden="true"
                                          ></i>
                                          <div className="reort-post-item-content">
                                            <h6>Delete Post</h6>
                                          </div>
                                        </div>
                                      </Dropdown.Item>
                                    ) : (
                                      ""
                                    )}
                                    {post?.uid !== uid ? (
                                      <>
                                        {" "}
                                        <Dropdown.Item
                                          onClick={() =>
                                            hidePostComntReply(
                                              post.id,
                                              undefined,
                                              undefined,
                                              post
                                            )
                                          }
                                        >
                                          <div className="reort-post-item">
                                            <i
                                              className="fa fa-eye-slash"
                                              aria-hidden="true"
                                            ></i>
                                            <div className="reort-post-item-content">
                                              <h6>Hide Post</h6>
                                            </div>
                                          </div>
                                        </Dropdown.Item>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </DropdownButton>
                                </div>
                              </div>
                              <div className="post-content">
                                <p>{post?.text}</p>
                              </div>
                              <div className="post-activity">
                                <div
                                  className={`post-likes ${
                                    disableBtn === post.id ? "disable-btn" : ""
                                  }`}
                                  onClick={() =>
                                    handlePostLike(
                                      post.id,
                                      post.likers,
                                      post.uid
                                    )
                                  }
                                >
                                  <span>
                                    {/*<i
                                  className={
                                    post?.likers?.find((el) => el.uid === uid)
                                      ? `fa fa-heart`
                                      : `fa fa-heart-o`
                                  }
                                  aria-hidden="true"
                                ></i>*/}
                                    <img src={like} alt="like" />
                                    {postLike === post?.id
                                      ? post?.likes + countValue
                                      : post?.likes}
                                  </span>
                                </div>
                                {post?.allowCom ? (
                                  <div className="post-comments">
                                    <span>
                                      {/*<i
                                    className="fa fa-comment"
                                    aria-hidden="true"
                                  ></i>*/}
                                      <img src={comments} alt="like" />
                                      {post?.comments}
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div
                                  className="post-share"
                                  onClick={() => {
                                    setSelectedPost(post.id);
                                    setCloseCreatePost("share");
                                  }}
                                >
                                  <span>
                                    {/*<i
                                  className="fa fa-share-square-o"
                                  aria-hidden="true"
                                ></i>{" "}*/}
                                    <img src={share} alt="like" />
                                    {0}
                                  </span>
                                </div>
                              </div>
                            </div>

                            {post?.allowCom ? (
                              <div className="feed-comments">
                                <div className="body_comment">
                                  <div className="row">
                                    <div className="avatar_comment col-md-1">
                                      <img src={myImg} alt="avatar" />
                                    </div>
                                    <div className="box_comment col-md-11">
                                      <textarea
                                        className="commentar"
                                        placeholder="Add a comment..."
                                        maxLength={150}
                                        onChange={(e) =>
                                          setNewComment(e.target.value)
                                        }
                                        value={newComment}
                                      ></textarea>
                                      <div className="emoji-section">
                                        <div className="emoji-button">
                                          <i
                                            className="las la-grin-beam"
                                            onClick={handleEmoji}
                                          ></i>
                                        </div>
                                        {emojiPopup === "comment" && (
                                          <EmojiPopup
                                            ref={wrapperRef}
                                            handleSelectEmoji={
                                              handleSelectEmojiComment
                                            }
                                          />
                                        )}
                                      </div>
                                      <div className="box_post">
                                        <button
                                          type="button"
                                          value="1"
                                          onClick={() =>
                                            handleComment(postId, newComment)
                                          }
                                          disabled={rotating}
                                        >
                                          {rotating === "comment" ? (
                                            <Spinner animation="border" />
                                          ) : (
                                            <i className="lab la-telegram-plane"></i>
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                    <p>{newComment.length}/150</p>
                                  </div>
                                  <div className="row">
                                    <ul id="list_comment" className="col-md-12">
                                      {post?.commentsArr
                                        .sort(
                                          (a, b) =>
                                            new Date(b.date) - new Date(a.date)
                                        )
                                        .map((comment, commentInd) => (
                                          <li
                                            key={comment.id}
                                            className="box_result row"
                                          >
                                            <div className="user-comments-details">
                                              <div
                                                className="avatar_comment"
                                                onClick={() =>
                                                  history.push(
                                                    `/userProfile/${comment.uid}`
                                                  )
                                                }
                                              >
                                                <img
                                                  src={comment.photo}
                                                  alt="avatar"
                                                />
                                                <p>{comment.nickname}</p>
                                              </div>
                                              {comment.uid !== uid ? (
                                                <div className="report-post">
                                                  <DropdownButton
                                                    id="dropdown-basic-button"
                                                    title={
                                                      <i
                                                        className="fa fa-ellipsis-v"
                                                        aria-hidden="true"
                                                      ></i>
                                                    }
                                                  >
                                                    <Dropdown.Item
                                                      onClick={() =>
                                                        hidePostComntReply(
                                                          post.id,
                                                          comment.id,
                                                          undefined,
                                                          post,
                                                          commentInd
                                                        )
                                                      }
                                                    >
                                                      <div className="reort-post-item">
                                                        <i
                                                          className="fa fa-eye-slash"
                                                          aria-hidden="true"
                                                        ></i>
                                                        <div className="reort-post-item-content">
                                                          <h6>Hide Comment</h6>
                                                        </div>
                                                      </div>
                                                    </Dropdown.Item>
                                                  </DropdownButton>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>

                                            <div className="result_comment">
                                              <p>{comment.text}</p>
                                              <div className="tools_comment">
                                                <div className="likes-box">
                                                  <div
                                                    className={`like-section ${
                                                      disableBtn === comment.id
                                                        ? "disable-btn"
                                                        : ""
                                                    }`}
                                                    onClick={() =>
                                                      handleCommentLike(
                                                        postId,
                                                        comment.id,
                                                        comment.likers,
                                                        post.uid
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className={
                                                        comment.likers.find(
                                                          (el) => el.uid === uid
                                                        )
                                                          ? `fa fa-thumbs-up`
                                                          : `fa fa-thumbs-o-up`
                                                      }
                                                    ></i>{" "}
                                                    <span className="count">
                                                      {" "}
                                                      {commentLike ===
                                                      comment.id
                                                        ? comment.likes +
                                                          countValue
                                                        : comment.likes}
                                                    </span>
                                                  </div>

                                                  <div
                                                    className={`like-section ${
                                                      disableBtn === comment.id
                                                        ? "disable-btn"
                                                        : ""
                                                    }`}
                                                    onClick={() =>
                                                      handleCommentDislike(
                                                        postId,
                                                        comment.id,
                                                        comment.dislikers,
                                                        post.uid
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className={
                                                        comment.dislikers.find(
                                                          (el) => el.uid === uid
                                                        )
                                                          ? `fa fa-thumbs-down`
                                                          : `fa fa-thumbs-o-down`
                                                      }
                                                    ></i>{" "}
                                                    <span className="count">
                                                      {commentDislike ===
                                                      comment.id
                                                        ? comment.dislikes +
                                                          countValue
                                                        : comment.dislikes}
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="like-section"
                                                    onClick={() =>
                                                      setReplyShow(comment.id)
                                                    }
                                                  >
                                                    <i className="fa fa-comment"></i>{" "}
                                                    <span className="count">
                                                      {comment.replies}
                                                    </span>
                                                  </div>
                                                  <div className="like-section">
                                                    <span>
                                                      {" "}
                                                      {" " +
                                                        timeDifference(
                                                          comment.date
                                                        )}
                                                    </span>
                                                  </div>
                                                </div>

                                                {replyShow === comment.id ? (
                                                  <>
                                                    <div className="row">
                                                      <div className="avatar_comment col-md-1">
                                                        <img
                                                          src={myImg}
                                                          alt="avatar"
                                                        />
                                                      </div>
                                                      <div className="box_comment col-md-11">
                                                        <textarea
                                                          className="commentar"
                                                          placeholder="Add a comment..."
                                                          onChange={(e) =>
                                                            setNewReply(
                                                              e.target.value
                                                            )
                                                          }
                                                          maxLength={150}
                                                          value={newReply}
                                                        ></textarea>
                                                        <div className="emoji-section">
                                                          <div className="emoji-button">
                                                            <i
                                                              className="las la-grin-beam"
                                                              onClick={
                                                                handleEmoji
                                                              }
                                                            ></i>
                                                          </div>
                                                          {emojiPopup ===
                                                            "reply" && (
                                                            <EmojiPopup
                                                              ref={wrapperRef}
                                                              handleSelectEmoji={
                                                                handleSelectEmojiReply
                                                              }
                                                            />
                                                          )}
                                                        </div>
                                                        <div className="box_post">
                                                          <button
                                                            type="button"
                                                            value="1"
                                                            onClick={() =>
                                                              handleComment(
                                                                postId,
                                                                newReply,
                                                                comment.id,
                                                                "reply"
                                                              )
                                                            }
                                                            disabled={rotating}
                                                          >
                                                            {rotating ===
                                                            "reply" ? (
                                                              <Spinner animation="border" />
                                                            ) : (
                                                              <i className="lab la-telegram-plane"></i>
                                                            )}
                                                          </button>
                                                        </div>
                                                      </div>
                                                      <p>
                                                        {newReply.length}/150
                                                      </p>
                                                    </div>
                                                    <ul className="child_replay">
                                                      {comment.repliesArr
                                                        .sort(
                                                          (a, b) =>
                                                            new Date(b.date) -
                                                            new Date(a.date)
                                                        )
                                                        .map(
                                                          (reply, replyInd) => (
                                                            <li
                                                              className="box_reply row"
                                                              key={reply.id}
                                                            >
                                                              <div className="user-comments-details">
                                                                <div
                                                                  className="avatar_comment"
                                                                  onClick={() =>
                                                                    history.push(
                                                                      `/userProfile/${reply.uid}`
                                                                    )
                                                                  }
                                                                >
                                                                  <img
                                                                    src={
                                                                      reply.photo
                                                                    }
                                                                    alt="avatar"
                                                                  />
                                                                  <p
                                                                    onClick={() =>
                                                                      this.setState(
                                                                        {
                                                                          redirect: `/userProfile/${reply.uid}`,
                                                                        }
                                                                      )
                                                                    }
                                                                  >
                                                                    {
                                                                      reply.nickname
                                                                    }
                                                                  </p>
                                                                </div>
                                                                {reply?.uid !==
                                                                uid ? (
                                                                  <div className="report-post">
                                                                    <DropdownButton
                                                                      id="dropdown-basic-button"
                                                                      title={
                                                                        <i
                                                                          className="fa fa-ellipsis-v"
                                                                          aria-hidden="true"
                                                                        ></i>
                                                                      }
                                                                    >
                                                                      <Dropdown.Item
                                                                        onClick={() =>
                                                                          hidePostComntReply(
                                                                            post.id,
                                                                            comment.id,
                                                                            reply.id,
                                                                            post,
                                                                            commentInd,
                                                                            replyInd
                                                                          )
                                                                        }
                                                                      >
                                                                        <div className="reort-post-item">
                                                                          <i
                                                                            className="fa fa-eye-slash"
                                                                            aria-hidden="true"
                                                                          ></i>
                                                                          <div className="reort-post-item-content">
                                                                            <h6>
                                                                              Hide
                                                                              Reply
                                                                            </h6>
                                                                          </div>
                                                                        </div>
                                                                      </Dropdown.Item>
                                                                    </DropdownButton>
                                                                  </div>
                                                                ) : null}
                                                              </div>
                                                              <div className="result_comment">
                                                                <p>
                                                                  {reply.text}
                                                                </p>
                                                                <div className="tools_comment">
                                                                  <div className="likes-box">
                                                                    <div
                                                                      className={`like-section ${
                                                                        disableBtn ===
                                                                        reply.id
                                                                          ? "disable-btn"
                                                                          : ""
                                                                      }`}
                                                                      onClick={() =>
                                                                        handleReplyLike(
                                                                          postId,
                                                                          comment.id,
                                                                          reply.id,
                                                                          reply.likers,
                                                                          post.uid
                                                                        )
                                                                      }
                                                                    >
                                                                      <i
                                                                        className={
                                                                          reply.likers.find(
                                                                            (
                                                                              el
                                                                            ) =>
                                                                              el.uid ===
                                                                              uid
                                                                          )
                                                                            ? `fa fa-thumbs-up`
                                                                            : `fa fa-thumbs-o-up`
                                                                        }
                                                                      ></i>{" "}
                                                                      <span className="count">
                                                                        {" "}
                                                                        {replyLike ===
                                                                        reply.id
                                                                          ? reply.likes +
                                                                            countValue
                                                                          : reply.likes}
                                                                      </span>
                                                                    </div>

                                                                    <div
                                                                      className={`like-section ${
                                                                        disableBtn ===
                                                                        reply.id
                                                                          ? "disable-btn"
                                                                          : ""
                                                                      }`}
                                                                      onClick={() =>
                                                                        handleReplyDislike(
                                                                          postId,
                                                                          comment.id,
                                                                          reply.id,
                                                                          reply.dislikers,
                                                                          post.uid
                                                                        )
                                                                      }
                                                                    >
                                                                      <i
                                                                        className={
                                                                          comment.dislikers.find(
                                                                            (
                                                                              el
                                                                            ) =>
                                                                              el.uid ===
                                                                              uid
                                                                          )
                                                                            ? `fa fa-thumbs-down`
                                                                            : `fa fa-thumbs-o-down`
                                                                        }
                                                                      ></i>{" "}
                                                                      <span className="count">
                                                                        {replyDislike ===
                                                                        comment.id
                                                                          ? reply.dislikes +
                                                                            countValue
                                                                          : reply.dislikes}
                                                                      </span>
                                                                    </div>

                                                                    <div className="like-section">
                                                                      <span>
                                                                        {" "}
                                                                        {" " +
                                                                          timeDifference(
                                                                            reply.date
                                                                          )}
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </li>
                                                          )
                                                        )}
                                                    </ul>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                          </li>
                                        ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          <h6>No Post</h6>
                        )}
                      </div>
                      <div className="mobile-game-cards">
                        <div className="feed-card blackjack">
                          <div className="play-game">
                            <h4>Are you want to play Blackjack?</h4>
                            <button
                              type="button"
                              className="btn btn-outline-dark share-box"
                              onClick={() =>
                                history.push(`/jointable/blackjack`)
                              }
                            >
                              Play
                            </button>
                          </div>
                        </div>

                        <div className="feed-card">
                          <div className="play-game">
                            <h4>
                              {gameCount} people are playing Poker, do you want
                              too?
                            </h4>
                            <button
                              type="button"
                              className="btn btn-outline-dark share-box"
                              onClick={() => history.push(`/jointable/poker`)}
                            >
                              Play
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
        <Modal
          show={closeCreatePost.length !== 0}
          onHide={() => setCloseCreatePost("")}
          centered
          className="friends-popup"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <small>
                {closeCreatePost === "newPost" ? "Create a post" : "Share Post"}
              </small>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="feed-body">
              <Form.Group className="post-form" controlId="post-id">
                <div className="post-box">
                  <img src={myImg} alt="Admin" className="rounded-circle" />
                  <small>@{user?.displayName}</small>
                </div>
                <button
                  onClick={() => setPrivacyShow(true)}
                  className="btn-sm btn-outline-light select-post"
                >
                  <i
                    className={visibilityIcon.get(privacy.label)}
                    aria-hidden="true"
                  >
                    {" "}
                  </i>
                  {privacy.label}
                </button>
              </Form.Group>

              <div className="allow-comment">
                <label className="switch">
                  <input
                    type="checkbox"
                    name="allowCom"
                    onChange={() => setAllowCom(!allowCom)}
                    defaultChecked={allowCom}
                  />
                  <span className="slider round"></span>
                </label>
                <label>Allow comment</label>
              </div>

              <div className="form-group">
                <div className="the-count">
                  <span className="current">{newpost.length}</span>
                  <span className="maximum">/ 250</span>
                </div>
                <textarea
                  className="form-control text-area animated"
                  placeholder="What's on your mind"
                  onChange={(e) => setNewPost(e.target.value)}
                  maxLength={250}
                  minLength={50}
                  value={newpost}
                  count={newpost}
                ></textarea>
              </div>

              <div className="btn-float">
                <button
                  className="btn btn-dark"
                  type="button"
                  onClick={() => {
                    closeCreatePost === "newPost"
                      ? handleNewPost()
                      : handleShare();
                  }}
                  disabled={rotating}
                >
                  {rotating ? <Spinner animation="border" /> : "Share"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={privacyShow}
          onHide={() => setPrivacyShow(false)}
          centered
          className="friends-popup"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <small>Who can see your post?</small>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="feed-body">
              <div className="col-md-6">
                <Form.Group className="post-form" controlId="post-id">
                  <div className="post-select">
                    <div className="filter-form-posts">
                      <div className="filter-input radio">
                        <input
                          type="radio"
                          value={JSON.stringify({
                            type: "all",
                            label: "Anyone",
                          })}
                          name="SharePost"
                          defaultChecked={privacy.type === "all"}
                          onChange={selectedPostType}
                        />
                        <label className="radio-label" id="Basketballtext">
                          <span className="btn-sm select-post">
                            <i
                              className="fa fa-globe fa-fa-design"
                              aria-hidden="true"
                            ></i>
                            Anyone
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="post-select">
                    <div className="filter-form-posts">
                      <div className="filter-input radio">
                        <input
                          type="radio"
                          value={JSON.stringify({
                            type: "ff",
                            label: "Friends and followers",
                          })}
                          name="SharePost"
                          defaultChecked={privacy.type === "ff"}
                          onChange={selectedPostType}
                        />
                        <label className="radio-label" id="Basketballtext">
                          <span className="btn-sm select-post">
                            <i
                              className="fa fa-users fa-fa-design"
                              aria-hidden="true"
                            ></i>
                            Friends and followers
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="post-select">
                    <div className="filter-form-games">
                      <div className="filter-input radio">
                        <input
                          type="radio"
                          value={JSON.stringify({
                            type: "friends",
                            label: "Friends",
                          })}
                          name="SharePost"
                          defaultChecked={privacy.type === "friends"}
                          onChange={selectedPostType}
                        />
                        <label className="radio-label" id="Basketballtext">
                          <span className="btn-sm select-post">
                            <i
                              className="fa fa-user fa-fa-design"
                              aria-hidden="true"
                            ></i>
                            Friends
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="post-select">
                    <div className="filter-form-games">
                      <div className="filter-input radio">
                        <input
                          type="radio"
                          value={JSON.stringify({
                            type: "followers",
                            label: "Followers",
                          })}
                          name="SharePost"
                          defaultChecked={privacy.type === "followers"}
                          onChange={selectedPostType}
                        />
                        <label className="radio-label" id="Basketballtext">
                          <span className="btn-sm select-post">
                            <i
                              className="fa fa-check-circle fa-fa-design"
                              aria-hidden="true"
                            ></i>
                            Followers
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </div>
              <div className="btn-float">
                <button
                  className="btn btn-dark"
                  onClick={() => setPrivacyShow(false)}
                >
                  Back
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={view}
          onHide={handleClose}
          centered
          className="report-popup"
        >
          <Modal.Header closeButton> </Modal.Header>
          <Modal.Body>
            <ReportPostPopup
              feedId={reportFeedId}
              ownerId={reportOwnerId}
              idToken={token}
            />
          </Modal.Body>
        </Modal>
      </div>
    </Layout>
  );
};

export default FeedDetails;
