import React, { Component } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import Lottie from "react-lottie";
import loading from "../../../assets/animation/loading.json";
import Loader from "../../loader/loader";
import info from "../../../assets/images/icons/info_30.png";
import Follome from "../../followme/followme";
import axios from "axios";
import Network from "./network";
import { getUserStats } from "../../../dbFetchFunctions";
import { MainContext } from "../../../context";
import UrlConfig from "../../../utils/ApiConfig";
import { cookieSetter } from '../../../dbFetchFunctions';

const loadingLottie = {
  loop: true,
  autoplay: true,
  animationData: loading,
};

class FriendsList extends Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      search: "",
      friends: [],
      rotating: false,

      //Show hide Modals
      networkShow: false,
      isTableVisible: false,

      //Get personal data from parent
      pending: true,
      redirect: this.props.redirect,
      uid: this.props.uid,
      signedin: this.props.signedin,
      nick: this.props.nick,
      stats: { coins: this.props.coins },
      idToken: this.props.idToken,
      observerUNSUB: this.props.observerUNSUB,

      //FollowMe
      isFollowing: "",
      followmeShow: false,
      followId: "",
      followNick: "",
      followAvatar: null,
      unFriend: false,
      unFriendName: "",
      unFriendUId: "",

      userStats: {},
      Hplayed: "",
      Friends: "",
      Level: "",
      Games: "",
      Wins: "",
      Losses: "",
    };
    this.handleUnfriend = this.handleUnfriend.bind(this);
    this.handleNetwork = this.handleNetwork.bind(this);
  }
  frTable;

  async componentDidMount() {
    this.handleInitialize();
  }

  async handleInitialize() {
    const context = this.context;
    if (context.friendList.length) {
      this.setState({
        friends: [...context.friendList],
        isTableVisible: true,
        pending: false,
      });
      this.frTable = [...context.friendList];
    } else {
      const UserId = this.state.uid;
      const Uidtoken = this.state.idToken;
      if (UserId) {
        await axios
          .get(UrlConfig.showFriends, {
            params: {
              uid: UserId,
              mode: "profilePage",
              viewMore: "no",
            },
          })
          .then((response) => {
            if (response.data) {
              context.setFriendList(response.data.frTable);
              this.frTable = [...response.data.frTable];
              this.setState({
                pending: false,
                friends: [...response.data.frTable],
                isTableVisible: true,
              });
            }
          })
          .catch((error) => {
            console.log("Error req", error);
          });
      }
    }
  }

  handleChange(event) {
    // Get event search value
    let searchValue = event.target.value;
    // Set the state to trigger a re-rendering
    let friends = this.frTable.filter((e) =>
      e.value.toLowerCase().match(searchValue.toLowerCase())
    );
    if (searchValue === "")
      this.setState({ search: searchValue, friends: this.frTable });
    else this.setState({ search: searchValue, friends });
  }

  handleUnfriendclose() {
    this.setState({
      unFriend: !this.state.unFriend,
    });
  }

  handlenetworkClose() {
    this.setState({
      networkShow: !this.state.networkShow,
    });
  }

  handleNetwork(event) {
    this.setState({
      networkShow: true,
    });
  }

  successClose() {
    window.location.reload(false);
  }


  handleUnfriend(event) {
    var res = String(event);
    const param = res.split(",");
    const Uid = param[0];
    const nickname = param[1];
    this.setState({
      unFriendUId: Uid,
      unFriendName: nickname,
      unFriend: true,
    });
  }

  async UserUnfriendAction(event) {
    event.preventDefault();
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
      id: "toast-loading",
      duration: 6000,
      style: {
        borderRadius: "5px",
        background: "#333",
        color: "#fff",
      },
    });
    this.setState({
      rotating: true,
    });
    try {
      const res = await axios.get(UrlConfig.UnfriendApi, {
          params: { frId: this.state.unFriendUId },
          headers: { wid: localStorage.getItem("walletid") },
          withCredentials: true,
          credentials: "include",
      });
      if (res.data) {
        this.setState({
          rotating: false,
        });
        if (res.data.error === "missing auth token") {
          const account = localStorage.getItem("walletid");
          await cookieSetter(account, this.UserUnfriendAction(event), (idToken) => this.setState({ idToken }));
      } else if (res.data.error === "no error") {
        toast.success("Friend has been removed", {
          id: "toast-error",
          style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
          },
        });
        this.handleUnfriendclose();
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      } else {
        toast.error(res.data.error, {
          id: "toast-error",
          style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
          },
        });
      } } else if (res.data.error !== "no error") {
        toast.error(res.data.error || "Server Error", {
          id: "toast-error",
          style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
            },
        });
      }
    } catch (e) {
      toast.error(e.response.data.message, {
        id: "toast-error",
        style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
            },
      });
    }
  }

  handleFollowMe(event) {
    var res = String(event);
    const param = res.split(",");
    const Uid = param[0];
    const nickname = param[1];
    const resFollowing = param[2];
    const avatar = param[3];

    this.fetchUserStats(Uid);

    this.setState({
      followmeShow: !this.state.followmeShow,
      followId: Uid,
      followNick: nickname,
      isFollowing: resFollowing,
      followAvatar: avatar,
    });
  }

  followmeClose() {
    this.setState({
      followmeShow: !this.state.followmeShow,
    });
  }

  fetchUserStats = async (Uid) => {
    const fetchedUserStats = await getUserStats(Uid);
    this.setState(prevState => ({
      userStats: {
        ...prevState.userStats,
        [Uid]: fetchedUserStats,
      },
      Hplayed: fetchedUserStats.total.HoursPlayed.toFixed(2) || 0, // Update Hplayed with HoursPlayed value
      Friends: fetchedUserStats.social.friends || 0,
      Level: fetchedUserStats.total.Level || 0,
      Games: fetchedUserStats.total.games || 0,
      Wins: fetchedUserStats.total.win || 0,
      Losses: fetchedUserStats.total.lose || 0,
    }));
  };

  render() {
    if (this.state.pending) {
      return <Loader />;
    }

    return (
      <div className="friends-list">
        <div className="friends-card">
          <div className="friends-content">
            <div className="row align-items-center">
              <div className=" col-md-3 find-friends">
                <span
                  className="frButton btn-gold"
                  role="presentation"
                  onClick={() => this.handleNetwork()} >
                  Community  <i className="fa fa-search"></i>
                </span>
              </div>

              <div
                className={
                  this.state.isTableVisible
                    ? "col-md-9 friends-top"
                    : "table-responsive-hide"
                }>
                    {this.state.friends?.length > 1 ? (
                            <div className="friends-search s-friends">
                              <input
                                id="mySearchInput"
                                className="form-control"
                                type="text"
                                name="search"
                                autoComplete="off"
                                placeholder="Search in friends.."
                                onChange={(e) => this.handleChange(e)}
                              />
                            </div>
                          ) : (
                            <>
                          </>
                      )}
                </div>
              </div>

            <div className="friends-list">
              {this.state.friends?.length > 0 && <h6>Friends list</h6>}
            </div>

            <div className="row">
              <div className="col-md-12 ml-auto mr-auto">
                <div
                  className={
                    this.state.isTableVisible
                      ? "table-responsive"
                      : "table-responsive-hide"
                  }>
                  <table className="table">
                    <tbody id="myTable">
                      {this.state.friends?.length > 0 ? (
                        <>
                          <tr>
                            <th>Avatar</th>
                            <th>Name</th>
                            <th></th>
                            {/* <th>Followers</th> */}
                          </tr>
                          {this.state.friends.map((f, index) => (
                            <tr key={index}>
                              <td>
                                <img
                                  src={f.avatar}
                                  alt="Avatar"
                                  className="avatar rounded-circle cursor"
                                  onClick={() =>
                                    this.handleFollowMe([
                                      f.id,
                                      f.value,
                                      f.isFollowing,
                                      f.avatar,
                                    ])
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  className="cursor icon-cursor"
                                  onClick={() =>
                                    this.handleFollowMe([
                                      f.id,
                                      f.value,
                                      f.isFollowing,
                                      f.avatar,
                                    ])
                                  }
                                  src={info}
                                  alt="info"
                                />{" "}
                                {f.value}
                              </td>
                              {/* <td>{f.followers}</td> */}
                              <td></td>
                              <td name={f.id} className="td-actions">
                                <Button
                                  className="btn-dark"
                                  onClick={() =>
                                    this.handleUnfriend([f.id, f.value])
                                  }>
                                  Unfriend
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <>
                        <tr>
                          <td colSpan="number_of_columns">
                            <small style={{ color: "#fff" }}>No Friends Found</small>
                          </td>
                        </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div>
              <Modal
                show={this.state.followmeShow}
                onHide={() => this.followmeClose()}
                centered
                className="friends-popup" >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <Follome
                    followId={this.state.followId}
                    nick={this.state.followNick}
                    isFollowing={this.state.isFollowing}
                    avatar={this.state.followAvatar}
                    Hplayed={this.state.Hplayed}
                    Friends={this.state.Friends}
                    Level={this.state.Level}
                    Games={this.state.Games}
                    Wins={this.state.Wins}
                    Losses={this.state.Losses}
                  />
                </Modal.Body>
              </Modal>
            </div>

            

            <Modal
              show={this.state.networkShow}
              onHide={() => this.handlenetworkClose()}
              centered
              className="friends-popup" >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div className="modal-body-searchbyId">
                  <Network uid={this.state.uid} idToken={this.state.idToken} />
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              show={this.state.unFriend}
              onHide={() => this.handleUnfriendclose()}
              centered
              className="friends-popup" >
              <Modal.Header closeButton>
                <Modal.Title>Un·friend</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={this.UserUnfriendAction.bind(this)}>
                  <div className="block">
                    <small>
                      Are you sure you want to remove{" "}
                      <span style={{ color: "#8461dc" }}>
                        @{this.state.unFriendName}
                      </span>{" "}
                      from your friends list?
                    </small><br/>
                    <Button
                      type="submit"
                      className="btn-dark"
                      disabled={this.state.rotating} >
                      {this.state.rotating ? (
                        <Spinner animation="border" />
                      ) : (
                        "Yes"
                      )}
                    </Button>
                  </div>
                </Form>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

export default FriendsList;
