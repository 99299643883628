import React, { Component } from 'react';
import { Table, Button } from "react-bootstrap";
import locked from "../../assets/animation/locked";
import Lottie from "react-lottie";
import "./index.css";

const lockedOption = {
    loop: true,
    autoplay: true,
    animationData: locked,
};

class Level extends Component {
constructor(props) {
        super(props);
            this.state = {
                uid: this.props.uid,
                level: this.props.level,
                gameTime: 100,
                friends: this.props.friends,
                idToken: this.props.idToken,
                coins: this.props.coins,
                playingH: this.props.playingH,
                levelUpShow: false,
                i_sound: localStorage.getItem("i_sound"),
            }
    }


  render() {
		return (
			<div className="tooltiptext responsiveLevels">
                <div className="tablelist-table">
                                <Table variant="dark" >
                                    <tbody>
                                        <tr>
                                            <td className="followtd">Game time<br></br>
                                            <b style={{ color: '#8461dc' }}>50H</b></td>
                                            <td className="followtd">Friends<br></br>
                                            <b style={{ color: '#8461dc' }}>50</b></td>
                                            <td className="followtd text-center">
                                                <Button className="btn-gold smallbtn _disabled" ><i className="fa fa-lock" style={{ color: "#16181e", fontSize: "16px" }}></i>&nbsp; LVL-1 </Button>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="followtd">Game time<br></br>
                                            <b style={{ color: '#8461dc' }}>60H</b></td>
                                            <td className="followtd">Friends<br></br>
                                            <b style={{ color: '#8461dc' }}>60</b></td>
                                            <td className="followtd text-center">
                                                <Button className="btn-gold smallbtn _disabled" ><i className="fa fa-lock" style={{ color: "#16181e", fontSize: "16px" }}></i>&nbsp; LVL-2 </Button>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="followtd">Game time<br></br>
                                            <b style={{ color: '#8461dc' }}>90H</b></td>
                                            <td className="followtd">Friends<br></br>
                                            <b style={{ color: '#8461dc' }}>90</b></td>
                                            <td className="followtd text-center">
                                                <Button className="btn-gold smallbtn _disabled" ><i className="fa fa-lock" style={{ color: "#16181e", fontSize: "16px" }}></i>&nbsp; LVL-3 </Button>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="followtd">Game time<br></br>
                                            <b style={{ color: '#8461dc' }}>120H</b></td>
                                            <td className="followtd">Friends<br></br>
                                            <b style={{ color: '#8461dc' }}>120</b></td>
                                            <td className="followtd text-center">
                                                <Button className="btn-gold smallbtn _disabled" ><Lottie options={lockedOption} height={50} width={50} /></Button>
                                                
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="followtd">
                                                <br></br>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table> 
                            </div>
                    </div>
            );
        }
    }

export default Level;
