import React, { useState, useEffect } from 'react';

function MinimumBet({ smallBlind: value, setSmallBlind: setValue, buyIn, setbuyIn, isShowSmallBlind = true }) {
  const [buttonClicked, setButtonClicked] = useState([false, true, false, false, false]);

  useEffect(() => {
    // When the component mounts, set the buyIn and button style for the second button
    setbuyIn(Number((value * 10).toFixed(2)));
  }, [setbuyIn, value]);

  const handleIncrement = () => {
    setValue(old => {
      const newValue = Number((old + 1).toFixed(2));
      setbuyIn(Number((newValue * 10).toFixed(2)));
      return newValue;
    });
  };

  const handleDecrement = () => {
    setValue(old => {
      const newValue = Number(Math.max(1, old - 1).toFixed(0));
      setbuyIn(Number((newValue * 10).toFixed(0)));
      return newValue;
    });
  };

  const handleMinbet = (newValue, index) => {
    const newBuyIn = Number(newValue.toFixed(0));
    setbuyIn(newBuyIn);

    // Create a new array with the updated state for the clicked button
    const updatedButtons = buttonClicked.map((btn, i) => (i === index ? true : false));
    setButtonClicked(updatedButtons);
  };

  return (
    <div className="qty">
      {isShowSmallBlind ? (
        <>
          <label>
              <b style={{ color: '#8461dc', fontSize: '17px' }}>SB/BB:</b> {value}/{(value * 2).toFixed(0)}
          </label>
          <div className="btns-qty">
            <button className="plus text-secondary" type="button" onClick={handleIncrement}>+</button>
            <button className="plus text-secondary" type="button" onClick={handleDecrement}>-</button>
          </div>
        </>
      ) : ""}
      <label>BuyIn:</label><br />
      <div className="btn-group" role="group" aria-label="First group">
        {[5, 10, 15, 25, 50].map((factor, index) => (
          <button
            key={index}
            type="button"
            style={{ backgroundColor: buttonClicked[index] ? '#8461dc' : 'white' }}
            onClick={() => handleMinbet(factor * value, index)}
            className={buttonClicked[index] ? 'active' : ''} >
            {(factor * value).toFixed(0)}
          </button>
        ))}
      </div>
    </div>
  );
}

export default MinimumBet;
