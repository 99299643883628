import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import Lottie from "react-lottie";
import loading from "../../assets/animation/loading.json";
import "./report.css";

const loadingLottie = {
    loop: true,
    autoplay: true,
    animationData: loading,
  };

const ReportUserPopup = ({ peer, idToken }) => {
  const [step, setStep] = useState();

  const handleStep = async (value) => {
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
        id: "toast-loading",
        duration: 6000,
        style: {
          borderRadius: "5px",
          background: "#333",
          color: "#fff",
        },
    });
    const res = await axios.get(
      "https://report-t3e66zpola-uc.a.run.app/reportUser",
      {
        headers: {
          idtoken: idToken,
        },
        params: {
          frId: peer,
          subject: value,
        },
      }
    );
    if (res.data.error === "no error") {
        toast.success("Thank you for letting us know.", {
            id: "toast-success",
            style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
            },
          });
      setStep(true);
    } else if (res.data.error === "you cant report this user again") {
        toast.error("You've already reported this user.", {
            id: "toast-error",
            style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
            },
        });
      setStep(true);
    } else if (res.data.error !== "no error"){
        toast.error(res.data.error, {
            id: "toast-error",
            style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
            },
        });
	}
  };

  return (
    <div className="report-user-popup">
      <div className="report-popup">
        <div className="report-popup-header">
          <h2>Report</h2>
        </div>

        {!step ? (
          <div className="report-popup-content">
            <h4>Please select a problem</h4>
            <p>
              If you think this text putting you in immediate danger, call local
              emergency services immediately.
            </p>

            <ul>
              <li onClick={() => handleStep("Fake account")}>Fake account</li>
              <li onClick={() => handleStep("Fraud or a scam")}>
                Fraud or a scam
              </li>
              <li onClick={() => handleStep("Texting inappropriate things")}>
                Texting inappropriate things
              </li>
              <li onClick={() => handleStep("Distributing text messages")}>
                Distributing text messages
              </li>
              <li onClick={() => handleStep("Something else")}>
                Something else
              </li>
            </ul>
          </div>
        ) : (
          <div className="report-popup-success">
            <h4>You have Report this Person successfully.</h4>
            <p>
              Thank you for letting us know! We review your resume carefully,
              and we will take action according to the user behavior.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
export default ReportUserPopup;
