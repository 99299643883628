import React, { useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import "./header.css";
import axios from "axios";
import firebase from "../../firebase";
import { useState } from "react";
import { MainContext } from "../../context";
import Bell from "../bell";
import logo from "../../assets/images/header/logo-new.png";
import logop from "../../assets/images/header/polygon.svg";
import helpicon from "../../assets/images/register/help.png";

const Header = () => {
  const context = useContext(MainContext);
  const [uid] = useState(context?.firebaseUser?.uid);
  const [token] = useState(context.token);
  const [notification, setNotification] = useState([]);
  const [unreadCount, setunreadCount] = useState();
  const history = useHistory();
  const [stickyHeader, setStickyHeader] = useState(false);

  const watchScroll = () => {
    if (window.scrollY > 60) {
      setStickyHeader(true);
    } else {
      setStickyHeader(false);
    }
  };

  useEffect(() => {
    if (window !== undefined && window.screen.width >= 0) {
      window.addEventListener("scroll", watchScroll);
    }
    return () => {
      window.removeEventListener("scroll", watchScroll);
    };
  }, []);

  const getNotification = async (token) => {
    const res = await axios.get("https://fetch-noti-t3e66zpola-uc.a.run.app/", {
      headers: {
        idToken: token,
      },
    });
    if(res.data.error === "user must login"){
      window.location.reload();
    }
    if (res.data.success) {
      context.setNotification(res.data.notiArray);
      const count = res.data.notiArray.reduce((prev, curr) => {
        return prev + !curr.isRead;
      }, 0);
      setNotification(res.data.notiArray);
      setunreadCount(count);
    }
  };

  useEffect(() => {
	let unsubscribe;
    const getFeedListener = async () => {
      const db = firebase.firestore();
      if (!uid) return;
      unsubscribe = db.collection(`${process.env.REACT_APP_DB_PREFIX}messanger`)
        .doc(uid)
        .collection("noti")
        .onSnapshot(async (snapshot) => {
          if (snapshot.docs.length < 1) {
            return 0;
          }
          getNotification(token);
        });
    };
    getFeedListener();
	return ()=>{
		if(unsubscribe){
			unsubscribe()
		  }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, token]);
  

  const pathname = window.location.pathname;
  const splitLocation = pathname.split("/");
  const xtkn = localStorage.getItem("xtkn");

  if (pathname === "/") {
    return (
        <div
          className={`header help-center-header ${
            stickyHeader ? "sticky-header" : ""
          }`} >
          <div className="header-top-area">
            <div className="container">
              <div className="header-wrapper">


             
                <h1 className="live-gaming-h">
                    <span className="h1-red">LIVE &#x2022;</span> <small>Poker | Sports Betting</small>
                  </h1>

                  <div>

                  <a href="https://t.me/wejedefi" target="_blank">
                    <i className="fa fa-telegram heteic"></i>
                    </a>

                  </div>

              </div>
            </div>
          </div>
          <div className="help-desk-icon">
          <span
            onClick={() => {
              history.push("/help-center");
            }}
            role="presentation">
            <img src={helpicon} alt="help" />
          </span>
        </div>
        </div>
      );
  }


  if (xtkn === null && pathname === "/help-center") {
    return (
      <div
        className={`header help-center-header ${
          stickyHeader ? "sticky-header" : ""
        }`} >
        <div className="header-top-area">
          <div className="container">
            <div className="header-wrapper">
              <div className="logo">
                  <img src={logop} alt="logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="help-desk-icon">
          <span
            onClick={() => {
              history.push("/help-center");
            }}
            role="presentation" >
            <img src={helpicon} alt="help" />
          </span>
        </div>
      </div>
    );
  }


  const getBellIconAndLogin = () => {
    if (pathname === "/profile") {
      return (
        <Bell
          uid={uid}
          idToken={token}
          notification={notification}
          unreadCount={unreadCount}
        />
      );
    }
    return "";
  };

  return (
    <div className={`header ${stickyHeader ? "sticky-header" : ""}`}>
      <div className="container">
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="dark"
          style={{ color: "#191b25" }} >
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            {" "}
            <div className="logo">
                <img src={logo} alt="logo" className="logo-profile" /> <small className="logo-text-profile">WEJE</small>
            </div>{" "}
            <div className="header-top-login after-login">
              <ul>
                <li className=" wow animate__animated animate__slideInUp">
                  <span
                    className={
                      splitLocation[1] === "profile" ? "activeheader" : "false"
                    }
                    role="presentation">
                    <Link to="/profile">
                      <i className="las la-home"></i>
                      <p>GAMES</p>
                    </Link>
                  </span>
                </li>
                <li className=" wow animate__animated animate__slideInUp">
                  <span
                    className={
                      splitLocation[1] === "friends" ? "activeheader" : "false"
                    }
                    role="presentation" >
                    <Link to="/friends">
                      <i className="las la-user-friends"></i>
                      <p>Friends</p>
                    </Link>
                  </span>
                </li>
                {/* <li className=" wow animate__animated animate__slideInUp">
                  <span
                    className={
                      splitLocation[1] === "feed" ? "activeheader" : "false"
                    }
                    role="presentation" >
                    <Link to="/feed">
					              <i className="las la-comments"></i>
                        <p>FEED</p>
                    </Link>
                  </span>
                </li> */}

                  {(pathname !== '/') && (
                    <li className=" wow animate__animated animate__slideInUp">
                    <span
                      className={
                      splitLocation[1] === "settings" ? "activeheader" : "false"
                      }
                      role="presentation">
                      <Link to="/settings">
                      <i className="las la-cog"></i>
                      <p>Settings</p>
                      </Link>
                    </span>
                    </li>
                  )}
              </ul>
            </div>
            <div className="bell-section">
			  {getBellIconAndLogin()}
			</div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;
