import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, useHistory, useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Profile from "./components/profile/profile";
import Followme from "./components/followme/followme";
import Friends from "./components/friends";
import CreateTable from "./components/table-poker";
import BJackCreateTable from "./components/table-blackjack";
import BackgammonCreateTable from "./components/table-backgammon";
import LudoCreateTable from "./components/table-ludo";
import JoinTable from "./components/jointable";
import PrivacyPolicy from "./components/privacy-policy";
import TermsOfUse from "./components/terms-of-use";
import NotFoundPage from "./components/NotFoundPage";
import Settings from "./components/settings/settings";
import TransactionList from "./components/transaction/transaction";
import { MainContext } from "./context";
import Feed from "./components/feed/feed";
import FeedDetails from "./components/feed/post/postDetails";
import UserProfile from "./components/userprofile/userprofile";
import "animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./responsive.css";
import PlaceMarket from "./components/place_market";
import Nft from "./components/place_market/nft";
import HelpCenter from "./components/HelpCenter/HelpCenter";
import NftDetails from "./components/place_market/nft/nftDetails";
// import { isSafari, isIOS, browserName } from "react-device-detect";
import firebase, { firebaseApp } from "./firebase";
import axios from "axios";
import Signup from "./components/signUp/landing";
import Loader from "./components/loader/loader";
import { getDoc } from "./dbFetchFunctions";
import { Button, Modal, Spinner } from "react-bootstrap";
import Web3Provider from "./components/cryptoWallet/Web3ReactProvider";
import UrlConfig from "./utils/ApiConfig";

// SPORTS BETTING
import SportsFilter from './components/sports-bet-filter/filter';
import SportsBetListing from './components/sports-bet/bet-list';
import BetDetails from './components/sports-bet/join-bet';


export const PrivateRoute = ({ user, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      user ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const App = () => {
  const history = useHistory();
  const location = useLocation();
  const publicPaths = [
    "/privacy-policy",
    "/terms-of-use",
    "/",
    "/help-center/:tab?",
    "/signup",
  ];
  const [friendList, setFriendList] = useState([]);
  const [blockList, setBlockList] = useState([]);
  const [baseStats, setBaseStates] = useState();
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [firebaseUser, setFirebaseUser] = useState();
  const [token, setToken] = useState();
  const [feeds, setFeeds] = useState([]);
  const [notification, setNotification] = useState([]);
  const [isOffline, setISOffline] = useState(false);
  const [post, setPost] = useState([]);
  const [inGame, setInGame] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [gameLink, setGameLink] = useState("");
  const [leaveTableUrl, setLeaveLink] = useState("");
  const [showInGamePopup, setShowInGamePopup] = useState(false);
  const [loader, setLoader] = useState(false);
  const [renderSignout, setrenderSignout] = useState(false);
  // const { firebaseUser, token} = useContext(MainContext);

  // useEffect(() => {
  //   if (
  //     window.Notification &&
  //     !isIOS &&
  //     !isSafari &&
  //     browserName !== "Firefox" &&
  //     Notification.permission !== "denied"
  //   ) {
  //     try {
  //       let messaging = firebase.messaging(firebaseApp);
  //       messaging
  //         ?.getToken({
  //           vapidKey:
  //             process.env.REACT_APP_VAPID_KEY,
  //         })
  //         .then(async (data) => {
  //           if (data) {
  //             if (Notification.permission !== "granted")
  //               await axios.get(
  //                 `${UrlConfig.pushTokenAPI}`,
  //                 { headers: { fmctoken: data }, params: { fmctoken: data } }
  //               );
  //             localStorage.setItem("fmcToken", data);
  //           }
  //         });
  //     } catch (error) {}
  //   }
  // }, []);

  window.addEventListener("offline", () => {
    setISOffline(true);
  });

  window.addEventListener("online", () => {
    setISOffline(false);
  });
  const handleLeave = async () => {
    setLoader(true);
    const res = await axios.get(leaveTableUrl);
    if (res.status === 200) {
      window.location.reload();
    }
  };

  useEffect(() => {
    let unsubscribe;
    const doSignout = (usid, token) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          renderSignout(true);
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get(
          UrlConfig.cookieSetterAPI,
          {
            params: {
              isLogout: "yes",
              uid: usid,
            },
            headers: {
              idtoken: localStorage.getItem("idtoken") || token,
              wid: localStorage.getItem("walletid"),
            },
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          if (response.data.error === "no error") {
            localStorage.removeItem("idtoken");
        localStorage.removeItem("walletid");
        localStorage.removeItem("provider");
        history.push("/");
          }
        })
        .catch((error) => {
          console.log("Error req", error);
        });
        
    };

    const checkIsLoggedIn = async (usid) => {
      unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          const token = await firebase.auth().currentUser.getIdToken(true);
          setToken(token);
          setFirebaseUser(user);
          if(!baseStats){
          const baseStat = await getDoc(user.uid, token);
          setBaseStates(baseStat);
          }
          if (
            window.location.pathname === "/"
          ) {
            setIsLoading(false);
            history.push("/profile");
            history.push({
              pathname: "/profile",
              state: {
                newRegister:
                  window.location.pathname === "/"
              },
            });
          } else {
            setIsLoading(false);
          }
        } else if (!publicPaths.includes(window.location.pathname)) {
          history.push("/");
          setIsLoading(false);
        }
        setIsLoading(false);
       
      }, (err) => {
        if(err){
          console.log("not logged in", err);
          //logout api
          doSignout(usid, token);
        }
      });
    };

    if (!firebaseUser || !token) checkIsLoggedIn();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    const checkInGame = async (usid) => {
      const pokerCheck = await axios.get(
        `${UrlConfig.pokerServer}/checkUserInGame/${process.env.REACT_APP_ENV_MODE}/${usid}`
      );
      if (pokerCheck.data.gameStatus === "InGame") {
        setInGame(true);
        setShowInGamePopup(true);
        setLeaveLink(pokerCheck.data.leaveTableUrl);
        setGameLink(pokerCheck.data.link);
        setTimeout(() => {
          window.location.reload();
        }, 180000);
      }
    };
      if(firebaseUser){
        checkInGame(firebaseUser.uid);
      }
  }, [location, firebaseUser])

  if (window.location.pathname.includes(publicPaths)) {
    return (
      <div className="App">
        <div className="main-wrapper">
          <React.Fragment>
            <main>
              <div className="main_content">
                <Switch>
                  <Route
                    path="/privacy-policy"
                    render={() => <PrivacyPolicy />}
                  />
                  <Route path="/terms-of-use" render={() => <TermsOfUse />} />
                  <Route exact path="/" render={() => <Signup />} />
                  <Route
                    path="/help-center/:tab?"
                    render={() => <HelpCenter />}
                  />
                </Switch>
              </div>
            </main>
          </React.Fragment>
        </div>
      </div>
    );
  }
  if (isLoading) {
    return <Loader />;
  }
  return (
    <MainContext.Provider
      value={{
        friendList: friendList,
        blockList: blockList,
        followers,
        following,
        feeds,
        notification,
        setInGame,
        inGame,
        baseStats,
        setBaseStates,
        firebaseUser,
        setFirebaseUser,
        token,
        setToken,
        setNotification: (e) => setNotification(e),
        setFeeds: (e) => setFeeds(e),
        setFollowers: (e) => setFollowers(e),
        setFollowing: (e) => setFollowing(e),
        setFriendList: (e) => {
          setFriendList(e);
        },
        setBlockList: (e) => {
          setBlockList(e);
        },
        post,
        setPostData: (e) => setPost(e),
      }}
    >
      <Web3Provider>
      <div className="App">
        <div className="main-wrapper">
          <React.Fragment>
            <main>
              <div className="main_content">
                <Switch>
                  <Route
                    path="/privacy-policy"
                    render={() => <PrivacyPolicy />}
                  />
                  <Route path="/terms-of-use" render={() => <TermsOfUse />} />
                  <Route exact path="/" render={() => <Signup />} />
                  <Route
                    path="/help-center/:tab?"
                    render={() => <HelpCenter />}
                  />
                  <Route exact path="/profile" render={() => <Profile />} />
                  <Route exact path="/followme" render={() => <Followme />} />
                  <Route path="/friends" render={() => <Friends />} />
                  <Route path="/table-poker" render={() => <CreateTable />} />
                  <Route
                    path="/table-blackjack"
                    render={() => <BJackCreateTable />}
                  />
                  <Route
                    path="/table-backgammon"
                    render={() => <BackgammonCreateTable />}
                  />
                  <Route
                    path="/table-ludo"
                    render={() => <LudoCreateTable />}
                  />
                  <Route path="/jointable/:game" render={() => <JoinTable />} />
                  <Route path="/settings" render={() => <Settings />} />
                  <Route
                    path="/transaction"
                    render={() => <TransactionList />}
                  />
                  <Route path="/feed" render={() => <Feed />} />
                  <Route
                    path="/userprofile/:id"
                    render={() => <UserProfile />}
                  />
                  <Route
                    path="/feed-detail/:postId"
                    render={() => <FeedDetails />}
                  />
                  <Route
                    path="/marketplace"
                    exact
                    render={() => <PlaceMarket />}
                  />
                  <Route
                    path="/help-center/:tab?"
                    render={() => <HelpCenter />}
                  />
                  <Route path="/marketplace/nft" exact render={() => <Nft />} />
                  <Route
                    path="/marketplace/nft/:tokenId"
                    render={() => <NftDetails />}
                  />
                 
                  <Route exact path='/sports-tickets/open' render={() => <SportsFilter />} />
                  <Route exact path='/sports-tickets/:betId' render={() => <BetDetails />} />
                  <Route exact path='/sports-tickets' render={() => <SportsBetListing />} />
                  
                 
                  <Route component={NotFoundPage} />
                </Switch>
              </div>
            </main>
          </React.Fragment>
        </div>
        <Modal
          show={showInGamePopup}
          centered
          className="friends-popup in-game"
          size="lg" >
          <Modal.Body>
            <div className="in-game-popup">
              <h6>You have an active game</h6>
              <div className="action">
               {leaveTableUrl ? <Button
                  className="leave-game"
                  onClick={handleLeave}
                  disabled={loader} >
                  {loader ? <Spinner animation="border" /> : "Leave Game"}
                </Button> : ""}
                <Button
                  className="btn-gold rejoin-class"
                  onClick={() => (window.location.href = gameLink)}
                  disabled={loader} >
                    Rejoin
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Toaster containerStyle={{ zIndex: 9999999999 }} />

        {isOffline ? (
          <div className="offline-mode">Couldn't refresh, Offline</div>
        ) : (
          ""
        )}
      </div>
      </Web3Provider>
    </MainContext.Provider>
    
  );
};

export default App;