// import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { MainContext } from "../../context";
import { updateReadNotification } from "../../dbFetchFunctions";
import "./index.css";

const Bell = (props) => {
  const history = useHistory();
  const [state, setState] = useState({
    Uid: props.uid,
    redirect: null,
    notification: props.notification,
    unreadCount: props.unreadCount,
    idToken: props.idToken,
  });
  const context1 = useContext(MainContext);

  // const getNotification = async (token) => {
  //   const res = await axios.get("https://fetch-noti-t3e66zpola-uc.a.run.app/", {
  //     headers: {
  //       idToken: token,
  //     },
  //   });
  //   const context = context1;
  //   if (res.data.success) {
  //     context.setNotification(res.data.notiArray);
  //     const count = res.data.notiArray.reduce((prev, curr) => {
  //       return prev + !curr.isRead;
  //     }, 0);
  //     setState({ notification: res.data.notiArray, unreadCount: count });
  //   }
  // };

  useEffect(() => {
    const context = context1;
    if (context.notification.length) {
      const count = context.notification.reduce((prev, curr) => {
        return prev + !curr.isRead;
      }, 0);
      setState({
        notification: context.notification,
        unreadCount: count,
      });
    } else {
      // getNotification(state.idToken);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.notification]);


  return (
    <div className="notification-box box">
      <div className="notifications">
        <i className="fa fa-bell"></i>
        {state.unreadCount ? (
          <span className="num">{state.unreadCount} {" "}</span>
        ) : (
          ""
        )}
        
        <ul className="ul-bell">
          {state.notification && state.notification.length !== 0 ? (
            state.notification.map((noti, i) => (
              <li
                className={`icon ${noti.isRead ? "" : "unread"}`}
                key={noti.date + i} >
                <span
                  className="icon"
                  onClick={() => {
                    updateReadNotification(props.uid, noti.docId);
                    history.push(`/userprofile/${noti.uid}`);
                  }} >
                  <img
                    className="notification-img"
                    src={noti.photo}
                    alt="notified-user-LV"
                  />
                </span>
                <span
                  className="text"
                  onClick={() => {
                    updateReadNotification(props.uid, noti.docId);
                    history.push(`/feed-detail/${noti.postId}`);
                  }} >
                  {noti.text}
                </span>
              </li>
            ))
          ) : (
            <li>
              <span className="text">No notifications</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Bell;