import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getDoc, getUserStats } from "../../dbFetchFunctions";
import firebase from "../../firebase";
import axios from "axios";
import toast from "react-hot-toast";
import Lottie from "react-lottie";
import loading from "../../assets/animation/loading.json";

//Animations
import Level100 from '../../assets/animation/Level100.png';
import Level100GIF from '../../assets/animation/gifs/Level-up.gif';
import Games100 from '../../assets/animation/Games100.png';
import GameGIF from '../../assets/animation/gifs/Games-gif.gif';
import Wins100 from '../../assets/animation/Wins100.png';
import WinsGif from '../../assets/animation/gifs/Wins-gif.gif';
import Lost100 from '../../assets/animation/Lost100.png';
import LostGif from '../../assets/animation/gifs/Lost-gif.gif';
import Friends100 from '../../assets/animation/Friends100.png';
import FriendsGif from '../../assets/animation/gifs/Friends-gif.gif';
import Time100 from '../../assets/animation/Time100.png';
import TimeGif from '../../assets/animation/gifs/Time-gif.gif';

import "./followme.css";

const loadingLottie = {
    loop: true,
    autoplay: true,
    animationData: loading,
  };

class Follome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Uid: this.props.followId,
      pending: true,
      followNick: this.props.nick,
      isFollowing: this.props.isFollowing,
      avatar: this.props.avatar,
      Hplayed: this.props.Hplayed,
      Friends: this.props.Friends,
      Level: this.props.Level,
      Games: this.props.Games,
      Wins: this.props.Wins,
      Losses: this.props.Losses,

      //Animations isHovered
      isLevelHovered: false,
      isGamesHovered: false,
      isWinsHovered: false,
      isLostHovered: false,
      isFriendsHovered: false,
      isTimeHovered: false,
      isRatioHovered: false,

      disableBtn: false,
      stats: {
        Level: "",
        wins: "",
        losses: "",
        friends: "",
        followers: "",
        following: "",
        games: "",
        coins: "",
      },
    };
  }

  idToken;

  async componentDidMount() {

  }

  handleMouseEnter = (param) => {
    if (param === 'level') {
      this.setState({
        isLevelHovered: true,
      });
    } else if (param === 'games') {
      this.setState({
        isGamesHovered: true,
      });
    } else if (param === 'wins') {
      this.setState({
        isWinsHovered: true,
      });
    } else if (param === 'lost') {
      this.setState({
        isLostHovered: true,
      });
    } else if (param === 'friends') {
      this.setState({
        isFriendsHovered: true,
      });
    } else if (param === 'time') {
      this.setState({
        isTimeHovered: true,
      });
    } else if (param === 'ratio') {
      this.setState({
        isRatioHovered: true,
      });
    }
  };

  handleMouseLeave = (param) => {
    if (param === 'level') {
      this.setState({
        isLevelHovered: false,
      });
    } else if (param === 'games') {
      this.setState({
        isGamesHovered: false,
      });
    } else if (param === 'wins') {
      this.setState({
        isWinsHovered: false,
      });
    } else if (param === 'lost') {
      this.setState({
        isLostHovered: false,
      });
    } else if (param === 'friends') {
      this.setState({
        isFriendsHovered: false,
      });
    } else if (param === 'time') {
      this.setState({
        isTimeHovered: false,
      });
    } else if (param === 'ratio') {
      this.setState({
        isRatioHovered: false,
      });
    }
  };

  hendlelFollowMe() {
    this.setState({
      disableBtn: true,
    });
    const Uid = this.props.followId;
    const nickname = this.props.nick;

    if (this.state.isFollowing === "following") {
        toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
            id: "toast-loading",
            duration: 6000,
            style: {
              borderRadius: "5px",
              background: "#333",
              color: "#fff",
            },
        });
      axios
        .get("https://unfollow-t3e66zpola-lz.a.run.app", {
          params: { frId: Uid },
          headers: { idtoken: this.idToken },
        })
        .then((response) => {
          if (response.data) {
            this.setState({
              disableBtn: false,
            });
            this.followingTable = response.data.followers;
            if (
              response.data.error === "no error" &&
              response.data.success === true
            ) {
              this.setState({
                isFollowing: "not following",
              });
              toast.success("You've just unfollowed @" + nickname + ".",  {
                id: "toast-success",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
            });
            }
            if (response.data.error === "must be follower to unfollow") {
              this.setState({
                disableBtn: false,
              });
              toast.error("You're already UnFollow @" + nickname, {
                id: "toast-error",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
              });
            }
          } else if (response.data.error !== "no error") {
            this.setState({
              disableBtn: false,
            });
            toast.error(response.data.error, {
                id: "toast-error",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
            });}
        })
        .catch((error) => {
          console.log("Error req", error);
          this.setState({
            disableBtn: false,
          });
        });
    }

    if (this.state.isFollowing === "not following") {
        toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
            id: "toast-loading",
            duration: 6000,
            style: {
              borderRadius: "5px",
              background: "#333",
              color: "#fff",
            },
        });
      axios
        .get("https://follow-t3e66zpola-lz.a.run.app", {
          params: { frId: Uid },
          headers: { idtoken: this.idToken },
        })
        .then((response) => {
          if (response.data) {
            this.setState({
              disableBtn: false,
            });
            if (
              response.data.error === "no error" &&
              response.data.success === true
            ) {
              this.setState({
                disableBtn: false,
              });
              this.setState({
                isFollowing: "following",
              });
              toast.success("You're now following @" + nickname, {
                id: "toast-success",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
              });
            }
            if (
              response.data.error === "no error" &&
              response.data.success === true &&
              response.data.special ===
                "You have removed this follower in the past"
            ) {
              this.setState({
                isFollowing: "following",
              });
              this.setState({
                disableBtn: false,
              });
              toast.success(
                "You're now following @" +
                  nickname +
                  ", notice that you removed him from following you.",
                {
                    style: {
                        maxWidth: 800,
                        fontSize: "14px",
                        borderRadius: "15px",
                    },
                }
              );
            }
            if (response.data.error === "already following him") {
              this.setState({
                disableBtn: false,
              });
              toast.error("You're already following @" + nickname, {
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
            });
            }
            if (response.data.error === "You are rejected follower") {
              this.setState({
                disableBtn: false,
              });
              toast.error("You gracefully declined @" + nickname + "from following you.", {
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
              });
            }
            if (response.data.error === "you can not follow yourself") {
              this.setState({
                disableBtn: false,
              });
              toast.error("Oops! You can't follow yourself. You're already the most awesome version of you!", {
                id: "toast-error",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
              });
            }
          } else if (response.data.error !== "no error") {
            this.setState({
              disableBtn: false,
            });
            toast.error(response.data.error, {
                id: "toast-error",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
            }); }
        })
        .catch((error) => {
          console.log("Error req", error);
          this.setState({
            disableBtn: false,
          });
        });
    }

  }

  render() {

    return (
      <div className="profile">
        <div className="profile-section">
          <div className="main-body">
            <div className="row gutters-sm">
              <div className="col-md-12 mb-12 center-item">
                <div className="card">
                  <div className="card-body">
                    <div className="profile-box d-flex flex-column text-left">
                      <div className="follow-me-first">
                        <div className="follow-me-img">
                          {/* <Link to={`/userprofile/${this.state.Uid}`}> */}
                            <img
                              src={this.props.avatar}
                              className="avatar rounded-circle cursor followme-img"
                              alt="Avatar"
                            />
                          {/* </Link> */}
                        </div>

                        <div className="follow-me-info">
                          <h5>{this.state.followNick}</h5>
                          {/* <div className="followme-box">
                            {this.state.isFollowing === "following" && (
                              <div
                                className={`followMebtn ${
                                  this.state.disableBtn ? "disable-btn" : ""
                                }`}
                              >
                                <i className="checkColor fa fa-check-circle"></i>
                                <button
                                  className="followMeBtn"
                                  onClick={() => this.hendlelFollowMe()}
                                >
                                  Unfollow
                                </button>{" "}
                              </div>
                            )}

                            {this.state.isFollowing === "not following" && (
                              <div
                                className={`followMebtn ${
                                  this.state.disableBtn ? "disable-btn" : ""
                                }`}
                              >
                                <i className="fa fa-check-circle"></i>
                                <button
                                  className="followMeBtn"
                                  onClick={() => this.hendlelFollowMe()}
                                >
                                  Follow me
                                </button>{" "}
                              </div>
                            )}

                            {this.state.isFollowing === "removed following" && (
                              <span></span>
                            )}
                          </div> */}

                          <div className="followers-followMe">
                            {/* <p className="alignleft">
                              <small>
                                {this.state.stats.followers}
                                <span> Followers</span>{" "}
                              </small>
                            </p> */}
                            
                            {/* <p className="alignright">
                              <small>
                                {this.state.stats.following}
                                <span> Following</span>{" "}
                              </small>
                            </p> */}
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card mt-3">
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap"
                        onMouseEnter={() => this.handleMouseEnter('friends')}
                        onMouseLeave={() => this.handleMouseLeave('friends')} >
                        <h6 className="mb-0">
                        <img
                          src={this.state.isFriendsHovered ? FriendsGif :  Friends100}
                          alt="friends"
                          className="image followme-ani" /> Friends
                        </h6>
                        <span className="text-secondary">
                          {this.props?.Friends}
                        </span>
                      </li>

                      <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap"
                      onMouseEnter={() => this.handleMouseEnter('level')}
                      onMouseLeave={() => this.handleMouseLeave('level')} >
                        <h6 className="mb-0">
                          <img
                              src={this.state.isLevelHovered ? Level100GIF :  Level100}
                              alt="Level"
                              className="image followme-ani"
                          /> Level
                        </h6>
                        <span className="text-secondary">
                          {this.props?.Level}
                        </span>
                      </li>

                      <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap"
                        onMouseEnter={() => this.handleMouseEnter('games')}
                        onMouseLeave={() => this.handleMouseLeave('games')} >
                        <h6 className="mb-0">
                        <img
                          src={this.state.isGamesHovered ? GameGIF :  Games100}
                          alt="Games"
                          className="image followme-ani" /> Games
                        </h6>
                        <span className="text-secondary">
                          {this.props?.Games}
                        </span>
                      </li>

                      <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap"
                        onMouseEnter={() => this.handleMouseEnter('wins')}
                        onMouseLeave={() => this.handleMouseLeave('wins')} >
                        <h6 className="mb-0">
                          <img
                            src={this.state.isWinsHovered ? WinsGif :  Wins100}
                            alt="wins"
                            className="image followme-ani" /> Wins
                        </h6>
                        <span className="text-secondary">
                          {this.props?.Wins}
                        </span>
                      </li>

                      <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap"
                        onMouseEnter={() => this.handleMouseEnter('lost')}
                        onMouseLeave={() => this.handleMouseLeave('lost')} >
                        <h6 className="mb-0">
                        <img
                          src={this.state.isLostHovered ? LostGif :  Lost100}
                          alt="lost"
                          className="image followme-ani" /> Losses
                        </h6>
                        <span className="text-secondary">
                          {this.props?.Losses}
                        </span>
                      </li>

                      <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap"
                      onMouseEnter={() => this.handleMouseEnter('time')}
                      onMouseLeave={() => this.handleMouseLeave('time')} >
                        <h6 className="mb-0">
                        <img
                          src={this.state.isTimeHovered ? TimeGif :  Time100}
                          alt="time"
                          className="image followme-ani" /> Playing hours
                          </h6>
                        <span className="text-secondary">
                          {this.props?.Hplayed}
                        </span>
                      </li>

                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Follome;
