import React, { Component } from "react";
import { Table } from "react-bootstrap";
import loadericon from "../../assets/animation/loader.webp";
import firebase from "../../firebase";
import axios from "axios";
import toast from "react-hot-toast";
import Lottie from "react-lottie";
import loading from "../../assets/animation/loading.json";
import "./tablelist.css";
import { MainContext } from "../../context";
import { Link } from "react-router-dom";

const loadingLottie = {
    loop: true,
    autoplay: true,
    animationData: loading,
  };

class Following extends Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);
    this.state = {
      Uid: this.props.uid,
      following: [],
      suggestionsYouKnow: [],
      search: "",
      isFollowing: this.props.isFollowing,
      pending: true,
      isActive: this.props.isFollowing,
      isClick: "",
    };
  }

  idToken;
  followingTable;
  youknowTable;

  async componentDidMount() {
    const context = this.context;
    this.setState({
      following: context.following,
    });
    this.idToken = await firebase.auth().currentUser.getIdToken(true);
    if (this.state.following.length === 0) {
      this.setState({
        isFollowing: false,
      });
    } else {
      this.setState({
        isActive: true,
      });
    }

    // Suggestions For You
    await axios
      .get("https://people-know-t3e66zpola-ew.a.run.app", {
        params: { usid: this.state.Uid, isFollowers: "yes" },
        headers: { idtoken: this.idToken },
      })
      .then((response) => {
        if (response.data) {
          this.youknowTable = response.data.array;
          this.setState({
            suggestionsYouKnow: this.youknowTable,
            pending: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error req", error);
      });
  }

  hendlelUnfollow(event) {
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
        id: "toast-loading",
        duration: 6000,
        style: {
          borderRadius: "5px",
          background: "#333",
          color: "#fff",
        },
    });
    var res = String(event);
    const param = res.split(",");
    const Uid = param[0];
    const nickname = param[1];
    axios
      .get("https://unfollow-t3e66zpola-lz.a.run.app", {
        params: { frId: Uid },
        headers: { idtoken: this.idToken },
      })
      .then(async (response) => {
        if (response.data) {
          this.followingTable = response.data.followers;
          if (
            response.data.error === "no error" &&
            response.data.success === true
          ) {
            toast.success("You've just unfollowed @" + nickname + ".",  {
                id: "toast-success",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
            });
            await axios
              .get("https://followers-table-t3e66zpola-ez.a.run.app", {
                params: { type: "following" },
                headers: { idtoken: this.idToken },
              })
              .then((response) => {
                const context = this.context;
                if (response.data.success) {
                  context.setFollowing(response.data.followers);
                  this.setState({
                    following: response.data.followers,
                  });
                }
              })
              .catch((error) => {
                console.log("Error req", error);
              });
          }

          if (response.data.error === "must be follower to unfollow") {
            toast.error("You're already UnFollow @" + nickname, {
                id: "toast-error",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
              });
          }
        } else if(response.data.error !== "no error"){
            toast.error(response.data.error, {
                id: "toast-error",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
              }); }
      })
      .catch((error) => {
        console.log("Error req", error);
      });
  }

  hendlelFollow(event) {
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
        id: "toast-loading",
        duration: 6000,
        style: {
          borderRadius: "5px",
          background: "#333",
          color: "#fff",
        },
    });
    var res = String(event);
    const param = res.split(",");
    const Uid = param[0];
    const nickname = param[1];
    axios
      .get("https://follow-t3e66zpola-lz.a.run.app", {
        params: { frId: Uid },
        headers: { idtoken: this.idToken },
      })
      .then(async (response) => {
        this.setState({
          isClick: "",
          suggestionsYouKnow: this.state.suggestionsYouKnow.filter(
            (el) => el.uid !== Uid
          ),
        });
         if (response.data) {
          if (
            response.data.error === "no error" &&
            response.data.success === true
          ) {
            toast.success("You're now following @" + nickname, {
                id: "toast-success",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
              });
            await axios
              .get("https://followers-table-t3e66zpola-ez.a.run.app", {
                params: { type: "following" },
                headers: { idtoken: this.idToken },
              })
              .then((response) => {
                const context = this.context;
                if (response.data.success) {
                  context.setFollowing(response.data.followers);
                  this.setState({
                    following: response.data.followers,
                  });
                }
              })
              .catch((error) => {
                console.log("Error req", error);
              });
          }
          if (response.data.error === "already follower or rejected follower") {
            toast.error("You're already following @" + nickname, {
                id: "toast-error",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
            });
          }
          if (response.data.error === "already following him") {
            toast.error("You're already following @" + nickname, {
                id: "toast-error",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
              });
          }
          if (response.data.error === "You are rejected follower") {
            toast.error("@" + nickname + " has gracefully declined your request.", {
                id: "toast-error",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
              });
          }
          if (response.data.error === "you can not follow yourself") {
            toast.error("Oops! You can't follow yourself. You're already the most awesome version of you!", {
                id: "toast-error",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
              });
          }
        } else if(response.data.error !== "no error"){
            toast.error(response.data.error, {
                id: "toast-error",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
              }); }
      })
      .catch((error) => {
        this.setState({ isClick: "" });
        console.log("Error req", error);
      });
  }

  handleChange(event) {
    // Get event search value
    let searchValue = event.target.value;
    // Set the state to trigger a re-rendering
    this.setState({ search: searchValue });
  }

  render() {
    // Searching in follow => Filter results by nick.
    let following = this.state.following,
      searchString = this.state.search.trim().toLowerCase();
    if (searchString.length > 0) {
      following = this.state.following.filter((e) =>
        e.nick.toLowerCase().match(searchString)
      );
    }

    let suggestionsYouKnow = this.state.suggestionsYouKnow;

    return (
      <div className="tablelist-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {!this.state.isFollowing ? (
                <small>You are following nobody, time to start..</small>
              ) : (
                <div
                  className={
                    this.state.isActive ? "follow-search" : "disable-search"
                  }
                >
                  <input
                    id="mySearchFollowing"
                    className="form-control"
                    type="text"
                    name="search"
                    autoComplete="off"
                    placeholder="Searching..."
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>
              )}
              <div className="tablelist-content">
                <div className="tablelist-table">
                  <Table
                    striped
                    bordered
                    hover
                    variant="dark"
                    className="followingtbody">
                    <thead>
                        <th>Avatar</th>
                        <th>Name</th>
                        <th></th>
                    </thead>
                    <tbody>
                      {following
                        ? following.map((f, index) => (
                            <tr key={f.uid}>
                              <td className="followtd">
							   <Link to={`/userprofile/${f.uid}`}>
                                <img
                                  className="followingImg"
                                  src={f.avatar}
                                  alt="followingImg"
                                />
								</Link>
                              </td>
                              <td className="followtd">{f.nick}</td>
                              <td className="followtd text-center">
                                <button
                                  className="followBtn btn btn-outline-secondary btn-sm"
                                  onClick={() => {
                                    this.setState({ isClick: f.uid });
                                    this.hendlelUnfollow([f.uid, f.nick]);
                                  }}
                                  disabled={this.state.isClick === f.uid}
                                >
                                  Unfollow
                                </button>
                              </td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </Table>

                  <p>
                    <br></br>
                    <small>Suggestions For You</small>
                  </p>

                  {this.state.pending ? (
                    <div className="suggestions-loader">
                      <p>
                        <img src={loadericon} alt="loader" />
                      </p>
                    </div>
                  ) : (
                    <Table striped bordered hover variant="dark">
                    <thead>
                        <th>Avatar</th>
                        <th>Name</th>
                        <th>Socially</th>
                    </thead>
                      <tbody>
                        {suggestionsYouKnow
                          ? suggestionsYouKnow.map((f, index) => (
                              <tr key={index} id={f.id}>
                                <td className="followtd">
								<Link to={`/userprofile/${f.uid}`}>
								  <img
                                    className="followingImg"
                                    src={f.image}
                                    alt="followingImg"
                                  />
								  </Link>
                                </td>
                                <td className="followtd">{f.name}</td>
                                <td className="followtd">
                                  Related: {f.frRelated}
                                </td>
                                <td className="followtd text-center">
                                  <button
                                    className="followBtn btn btn-outline-warning btn-sm"
                                    onClick={() =>
                                      this.hendlelFollow([f.uid, f.name])} >
                                    Follow
                                  </button>
                                </td>
                              </tr>
                            ))
                          : ""}
                      </tbody>
                    </Table>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Following;
