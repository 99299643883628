import React, { useState } from 'react';
import { Modal, Spinner } from "react-bootstrap";
import ReConnect from "../cryptoWallet/ReConnect";
import walletIcon from "../../assets/images/popups/wallet-logo.png";
import "./index.css";

function CryptoNOT() {

    const [isShow, setisShow] = useState(true);

  const handleShow = () => {
    setisShow(!isShow);
    window.location.href = "/profile"
  };

  return (
    <div className="friends-content">
    <div className="row">
      <Modal
        show={isShow}
        onHide={handleShow}
        centered
        className="friends-popup">
        <Modal.Header closeButton>
          <Modal.Title><img src={walletIcon} alt="Wallet Icon" className="wallet-icon"/> <Spinner animation="border" /></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="not-popup">
                <h6>Please connect your wallet</h6>
              <div className="not-connected-w">
                <ReConnect />
              </div>
            </div>
        </Modal.Body>
      </Modal>
    </div>
  </div>
  );
};

export default CryptoNOT;