import React, { useContext, useEffect, useMemo, useState } from "react";
import Layout from "../layout/layout";
import { useWeb3React } from "@web3-react/core";
import { Form, Modal, Button, Spinner, Accordion, Tab, Row, Col, Nav } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import CryptoNOT from "../cryptoNOT/index";
import Lottie from "react-lottie";
import walletIcon from '../../assets/images/header/coin.png';
import { ethers } from "ethers";
import toast from 'react-hot-toast';
import './join-bet.css';
import { useParams } from "react-router-dom";
import SportsAbi from "../../ABI/SportsAbi.json";
import confirm from "../../assets/animation/confirm.json";
import blockc from "../../assets/animation/block.json";
import { simplifyTicket } from "../../utils";
import Loader from "../loader/loader";
import { MainContext } from "../../context";
import axios from "axios";
import UrlConfig from "../../utils/ApiConfig";

const confirmLottie = {
  loop: true,
  autoplay: true,
  animationData: confirm,
}

const blockLottie = {
  loop: true,
  autoplay: true,
  animationData: blockc,
};

const BetDetails = () => {
  const [walletConnected, setWalletConnected] = useState(true);
  const { firebaseUser } = useContext(MainContext);
  const [balance, setBalance] = useState('');
  const { account, provider } = useWeb3React();
  const { betId } = useParams();
  const [contract, setContract] = useState();
  const [ticket, setTicket] = useState();
  const [loading, setLoading] = useState(true);
  const [ticketError, setTicketError] = useState("");
  const [selections, setSelections] = useState([]);
  const [confirmationText, setconfirmationText] = useState("Please confirm the transaction");
  const [ConfirmationShow, setConfirmationShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [isVisible, setIsVisible] = useState(true);


  const userSelection = useMemo(() => {
    if (ticket) {
      return ticket.users.find(user => user.userId === firebaseUser.uid)?.selections || [];
    }
    return [];
  }, [ticket]);

  useEffect(() => {
    const getBalance = async () => {
      if (account) {
        try {
          const value = await provider?.getBalance(account);
          if (value) {
            const formattedBalance = Number(ethers.utils.formatEther(value)).toFixed(5);
            setBalance(formattedBalance);
            setWalletConnected(true);
          }
        } catch (error) {
          console.error('Error fetching balance:', error);
          setWalletConnected(false);
        }
      } else {
        setWalletConnected(false);
      }
    };
    getBalance();

    if (account && provider) {
      const contract = new ethers.Contract(process.env.REACT_APP_SPORTS_CONTRACT_ADDRESS, SportsAbi, provider.getSigner());
      setContract(contract);
      fetchTicket(contract);
    }
  }, [account, provider]);

  const fetchTicket = async (contract) => {
    if (contract) {
      try {
        setLoading(true);
        const ticket = await contract.getTicketByUserId(betId);
        setTicket(simplifyTicket(ticket));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setTicketError("Ticket not found, You will be redirected back in 3 seconds..");
        setTimeout(() => {
          window.location.href = '/sports-tickets';
        }, 3000);;
      }
    }
  }

  const validate = (formBetAmount) => {
    if (Number(balance) < formBetAmount) {
      const deficit = (formBetAmount - Number(balance)).toFixed(2);
      toast.error(
        <div>You are short <b>{deficit} Matic</b> to join the bet</div>, {
        duration: 4000,
        style: {
          maxWidth: 800,
          fontSize: "14px",
          borderRadius: "15px",
        },
      }
      );
      return false;
    }
    if (selections.length !== ticket?.matches?.length) {
      toast.error(
        <div>Please select all matches</div>, {
        duration: 4000,
        style: {
          maxWidth: 800,
          fontSize: "14px",
          borderRadius: "15px",
        },
      }
      );
      return false;
    }
    if (!firebaseUser.uid || !firebaseUser.displayName) {
      toast.error(
        <div>User not found error</div>, {
        duration: 4000,
        style: {
          maxWidth: 800,
          fontSize: "14px",
          borderRadius: "15px",
        },
      }
      );
      return false;
    }
    return true;
  };

  const resetSelection = (homeElement, tieElement, awayElement) => {
    homeElement.classList.remove("selected");
    awayElement.classList.remove("selected");
    if (tieElement) {
      tieElement.classList.remove("selected");
    }
  };

  const select = (event, game, choice) => {
    if (ticket.status == 0) {
      console.log("ticket.status::::" + ticket.status);
      if (userSelection.length) return;
      const { gameId, isDrawable } = game;
      const homeElement = document.querySelector(`.game-${gameId}home`);
      const awayElement = document.querySelector(`.game-${gameId}away`);
      let tieElement;
      if (isDrawable) {
        tieElement = document.querySelector(`.game-${gameId}tie`);
      }
      resetSelection(homeElement, tieElement, awayElement);
      event.target.classList.add("selected");
      const index = selections.findIndex((game) => game.gameId === gameId);
      const updatedMySelection = [...selections];
      if (index !== -1) {
        updatedMySelection[index].choice = choice;
      } else {
        updatedMySelection.push({
          gameId,
          choice,
        });
      }
      setSelections(updatedMySelection);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validate(ticket.amount);
    if (isValid) {
      setIsLoading(true);
      setconfirmationText('Please Confirm Transaction');
      let selection = selections.map((game) => [game.gameId, game.choice]);
      console.log("user", firebaseUser.displayName)
      const user = [firebaseUser.uid, firebaseUser.displayName, firebaseUser.photoURL, account];
      const receipt = await joinTicket(selection, user);
      if (receipt) {
        toast.success("Request to submit a bet numbered " + betId + " received.", {
          duration: 3000,
          id: "toast-success",
          style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
          },
        });
        setTimeout(() => {
          window.location.href = `/sports-tickets?ticket=${betId}`;
        }, 3000);
      }
    }
  };

  const joinTicket = async (selections, user) => {
    let tx;
    console.log("calling create tickegt")
    try {
      if (contract) {
        const depositAmountInMatic = ticket.amount;
        const valueToSend = ethers.utils.parseUnits(depositAmountInMatic.toString(), 18);
        console.log("checking call")
        const { data: { result: { FastGasPrice } = {} } = {} } = await axios.get(`${UrlConfig.gasTrackerUrl}${process.env.REACT_APP_API_TOKEN}`);
        // estimeate gasPrice using estimateGas functions
        const estimatedGasLimit = await contract.estimateGas.joinBet(
          betId, selections, user, {
          value: valueToSend,
          gasPrice: ethers.utils.parseUnits(FastGasPrice.toString() || '35', 'gwei'),
          nonce: await provider.getTransactionCount(account),
        });
        console.log("limit", ethers.BigNumber.from(Math.floor(Number(estimatedGasLimit) * 1.2)), Math.floor(Number(estimatedGasLimit) * 1.2));
        tx = await contract.joinBet(
          betId, selections, user, {
          value: valueToSend,
          gasPrice: ethers.utils.parseUnits(FastGasPrice.toString() || '35', 'gwei'),
          nonce: await provider.getTransactionCount(account),
          gasLimit: ethers.BigNumber.from(Math.floor(Number(estimatedGasLimit) * 1.2)),
        });
        setconfirmationText('Waiting For Block Confirmation');
        const receipt = await tx.wait();
        return receipt;
      }
    } catch (e) {
      console.log("error in createTicket", e);
      if (e.message.includes("execution reverted")) {
        const revertReason = await contract.provider.getTransactionReceipt(tx.hash)
          .then((receipt) => receipt ? receipt.reason : "No revert reason");
        toast.error("Revert Reason: ", revertReason, {
          duration: 4000,
          id: "toast-error",
          style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
          },
        });
        setConfirmationShow(true);
        setIsLoading(false);
        setconfirmationText('Waiting For Block Confirmation');
        return false;
      } else {
        if (e.message.includes("insufficient funds")) {
          toast.error("Not enough balance to create a game", {
            duration: 4000,
            id: "toast-error",
            style: {
              maxWidth: 800,
              fontSize: "14px",
              borderRadius: "15px",
            },
          });
        }
        setConfirmationShow(true);
        setIsLoading(false);
        return false;
      }
    }
  };

  const confirmationClose = () => {
    setConfirmationShow(!ConfirmationShow);
  };

  const toggleVisibility = () => {
    setIsVisible(prevState => !prevState);
  };



  if (!walletConnected) {
    return <CryptoNOT />
  }

  if (loading) {
    return <Loader />
  }

  if (ticketError) {
    return <div>{ticketError}</div>
  }

  return (
    <Layout>
      <div className="filter-page">
        <div className="container">
          <div className="filter-box">
            <Form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-8">
                  <div className="ticket-matches">
                    <p><span style={{ color: "#8461dc" }}>@{ticket?.users && ticket.users[0]?.name}</span> Ticket</p>
                    <div className="game-data">
                      <small className="time">
                        {ticket.status === 2 ? <b>Result:  <span style={{color: "red"}}>Finished</span></b> : <>
                          <b>Ticket will close at -</b>
                          {new Date(ticket.startDate).toLocaleDateString() + ", " + new Date(ticket.startDate).toLocaleTimeString()}
                        </>}
                      </small>
                    </div>
                    <ul>
                      {ticket?.matches && ticket.matches.map((match, i) => <MatchRenderer
                        match={match}
                        result={ticket.results[i]}
                        userSelection={userSelection[i]}
                        select={select}
                        key={match.gameId}
                      />)}
                    </ul>
                  </div>
                </div>

                {isVisible && (

                <div className="col-md-3">
                  <div className="filter-card filter-sport join-bet-info">
                    <div className="matic-balance">
                      <Form.Group controlId="formBasicFirst" className="coin-matic">
                        <div className="your-wallet">
                          <p className="your-wallet-balance">Payment: <b>{ticket.amount}</b> </p>
                          <img src={walletIcon} alt="wallet icon" className="polygon-coins" />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="player-info">
                      <p style={{ color: "#8461dc" }}>Total players: <span style={{ color: "white", fontSize: "1.2em" }}>{ticket.users.length}</span></p>
                      <p style={{ color: "#8461dc" }}>Winning pool: <span style={{ color: "white", fontSize: "1.2em" }}>{ticket.pool}</span></p>
                      <p style={{ color: "#8461dc" }}>Starting time: <span style={{ color: "white" }}>{new Date(ticket.startDate).toLocaleDateString() + ", " + new Date(ticket.startDate).toLocaleTimeString()}</span></p>
                      {/* <p style={{ color: "#8461dc" }}>Balance: <span style={{ color: "white" }}>{balance}</span> <img src={walletIcon} alt="wallet icon" className="bet-matic" /></p> */}
                    </div>

                    <div className="filter-form">
                      <div className="card-footer text-center">
                        {ticket.status > 0 ? (
                          <Button
                            id="btn-join-id"
                            type="button"
                            className="btn-gold btn-join"
                            onClick={() => setShow(true)}
                          >
                            Stats
                          </Button>
                        ) : ticket?.users && ticket.users?.find(user => user.userId === firebaseUser.uid) ?
                          <small>"This is your bet - no need to join"</small> :
                          ticket?.status == 0 && (
                            <Button
                              id="btn-join-id"
                              type="submit"
                              className="btn-gold btn-join"
                              disabled={selections.length !== ticket?.matches?.length}
                            >
                              Join
                            </Button>
                          )
                        }
                      </div>
                    </div>
                    <div className="bet-user-photo">
                      {ticket.users.slice(0, 5).map(user => (<img className="user-photo" src={user.photoUrl} alt="user-img" key={user.userId} />))}
                      {ticket.users.length > 5 && <span style={{ color: "#8461dc" }}>+ {ticket.users.length - 5} more users</span>}
                    </div>
                  </div>
                </div>
                )}

                {isMobile > 0 && (
                     <button type="button" className="floating-button" onClick={toggleVisibility}>
                      {selections.length === 0 ? (
                        <>
                        {isVisible ? (
                          <i className="fa fa-minus" aria-hidden="true"></i>
                        ) : (
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        )}
                        </>
                      ) : (
                        selections.length
                      )}
                   </button>
                    )}

              </div>
            </Form>
          </div>
        </div>
        {isLoading ? (
          <>
            <Modal
              show={true}
              onHide={() => confirmationClose()}
              centered
              className="sports-bet-pending friends-popup pending-popup">
              <Modal.Header >
                <Modal.Title>
                  <Spinner animation="border" />
                  <small className='title-pending'>
                    {confirmationText === "Waiting For Block Confirmation" ? (
                      <span style={{ color: '#8461dc' }}>{confirmationText}</span>
                    ) : (
                      confirmationText
                    )}
                  </small>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="feed-body">
                  {confirmationText === "Please Confirm Transaction" ? (
                    <Lottie options={confirmLottie} width={250} height={250} />
                  ) : <Lottie options={blockLottie} width={300} height={250} />}
                </div>
              </Modal.Body>
            </Modal>
          </>
        ) : null}
        <Modal
          show={show}
          onHide={() => setShow(false)}
          centered
          className="overview-modal friends-popup"
        >
        <Modal.Body>
          <Tab.Container defaultActiveKey={ticket?.users[0]?.userId || ''}>
            <Row>
              <Col sm={2} className="user-list-col">
                <Nav variant="pills" className="user-nav">
                  {ticket.users.map(user => (
                    <Nav.Item key={user.userId} className="user-nav-item">
                      <Nav.Link eventKey={user.userId} className="user-nav-link">
                        <img className="nav-user-photo" src={user.photoUrl} alt={`Photo of ${user.name}`} />
                        <span className="nav-user-name">{user.name}</span>
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col sm={10} className="matches-col">
                <Tab.Content>
                  {ticket.users.map(user => (
                    <Tab.Pane eventKey={user.userId} key={user.userId}>
                      <div className="ticket-matches">
                        {ticket.matches?.map((match, i) => (
                          <MatchRenderer
                            match={match}
                            result={ticket.results[i]}
                            userSelection={user.selections[i]}
                            select={select}
                            key={match.gameId}
                          />
                        ))}
                      </div>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Modal.Body>
    </Modal>
      </div>
    </Layout>
  );
};

export default BetDetails;


const MatchRenderer = ({ match, result, userSelection, select }) => {
  const matchResult = result?.choice;
  const userMatchChoice = userSelection?.choice;
  const homeStyle =
    match.home.id === matchResult
      ? userMatchChoice === match.home.id
        ? "result-win"
        : "result-lose"
      : "";

  const tieStyle =
    match.isDrawable && matchResult === 0
      ? userMatchChoice === 0
        ? "result-win"
        : "result-lose"
      : "";

  const awayStyle =
    match.away.id === matchResult
      ? userMatchChoice === match.away.id
        ? "result-win"
        : "result-lose"
      : "";
  console.log("style", match, result, userSelection);
  return (
    <li key={match.gameId} className={`game-${match.gameId}`}>
      <div className="filter-game" id={match.gameId}>
        <div className="game-box">
          <img className="img-raised1" src={match.home.logo} alt="home_team" />
          <span
            id={match.home.id}
            onClick={(event) => select(event, match, match.home.id)}
            role="button"
            tabIndex="1"
            className={`game-${match.gameId}home ${userSelection?.choice === match.home.id ? "selected" : ""} ${homeStyle}`} >
            {match.home.name}
          </span>
          <small className="home_score">Home team</small>
        </div>
        <div className="game-vs">
          {match.isDrawable ? <span
            id={match.gameId}
            onClick={(event) => select(event, match, 0)}
            role="button"
            tabIndex="0"
            className={`game-${match.gameId}tie tie-game-class ${userSelection?.choice === 0 ? "selected" : ""} ${tieStyle}`}
          >
            X
          </span> : <span>vs</span>}

        </div>
        <div className="game-box">
          <img className="img-raised1" src={match.away.logo} alt="away_team" />
          <span
            id={match.away.id}
            onClick={(event) => select(event, match, match.away.id)}
            role="button"
            tabIndex="2"
            className={`game-${match.gameId}away ${userSelection?.choice === match.away.id ? "selected" : ""} ${awayStyle}`} >
            {match.away.name}
          </span>
          <small className="away_score">Away team</small>
        </div>
      </div>
    </li>
  )
}
