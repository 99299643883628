import "./Card.css";
//import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
//import Lottie from "react-lottie";
//import walletlottie from '../../../../assets/animation/wallet.json'

//const coinanim = {
//  loop: true,
//  autoplay: true,
//  animationData: walletlottie,
//};

const Card = ({ nftData, createBuyOrder, createMoralisBuyOrder, tokenId }) => {
  const history = useHistory();
  const {
    description,
    image_url,
    name,
  } = nftData || {};




  return (
    <div
      className="col-md-4 mb-4 mobile-nft"
      onClick={() => {
        history.push(`/marketplace/nft/${tokenId}`);
      }}
    >
      <div className="nft-card">
        <img src={image_url} alt="" />
        <div className="nft-card-body">
          <h2>{name}</h2>
          <h5>{description}</h5>
          
          {/*<h5>Owned by - {ownerUser?.username}</h5>
          <h5>Created by - {user?.username}</h5>*/}
          <div className="nft-card-btn">
            
              <button
                
              >
                Make Offer
              </button>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
