import React from "react";
import MetaMaskInstall from "./images/Install-metamask.png";
import SettingWallet from "./images/Setting-up-wallet.png";
import WejeAccount from "./images/weje-account.png";
import PolyNet from "./images/Polygon-network.png";
import CreateAccount from "./images/create_account.png";
import MaticSent from "./images/welcome-weje.png";

const Crypto = () => {
  return (
    <div className="tabbedcontent about-us">
      <h4 className="title">
        Create a WEJE account!
      </h4>
        <div className="container">
            <div className="row">
                <div className="item col-xs-6 col-lg-6 mobile-view-crypto">
                        <div className="thumbnail card-crypto">
                            <div className="caption card-body help-card-body">
                                <h6 className="group card-title inner list-group-item-heading">
                                    <span className="num-title step-text">① </span> Set up a crypto wallet</h6>
                                    <div className="img-event">
                                        <img className="group list-group-image img-fluid" src={MetaMaskInstall} alt="" />
                                    </div>
                                    <div className="info-help">
                                        <p className="group inner list-group-item-text">Install <a className="metamask-link" target="_blank" href="https://metamask.io/download/" rel="noreferrer">Metamask</a> browser extension.</p>
                                        <p className="group inner list-group-item-text">Install <a className="coinbase-link" target="_blank" href="https://www.coinbase.com/wallet/downloads" rel="noreferrer">Coinbase</a> browser extension.</p>
                                        <p className="group inner list-group-item-text">Install <a className="trust-link" target="_blank" href="https://trustwallet.com/download/" rel="noreferrer">Trust Wallet</a> browser extension.</p> 
                                    </div>
                            </div>
                        </div>
                    </div>
 
                    <div className="item col-xs-6 col-lg-6 mobile-view-crypto">
                        <div className="thumbnail card-crypto">
                            <div className="caption card-body help-card-body">
                                <h6 className="group card-title inner list-group-item-heading">
                                <span className="num-title step-text">② </span> Connect your wallet</h6>
                                    <div className="img-event">
                                        <img className="group list-group-image img-fluid" src={WejeAccount} alt="" />
                                    </div>
                                    <div className="info-help">
                                        <p className="group inner list-group-item-text"><span className="min-step-text">① </span> Enter your provider password on your crypto wallet prompt</p>
                                        <p className="group inner list-group-item-text"><span className="min-step-text">② </span> Click on <b>'Connect Wallet'</b> button || Refresh the page</p>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className="item col-xs-6 col-lg-6 mobile-view-crypto">
                        <div className="thumbnail card-crypto">
                            <div className="caption card-body help-card-body">
                                <h6 className="group card-title inner list-group-item-heading">
                                <span className="num-title step-text">③ </span> Create a WEJE account</h6>
                                    <div className="img-event">
                                        <img className="group list-group-image img-fluid" src={CreateAccount} alt="" />
                                    </div>
                                    <div className="info-help">
                                        <p className="group inner list-group-item-text"><span className="min-step-text">① </span> Agree to terms and conditions</p>
                                        <p className="group inner list-group-item-text"><span className="min-step-text">② </span> Click <b>'Create Account'</b></p>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className="item col-xs-6 col-lg-6 mobile-view-crypto">
                        <div className="thumbnail card-crypto">
                            <div className="caption card-body help-card-body">
                                <h6 className="group card-title inner list-group-item-heading">
                                <span className="num-title step-text">④ </span> Welcome to WEJE! 😃</h6>
                                    <div className="img-event">
                                        <img className="group list-group-image img-fluid" src={MaticSent} alt="" />
                                    </div>
                                    <div className="info-help">
                                        <p className="group inner list-group-item-text">
                                            <span className="min-step-text"><b className="con-now">Congratulations!</b> Now, let's play!</span></p>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
};

export default Crypto;
