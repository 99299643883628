import React from "react";
import { Web3ReactProvider } from '@web3-react/core';
import { hooks as metaMaskHooks, metaMask } from './metamask';

const connectors= [
  [metaMask, metaMaskHooks],
]

function Web3Provider({children}) {
  return (
    <Web3ReactProvider connectors={connectors}>
      {children}
    </Web3ReactProvider>
  );
}

export default Web3Provider;