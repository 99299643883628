import React from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import firebase from "../../firebase";
import { getPost } from "../../dbFetchFunctions";
import Lottie from "react-lottie";
import loading from "../../assets/animation/loading.json";
import Post from "./post";
import "./index.css";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "../loader/loader";
import { MainContext } from "../../context";
import { visibilityIcon } from "../../helpers/formatters";

const loadingLottie = {
  loop: true,
  autoplay: true,
  animationData: loading,
};

class FeedMenu extends React.Component {
  static contextType = MainContext;

  constructor() {
    super();
    this.state = {
      date_start: "",
      signedin: false,
      create_a_post_show: "",
      who_can_see_show: false,
      privacy: { type: "all", label: "Anyone" },
      uid: null,
      nick: "",
      image: "",
      error: "",
      posts: [],
      loading: true,
      paginate: 20,
      page: 1,
      newpost: "",
      idToken: null,
      rotating: false,
      allowCom: true,
      show: "",
      replyShow: "",
      selectedPost: "",
    };

    this.handleLikePost = this.handleLikePost.bind(this);
    this.handleComment = this.handleComment.bind(this);
    this.handleShare = this.handleShare.bind(this);
    this.addPost = this.addPost.bind(this);
    this.handleNewPost = this.handleNewPost.bind(this);
    this.handleReplyShow = this.handleReplyShow.bind(this);
    this.handleCommentShow = this.handleCommentShow.bind(this);
    this.handleLikeCommentReply = this.handleLikeCommentReply.bind(this);
  }

  async componentDidMount() {
    // Print out today date in format dd-mm-yyyy
    var dateObj = new Date()
      .toISOString()
      .replace(/T/, " ")
      .replace(/\..+/, "");
    dateObj = dateObj.split(" ")[0];
    this.date_start = dateObj;
    const context = this.context;
    let img = context.firebaseUser?.photoURL;
    if (
      img ===
      "https://storage.cloud.google.com/mycool-net-app.appspot.com/default-avatar/default-avatar_homePageLogo.png"
    ) {
      const hdd = firebase.storage();
      const gsRef = hdd.refFromURL(img);
      await gsRef.getDownloadURL().then((image) => (img = image));
    }

    const { baseStats } = context;

    this.setState({ loading: true });
    const post = await getPost(
      context.firebaseUser?.uid,
      undefined,
      this.state.paginate,
      this.state.page
    );
    context.setPostData(post);

    this.setState({ loading: false, posts: post });
    if (baseStats) {
      this.setState({
        idToken: context.token,
        pending: false,
        signedin: true,
        uid: context.firebaseUser?.uid,
        nick: context.firebaseUser?.displayName,
        image: img,
      });
    }
  }

  closeCreatePost() {
    this.setState({
      create_a_post_show: "",
    });
  }

  handleCommentShow = (value) => {
    if (value === this.state.show) {
      this.setState({ show: "" });
    } else this.setState({ show: value });
  };

  handleReplyShow = (value) => {
    if (value === this.state.replyShow) {
      this.setState({ replyShow: "" });
    } else this.setState({ replyShow: value });
  };

  whoCanSee() {
    this.setState({
      who_can_see_show: !this.state.who_can_see_show,
    });
  }

  addPost(value, id) {
    this.setState({
      create_a_post_show: value,
      selectedPost: id,
    });
  }

  selectPostType(event) {
    this.setState({
      privacy: JSON.parse(event.target.value),
    });
  }

  async handleNewPost() {
    if (this.state.newpost === "" && this.state.newpost.length < 50) {
      return toast.error("Text field is empty", {
        id: "toast-error",
        style: {
          maxWidth: 800,
          fontSize: "14px",
          borderRadius: "15px",
        },
      });
    }
    this.setState({
      rotating: true,
    });
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
      id: "toast-loading",
      duration: 6000,
      style: {
        borderRadius: "5px",
        background: "#333",
        color: "#fff",
      },
    });
    const res = await axios.get(
      "https://create-post-t3e66zpola-uc.a.run.app/",
      {
        params: {
          text: this.state.newpost,
          privacy: this.state.privacy.type,
          allowCom: this.state.allowCom ? "yes" : "no",
          action: "newPost",
          feedUid: this.state.uid,
        },
        headers: {
          Idtoken: this.state.idToken,
        },
      }
    );
    this.setState({
      rotating: false,
      newpost: "",
    });
    if (res.data.error === "no error") {
      toast.success("Thank you for sharing.", {
        id: "toast-success",
        style: {
          maxWidth: 800,
          fontSize: "14px",
          borderRadius: "15px",
        },
      });
      this.setState({
        create_a_post_show: "",
        who_can_see_show: "",
        newpost: "",
      });
      const post = await getPost(
        this.state.uid,
        undefined,
        this.state.paginate,
        this.state.page
      );
      if (post) {
        const context = this.context;
        context.setPostData(post);
        this.setState({ posts: post });
      }
    } else {
      toast.error(res.data.error, {
        id: "toast-error",
        style: {
          maxWidth: 800,
          fontSize: "14px",
          borderRadius: "15px",
        },
      });
    }
  }

  async handleLikePost(id, commentId, replyId) {
    this.setState({ rotating: true });
    const res = await axios.get(
      "https://like-com-share-t3e66zpola-ue.a.run.app/",
      {
        params: {
          action: "like",
          comId: commentId,
          replyId,
        },
        headers: {
          Idtoken: this.state.idToken,
          Postid: id,
        },
      }
    );
    if (res.data.error === "no error") {
      const post = await getPost(
        this.state.uid,
        "noupdate",
        this.state.paginate,
        this.state.page
      );
      if (post) {
        const context = this.context;
        context.setPostData(post);
        this.setState({ posts: post });
      }
    } else {
      toast.error(res.data.error, {
        id: "toast-error",
        style: {
          maxWidth: 800,
          fontSize: "14px",
          borderRadius: "15px",
        },
      });
    }
    this.setState({ rotating: false });
    return true;
  }

  async handleComment(id, text, commentId, action = "com") {
    if (text.length && text.length < 151) {
      if (action === "com") {
        this.setState({ rotating: "comment" });
      } else {
        this.setState({ rotating: "reply" });
      }

      const res = await axios.get(
        "https://create-comment-reply-t3e66zpola-uc.a.run.app/",
        {
          params: {
            action,
            text,
            comId: commentId,
            feedUid: this.state.uid,
          },
          headers: {
            Idtoken: this.state.idToken,
            Postid: id,
          },
        }
      );
      if (res.data.error === "no error") {
        const post = await getPost(
          this.state.uid,
          "noupdate",
          this.state.paginate,
          this.state.page
        );
        if (post) {
          const context = this.context;
          context.setPostData(post);
          this.setState({ posts: post });
        }
      } else {
        toast.error(res.data.error, {
            id: "toast-error",
            style: {
              maxWidth: 800,
              fontSize: "14px",
              borderRadius: "15px",
            },
        });
      }
      this.setState({ rotating: false });
      return true;
    } else {
      toast.success(
        action === "com"
          ? "Comment text is required and length must less than 150"
          : "Reply text is required and length must less than 150",
        {
            id: "toast-error",
            style: {
              maxWidth: 800,
              fontSize: "14px",
              borderRadius: "15px",
            },
        }
      );
      return false;
    }
  }

  async handleShare() {
    this.setState({ rotating: true });
    const res = await axios.get(
      "https://create-post-t3e66zpola-uc.a.run.app/share",
      {
        params: {
          text: this.state.newpost,
          postId: this.state.selectedPost,
          //  shareLink: `/feed-detail/${this.state.uid}/${this.state.selectedPost}`,
          shareOri: `/feed-detail/${this.state.selectedPost}`,
          privacy: this.state.privacy.type,
          allowCom: this.state.allowCom,
          feedUid: this.state.uid,
        },
        headers: {
          Idtoken: this.state.idToken,
        },
      }
    );
    this.setState({ rotating: false, newpost: "" });
    if (res.data.error === "no error") {
      const post = await getPost(
        this.state.uid,
        "noupdate",
        this.state.paginate,
        this.state.page
      );
      if (post) {
        const context = this.context;
        context.setPostData(post);
        this.setState({
          posts: post,
          create_a_post_show: "",
          newpost: "",
          who_can_see_show: "",
        });
      }
    } else {
      toast.error(res.data.error, {
        id: "toast-error",
        style: {
          maxWidth: 800,
          fontSize: "14px",
          borderRadius: "15px",
        },
      });
    }
    return true;
  }

  async handleLikeCommentReply(action, id, commentId, replyId, postUserId) {
    this.setState({ rotating: true });
    const res = await axios.get(
      "https://like-setter-t3e66zpola-ue.a.run.app/",
      {
        params: {
          action,
          comId: commentId,
          replyId,
          feedUid: postUserId,
        },
        headers: {
          Idtoken: this.state.idToken,
          Postid: id,
        },
      }
    );
    if (res.data.error === "no error") {
      const post = await getPost(
        this.state.uid,
        "noupdate",
        this.state.paginate,
        this.state.page
      );
      if (post) {
        const context = this.context;
        context.setPostData(post);
        this.setState({ posts: post });
      }
    } else {
      toast.error(res.data.error, {
        id: "toast-error",
        style: {
          maxWidth: 800,
          fontSize: "14px",
          borderRadius: "15px",
        },
      });
    }
    this.setState({ rotating: false });
    return true;
  }

  handleDeletePost = async (postId) => {
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
      id: "toast-loading",
      duration: 6000,
      style: {
        borderRadius: "5px",
        background: "#333",
        color: "#fff",
      },
    });
    try {
      const res = await axios.get(
        `https://del-user-feed-t3e66zpola-uc.a.run.app/deleteSinglePost`,
        { headers: { idToken: this.state.idToken }, params: { postId } }
      );
      if (res.data.error === "no error") {
        const post = await getPost(
          this.state.uid,
          "noupdate",
          this.state.paginate,
          this.state.page
        );
        if (post) {
          const context = this.context;
          context.setPostData(post);
          toast.success("Deleted.", {
            id: "toast-success",
            style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
            },
          });
          this.setState({ posts: post });
        }
      } else {
        toast.error(res.data.error, {
            id: "toast-error",
            style: {
              maxWidth: 800,
              fontSize: "14px",
              borderRadius: "15px",
            },
        });
      }
      this.setState({ rotating: false });
      return true;
    } catch (error) {
      console.log("Error in handleDeletePost api =>", error);
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (this.state.loading) return <Loader />;
    return (
      <div className="feed-page">
        <div className="container">
          <div className="feed-box-form">
            <Form>
              <div className="row">
                <div className="col-md-4">
                  <div className="feed-card">
                    <div className="feed-box">
                      <img src={this.state.image} alt="Admin" />
                      <div className="feed-info">
                        <h4>{this.state.nick}</h4>
                        <p>
                          <Link to="/profile">Home</Link>
                        </p>
                        <p className="feed-date">{this.date_start}</p>

                        <button
                          type="button"
                          className="btn-share btn-gold btn-outline start-a-post"
                          onClick={() => this.addPost("newPost")}
                        >
                          Start a post
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <Post
                    posts={this.state.posts}
                    handleLikePost={this.handleLikePost}
                    handleComment={this.handleComment}
                    img={this.state.image}
                    addPost={this.addPost}
                    handleReplyShow={this.handleReplyShow}
                    handleCommentShow={this.handleCommentShow}
                    show={this.state.show}
                    replyShow={this.state.replyShow}
                    handleLikeCommentReply={this.handleLikeCommentReply}
                    uid={this.state.uid}
                    rotating={this.state.rotating}
                    idToken={this.state.idToken}
                    handleDeletePost={this.handleDeletePost}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
        
        <Modal
          show={this.state.create_a_post_show.length !== 0}
          onHide={() => this.closeCreatePost()}
          centered
          className="friends-popup"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <small>
                {this.state.create_a_post_show === "newPost"
                  ? "Create a post"
                  : "Share Post"}
              </small>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="feed-body">
              <Form.Group className="post-form" controlId="post-id">
                <div className="post-box">
                  <img
                    src={this.state.image}
                    alt="Admin"
                    className="rounded-circle"
                  />
                  <small>@{this.state.nick}</small>
                </div>
                <button
                  onClick={() => this.whoCanSee()}
                  className="btn-sm btn-outline-light select-post"
                >
                  <i
                    className={visibilityIcon.get(this.state.privacy.label)}
                    aria-hidden="true"
                  >
                    {" "}
                  </i>
                  {this.state.privacy.label}
                </button>
              </Form.Group>

              <div className="allow-comment">
                <label className="switch">
                  <input
                    type="checkbox"
                    name="allowCom"
                    onChange={() =>
                      this.setState({ allowCom: !this.state.allowCom })
                    }
                    defaultChecked={this.state.allowCom}
                  />
                  <span className="slider round"></span>
                </label>
                {/* <input
                    type="radio"
                    name="allowCom"
                    defaultChecked={this.state.allowCom}
                    onChange={() =>
                      this.setState({ allowCom: !this.state.allowCom })
                    }
                  /> */}
                <label>Allow comment</label>
              </div>

              <div className="form-group">
                <div className="the-count">
                  <span className="current">{this.state.newpost.length}</span>
                  <span className="maximum">/ 250</span>
                </div>
                <textarea
                  className="form-control text-area animated"
                  placeholder="What's on your mind?"
                  onChange={(e) => this.setState({ newpost: e.target.value })}
                  maxLength={250}
                  minLength={50}
                  value={this.state.newpost}
                  count={this.state.newpost}
                ></textarea>
              </div>

              <div className="btn-float">
                <button
                  className="btn-share btn-gold btn-outline start-a-post"
                  type="button"
                  onClick={() => {
                    this.state.create_a_post_show === "newPost"
                      ? this.handleNewPost()
                      : this.handleShare();
                  }}
                  disabled={this.state.rotating}
                >
                  {this.state.rotating ? (
                    <Spinner animation="border" />
                  ) : (
                    "Share"
                  )}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.who_can_see_show}
          onHide={() => this.whoCanSee()}
          centered
          className="friends-popup"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <small>Who can see your post?</small>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="feed-body">
              <div className="col-md-8">
                <Form.Group className="post-form" controlId="post-id">
                  <div className="post-select">
                    <div className="filter-form-posts">
                      <div className="filter-input radio">
                        <input
                          type="radio"
                          value={JSON.stringify({
                            type: "all",
                            label: "Anyone",
                          })}
                          name="SharePost"
                          defaultChecked={this.state.privacy.type === "all"}
                          onChange={this.selectPostType.bind(this)}
                        />
                        <label className="radio-label" id="Basketballtext">
                          <span className="btn-sm select-post">
                            <i
                              className="fa fa-globe fa-fa-design"
                              aria-hidden="true"
                            ></i>
                            Anyone
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="post-select">
                    <div className="filter-form-posts">
                      <div className="filter-input radio">
                        <input
                          type="radio"
                          value={JSON.stringify({
                            type: "ff",
                            label: "Friends and followers",
                          })}
                          name="SharePost"
                          defaultChecked={this.state.privacy.type === "ff"}
                          onChange={this.selectPostType.bind(this)}
                        />
                        <label className="radio-label" id="Basketballtext">
                          <span className="btn-sm select-post">
                            <i
                              className="fa fa-users fa-fa-design"
                              aria-hidden="true"
                            ></i>
                            Friends and followers
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="post-select">
                    <div className="filter-form-games">
                      <div className="filter-input radio">
                        <input
                          type="radio"
                          value={JSON.stringify({
                            type: "friends",
                            label: "Friends",
                          })}
                          name="SharePost"
                          defaultChecked={this.state.privacy.type === "friends"}
                          onChange={this.selectPostType.bind(this)}
                        />
                        <label className="radio-label" id="Basketballtext">
                          <span className="btn-sm select-post">
                            <i
                              className="fa fa-user fa-fa-design"
                              aria-hidden="true"
                            ></i>
                            Friends
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="post-select">
                    <div className="filter-form-games">
                      <div className="filter-input radio">
                        <input
                          type="radio"
                          value={JSON.stringify({
                            type: "followers",
                            label: "Followers",
                          })}
                          name="SharePost"
                          defaultChecked={
                            this.state.privacy.type === "followers"
                          }
                          onChange={(e) => this.selectPostType(e)}
                        />
                        <label className="radio-label" id="Basketballtext">
                          <span className="btn-sm select-post">
                            <i
                              className="fa fa-check-circle fa-fa-design"
                              aria-hidden="true"
                            ></i>
                            Followers
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </div>
              <div className="btn-float">
                <button
                  className="btn btn-dark"
                  onClick={() => this.whoCanSee()}
                >
                  Back
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default FeedMenu;
