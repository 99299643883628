import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import Layout from "../layout/layout";
import "./join.css";
import { ethers } from "ethers";
import Loader from "../loader/loader";
import firebase from "../../firebase";
import { MainContext } from "../../context";
import { useWeb3React } from "@web3-react/core";
import WejeAbi from "../../ABI/WejeAbi.json";
import Join from "./join";
import axios from "axios";
import UrlConfig from "../../utils/ApiConfig";
import { metaMask } from '../cryptoWallet/metamask';
import CryptoNOT from "../cryptoNOT/index";
import toast from "react-hot-toast";
import { convertToObject } from "../../utils";

let viewMore = "no";
let interval1 = null;
let interval2= null;
let interval3 = null;

const JoinTable = () => {
  let { game } = useParams();
  const { token, firebaseUser: user, baseStats } = useContext(MainContext);
  const { account, provider } = useWeb3React();
  const history = useHistory();
  const [tableList, setTableList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [contract, setContract] = useState();
  const [balance, setBalance] = useState('');
  const [balanceInUSD, setBalanceInUSD] = useState(0.0);
  const [message, setmessage] = useState('');
  const [walletConnected, setwalletConnected] = useState(localStorage.getItem("isWalletConnected"));
  const [selectedGame, setSelectedGame] = useState(game);
  const [gameName, setgameName] = useState('');
  const [showTable, setshowTable] = useState(false);
  const [adminPhoto, setadminPhoto] = useState(''); 
  const [tablelink, setTablelink] = useState(''); 
  const [gameStatus, setgameStatus] = useState(''); 
  const [buyin, setBuyin] = useState(''); 
  const [minBet, setminBet] = useState(''); 
  const [media, setMedia] = useState(''); 
  const [playersLength, setplayersLength] = useState(''); 

  useEffect(()=>{
    let cont;
    if(account && provider){
      const signer = provider.getSigner();
      cont = new ethers.Contract(process.env.REACT_APP_CONTRACT_ADDRESS, WejeAbi, signer);
      setContract(cont);
      interval1 =setTimeout(() => {
        fetchTablelist(cont);
      }, 3000);
      cont.on("GameCreated", () => {
        interval2 =setTimeout(() => {
          fetchTablelist(cont);
        },3000)
      });
      cont.on("PlayerLeft", () => {
        interval3 = setTimeout(() => {
          fetchTablelist(cont);
        },3000)
      });
    }
    return () => {
      if(cont){
        cont.off("GameCreated");
        cont.off("PlayerLeft");
      }
      if(interval1){
        clearInterval(interval1);
      }
      if(interval2){
        clearInterval(interval2);
      }
      if(interval3){
        clearInterval(interval3);
      }
    }
  },[account, provider])


  useEffect(() => {
    const getMaticUsdPrice = async(matic) => {
      const res = await axios.get(UrlConfig.maticUsdPriceUrl);
      if(res.data){
        const curentPrice = res.data.market_data.current_price.usd
        setBalanceInUSD(curentPrice * matic);
      }
    }
    const getBalance = async() => {
    if(account){
      const value = await provider?.getBalance(account);
        if(value)
        setBalance(Number(ethers.utils.formatEther(value)).toFixed(5));
        getMaticUsdPrice(Number(ethers.utils.formatEther(value)));
        setwalletConnected(true);
        setmessage("");
    } else {
        setwalletConnected(false);
        setmessage("Crypto wallet is not connected");
        handleConnectWallet();
    }
  }
  getBalance()
}, [account, provider])

  const fetchTablelist = async(cont) => {
    try {
      setSelectedGame(game);
    if(game === 'poker') {
        setgameName('Cash Play');
    }
    if(!cont && contract){
      return;
    }
    let contr = contract || cont;
    setLoading(true);
    const games = await contr.getAllGames();
    let gameData = [];
    // Convert the response to an array of Game objects
 games.forEach(game => { 
  if(game[4].length === 10 || !game[8]){
    return;
  }
  gameData.push(convertToObject(
      game[0],
      game[1],
      game[2],
      game[3], // BigNumber, can be directly accessed as hex
      game[4],
      game[5],
      game[6], // BigNumber, can be directly accessed as hex
      game[7],
      game[8],
      game[9],
      game[10] // BigNumber, can be directly accessed as hex
  ));
});


    // gameType, isPublic, minBet, buyIn, autoHandStart
    const filteredGames = gameData.filter(game => game.gameType === "pokerCP_Tables" && game.isPublic);
   setTableList(filteredGames);
    setLoading(false);
  } catch(e) {
    setLoading(false);
   }
  }

  
  const handleShowTable = async (table) => { 
    setTablelink(`/poker/index.html?tableid=${table.gameId}&gameCollection=${table.gameType}`);
    setadminPhoto(table.players.find(el => el.playerId === table.admin).photoUrl);
    setshowTable(!showTable);
    setgameStatus("lobby");
    setBuyin(table.minBet * 2);
    setminBet(table.minBet);
    setMedia(table.media);
    setplayersLength(table.players.length);
  }

  async function handleConnectWallet () {
    if ( window.ethereum ) {
    const isMetaMask = window.ethereum.isMetaMask;
    const isCoinbase = window.ethereum.isCoinbaseWallet;
    const isTrust = window.ethereum.isTrust;
    const localProvider = localStorage.getItem("provider");
    if ((isMetaMask && localProvider === "MetaMask") ||
        (isCoinbase && localProvider === "Coinbase") || 
        (isTrust && localProvider === "TrustWallet")) 
        {
        try {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const compare = accounts[0].toString().toLowerCase();
            const compare2 = localStorage.getItem('walletid').toString().toLowerCase();
            if (compare === compare2) {
                localStorage.setItem('isWalletConnected', true);
                metaMask.activate({
                chainId: 137, // Polygon Mainnet 137
                chainName: "Polygon Mainnet",
                nativeCurrency: {
                    name: 'Matic',
                    symbol: 'MATIC',
                    decimals: 18,
                },
                  rpcUrls: ["https://polygon-rpc.com/"], // TestNet - "https://rpc-amoy.polygon.technology/"
                  blockExplorerUrls: ["https://polygonscan.com/"] 
                });
                localStorage.setItem('isWalletConnected', true);
                toast.success("Connected!", {
                    duration: 4000,
                    id: "toast-success",
                    style: {
                      maxWidth: 800,
                      fontSize: "14px",
                      borderRadius: "15px",
                    },
                });
            } else {
                toast.error("The wallet ID associated incorrect, please connect correct Ethereum wallet provider", {
                    duration: 4000,
                    id: "toast-error",
                    style: {
                      maxWidth: 800,
                      fontSize: "14px",
                      borderRadius: "15px",
                    },
                  });
               }
            } catch (error) {
              console.error("Error connecting:", error);
            }
    } else {
        toast.error("Please activate correct Ethereum wallet", {
            duration: 4000,
            id: "toast-error",
            style: {
              maxWidth: 800,
              fontSize: "14px",
              borderRadius: "15px",
            },
          });
    }   
} else {
    localStorage.setItem('isWalletConnected', false);
    toast.error("No Ethereum wallet provider detected", {
        duration: 4000,
        id: "toast-error",
        style: {
          maxWidth: 800,
          fontSize: "14px",
          borderRadius: "15px",
        },
      });
  }
}

  if (loading) {
    return <Loader />;
  } else if (!walletConnected) {
    return <CryptoNOT />;
  } else
    return (
      <Layout>
         {/* <button onClick={handleSend}>Send Ether</button> */}
        <div className="bet-las-vegas">
          <div className="container">
            <div className="row">
             
              <div className="col-md-8 m-auto">
                <div className="weje-join-content">
                  <div className="select-game">
                    <p>Select a game</p>
                    <select
                      name="singleSelect"
                      onChange={(e) => { history.push(`/jointable/${e.target.value}`)}}
                      value={selectedGame}>
                      <option value="Poker">Poker</option>
                      {/* 
                      <option value="blackjack">Blackjack</option>
                      <option value="backgammon">Backgammon</option>
					            <option value="ludo">Ludo</option>
                      */}
                    </select>
                  </div>

                  <div className="online-game-container">
                    <div className="row">
                      <div className="col-lg-12">
                        <h6>Active games</h6>
                      </div>
                      {tableList && tableList.length ? (
                        tableList.map((table, i) => (
                          <div className="col-lg-4" key={`item-${i}`}>
                            <div className="our-team-main" onClick={() => handleShowTable(table)}>
                              <div className="team-front-weje" >
                                {table.media !== "no-media" ? (
                                  <div className="video-table">
                                    <i
                                      className={
                                        table.media === "video"
                                          ? "fa fa-video-camera"
                                          : "fa fa-microphone"
                                      }
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <img
                                  src={table.players.find(el => el.playerId === table.admin).photoUrl}
                                  alt=""
                                  className="img-fluid"
                                />
                                <h3 className="table-title">{gameName}</h3>
                                <small className="admin-name">@{table.players.find(el => el.playerId === table.admin).name}</small>
                                {table.players.map((player, j) => (
                                  <div
                                    key={`item-${j}`}>
                                    {/* <img
                                      src={player.photoUrl}
                                      alt=""
                                      className="img-fluid"
                                    /> */}
                                  </div>
                                ))}
                              </div>
                              <div className="team-back-weje">
                                {table?.minBet ? (
                                  <p>
                                    <small>Small blind:</small> 
                                    <span className="val-table">{table.minBet}</span>
                                    <br></br>
                                  </p>
                                ) : (
                                  ""
                                )}
								                {table?.minBet ? (
                                  <p>
                                    <small>BuyIn:</small> 
                                    <span className="val-table">{table.buyIn}</span>
                                    <br></br>
                                  </p>
                                ) : (
                                  ""
                                )}
                                <p>
                                <small>Players:</small> 
                                <span className="val-table">{table.players.length}</span>
                                <br></br>
                                </p>
                                {table?.watchers?.length ? (
                                  <p>
                                    <small>Watchers:</small> 
                                    <span className="val-table">{table.watchers.length}</span>
                                  </p>
                                ) : (
                                  ""
                                )}
                              
                              </div>
                              <div className="btn-show-div">
                                <Button
                                    onClick={() => handleShowTable(table)}
                                    className="btn-gold btn btn-primary btn-show">
                                Join
                                </Button>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="col-lg-12">
                          <div className="no-data-found">
                            <p>No table has been found</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
				  {}
                  {tableList.length >= 9 ? (
                    <div className="pagination-view">
						          <Button className="btn-gold" onClick={() => fetchTablelist(game, 'yes', token)}>View More</Button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <>
                {showTable ? (
                    <Join
                    minBet={minBet}
                    buyin={buyin}
                    status={gameStatus}
                    link={tablelink}
                    photo={adminPhoto}
                    game={selectedGame}
                    media={media}
                    length={playersLength}
                    contract={contract}
                    walletConnected={walletConnected}
                    setshowTable={setshowTable}
                    />
                ) : (
                null
                )}
            </>
          </div>
        </div>
      </Layout>
    );
};

export default JoinTable;
