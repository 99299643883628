import React from "react";
import Lottie from "react-lottie";
import handImage from "../../assets/animation/fist-bump.json";
import "./popup.css";

const coinanim = {
  loop: true,
  autoplay: true,
  animationData: handImage,
};

const FollowEachPopup = ({ userName }) => {
  return (
    <div className={`follow-each-popup`}>
      <div className="follow-each-grid">
        <div className="delete-lottie">
          <Lottie options={coinanim} width={300} height={300} />
        </div>
        <h2>Congratulations!</h2>
        <p>
          You and <span>{userName}</span> are now following each other.
        </p>
      </div>
    </div>
  );
};
export default FollowEachPopup;
