import React, { useState, useEffect, useRef } from "react";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import UrlConfig from "../../utils/ApiConfig";
import { isMobile } from "react-device-detect";
import './filter.css';

function getFutureDate(days) {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date.toISOString().split("T")[0];
}

const BetFilter = ({ title, selectionUpdate, leagueId, className="football-page", isDrawable=true, endDays, onScroll }) => {
  const [openGames, setOpenGames] = useState([]);
  const [selectedGames, setSelectedGames] = useState([]);
  const [leagueError, setLeagueError] = useState("");
  const dateStart = new Date().toISOString().split("T")[0];
  const [dateEnd] = useState(getFutureDate(endDays));
  const [loading, setLoading] = useState(true);
  const filterMatchesRef = useRef(null); // Ref for filter-matches

  const getData = async (leagueId, setStateCallback, setErrorCallback) => {
    try {
      const options = {
        method: "GET",
        url: UrlConfig.openMatchSearch,
        headers: {
          targetUrl: `https://sportscore1.p.rapidapi.com/events/search?league_id=${leagueId}&status=notstarted&date_start=${dateStart}&date_end=${dateEnd}`,
        },
      };
      const response = await axios.request(options);
      const data = response.data.data;
      data.sort((a, b) => new Date(a.start_at) - new Date(b.start_at));
      setStateCallback(data);
      if (!Object.keys(data).length) {
        setErrorCallback("No games available for betting");
      } else {
        setErrorCallback(""); // Clear error if data is available
      }
    } catch (error) {
      console.error(error);
      setErrorCallback("Error fetching data");
    } finally {
      setLoading(false); // Set loading to false after data is fetched or error
    }
  };

  useEffect(() => {
    setLoading(true); // Set loading to true before fetching data
    getData(leagueId, setOpenGames, setLeagueError);
  }, [dateStart, dateEnd]);

   // Adding the onScroll event listener
   useEffect(() => {
    const handleScroll = () => {
      console.log("yes");
      onScroll();
    };

    const filterMatchesDiv = filterMatchesRef.current;
    if (filterMatchesDiv && isMobile) {
      filterMatchesDiv.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (filterMatchesDiv  && isMobile) {
        filterMatchesDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);


  const resetSelection = (homeElement, tieElement, awayElement) => {
    homeElement.classList.remove("selected");
    awayElement.classList.remove("selected");
    if(isDrawable && tieElement){
    tieElement.classList.remove("selected");
    }
  };

  const select = (event, game, choice) => {
    const { id: gameId } = game;
    const homeElement = document.querySelector(`.game-${gameId}home`);
    const awayElement = document.querySelector(`.game-${gameId}away`);
    let tieElement;
    if(isDrawable){
        tieElement = document.querySelector(`.game-${gameId}tie`);
    }
    
    if (event.target.classList.contains("selected")) {
      event.target.classList.remove("selected");
      resetSelection(homeElement, tieElement, awayElement);
    } else {
      resetSelection(homeElement, tieElement, awayElement);
      event.target.classList.add("selected");
    }
    const index = selectedGames.findIndex((game) => game.id === gameId);
    const updatedMyGames = [...selectedGames];
    if (index !== -1) {
      if (updatedMyGames[index].choice === choice) {
        updatedMyGames.splice(index, 1);
      } else {
        updatedMyGames[index].choice = choice;
      }
    } else {
      let startDate = game.start_at.split(" ").join("T") + ".000Z";
      // endDate is 3hours ahead
      let endDate = new Date(new Date(startDate).setHours(new Date(startDate).getHours() + 3)).toISOString();
      updatedMyGames.push({
        id: gameId,
        choice,
        isDrawable,
        begin: startDate,
        end: endDate,
        timestamp: Date.now(),
        home: game?.home_team.name || "",
        away: game?.away_team.name || "",
        homeLogo: game?.home_team.logo || "",
        awayLogo: game?.away_team.logo || "",
        homeId: game?.home_team.id || "",
        awayId: game?.away_team.id || "",
      });
    }
    setSelectedGames(updatedMyGames);
    selectionUpdate(updatedMyGames);
  };

  const renderGame = (game) => (
    <li key={game.id} className={`game-${game.id}`}>
      <div className="filter-game" id={game.slug}>
        <div className="game-box">
          <img className="img-raised1" src={game.home_team.logo} alt="home_team" />
          <span
            id={game.home_team.slug}
            onClick={(event) =>select(event, game, "home")}
            role="button"
            tabIndex="1"
            className={`game-${game.id}home`}
          >
            {game.home_team.name}
          </span>
          <small className="home_score">Home team</small>
        </div>
        <div className="game-vs">
            {isDrawable ? <span
            id={game.id}
            onClick={(event) => select(event, game, "tie")}
            role="button"
            tabIndex="0"
            className={`game-${game.id}tie`}
          >
            X
          </span>: <span>vs</span>}
          
        </div>
        <div className="game-box">
          <img className="img-raised1" src={game.away_team.logo} alt="away_team" />
          <span
            id={game.away_team.slug}
            onClick={(event) =>select(event, game, "away")}
            role="button"
            tabIndex="2"
            className={`game-${game.id}away`}
          >
            {game.away_team.name}
          </span>
          <small className="away_score">Away team</small>
        </div>
        <div className="game-data">
          <small className="time">
            {new Date(game.start_at.split(" ").join("T")+".000Z").toLocaleString()} <b>{game.status}</b>
          </small>
        </div>
      </div>
    </li>
  );

  return (
    <div className={className}>
      <div className="filter-card">
        <div className="row">
          <div className="col-md-12">
            <div className="filter-matches custom-scrollbar filter-matches" ref={filterMatchesRef} style={{ maxHeight: "600px", overflowY: "scroll" }}>
              <p>{title}</p>
              {loading ? (
                <Spinner animation="border" className="sportspinner" />
              ) : (
                <>
                  <small>{leagueError}</small>
                  <ul>{openGames.map(renderGame)}</ul>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BetFilter;