import React, { useContext, useEffect, useState } from 'react';
import { Form, Modal, Button, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import Layout from "../layout/layout";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast from "react-hot-toast";
import CryptoNOT from "../cryptoNOT/index";
import { Link } from "react-router-dom";
import firebase from "../../firebase";
import { browserName, getUA} from "react-device-detect";
import axios from "axios";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import confirm from "../../assets/animation/confirm.json";
import blockc from "../../assets/animation/block.json";
import Lottie from "react-lottie";
import info from "../../assets/images/icons/info_30.png";
// import coin from "../../assets/images/header/coin.png";
import walletIcon from "../../assets/images/popups/wallet-logo.png";
import "./table.css";
import { MainContext } from '../../context';
import WejeAbi from "../../ABI/WejeAbi.json";
import LudoImage from "../../assets/animation/Ludo.png";
import UrlConfig from "../../utils/ApiConfig";
import MinimumBet from "./minbet";
import { v4 } from "uuid";

let device = browserName + "_" + getUA.replace(/[^A-Z0-9]/gi, "_")

const confirmLottie = {
  loop: true,
  autoplay: true,
  animationData: confirm,
};

const blockLottie = {
  loop: true,
  autoplay: true,
  animationData: blockc,
};

const timeoutArray = [
    { value: "15", label: "15 second" },
    { value: "20", label: "20 second" },
];

const gameTime = [{value: 5, label: "5 minutes"}]

const playersArray = [
  { value: "Rizwan", label: "Rizwan", id: "z2DOjU0mSLWWW2FNkLN5dZ9PvtM2" },
  { value: "Adi", label: "Adi", id: "jLagV11CriYqqgnt6Oi845uSrEE3" },
];

function LudoCreateTable() {
  const { token } = useContext(MainContext);
  const [contract, setContract] = useState();
  const { firebaseUser: user } = useContext(MainContext);
    const [playerSelected, setplayerSelected] = useState([]);
    const [walletConnected, setwalletConnected] = useState(localStorage.getItem("isWalletConnected"));
    const [balance, setBalance] = useState('');
    const [balanceInUSD, setBalanceInUSD] = useState(0.0);
    const [wallet_min, setwallet_min] = useState(1);
    const [nickName, setnickName] = useState("");
    const [timeoutSelected, settimeoutSelected] = useState({ value: "15", label: "15 second" });
    const [pickerSchedule, setpickerSchedule] = useState(false);
    const [selectDate, setselectDate] = useState('no');
    const [selectedTime, setselectedTime] = useState(null);
    const [deviceId] = useState(localStorage.getItem("deviceId"));
    const [i_sound] = useState(localStorage.getItem("i_sound"));
    const [idToken, setidToken] = useState(localStorage.getItem("idtoken"));
    const [uid, setuid] = useState('');
    const [gameType, setgameType] = useState('MTimer');
    const gametypes = [
      { value: "LudoClassic", label: "Classic", desc: "The first winner will receive 70% of the winning amount, the second winner will receive 20%, and the third winner will receive 10%" },
      { value: "LudoTimer", label: "Timer", desc: "The game will end after a specified time period, and the winner will be the player with the highest score" },
      { value: "LudoMoveableTimer", label: "Moveable Timer", desc: "All pawns will be ready for movement, and the winner will be the highest scorer when the game time expires" },
    ];
    const [gameDesc] = useState('All pawns will be ready to move, Winner will be the highest scorer with in specifid game period');
    const [gameName, setgameName] = useState('');
    const [multiplayer, setmultiplayer] = useState(true);
    const [public_game, setpublic_game] = useState(true);
    const [is_Audio, setis_Audio] = useState(false);
    const [is_Video, setis_Video] = useState(false);
    const [allow_round, setallow_round] = useState(true);
    const [media, setmedia] = useState("no-media");
    const [video_hide, setvideo_hide] = useState(false);
    const [audio_hide, setaudio_hide] = useState(false);
    const [loading, setloading] = useState(false);
    const [message, setmessage] = useState('');
    const [confirmationText, setconfirmationText] = useState('');
    const [lobbyLink, setlobbyLink] = useState('');
    const [textlobbyLink, settextlobbyLink] = useState('');
    const friends = [];
    const { account, provider } = useWeb3React();
    const [smallBlind, setSmallBlind] = useState(0.01);
    const [buyIn, setbuyIn] = useState(0.1);
    const [confirmationShow, setconfirmationShow] = useState(false);

    const createGame = async (gameId) => {
      let tx;
      try {
        if (contract) {
          const depositAmountInMatic = buyIn;
          const valueToSend = ethers.utils.parseUnits(depositAmountInMatic.toString(), 18);
          const minBet = ethers.utils.parseUnits((smallBlind).toString(), 18);
          tx = await contract.createGame(gameId, "LudoMoveableTimer", minBet, [user.uid, valueToSend, account, user.photoURL, user.displayName], parseInt(timeoutSelected.value), media, public_game, allow_round, {
            value: valueToSend,
            gasLimit: 800000,
            gasPrice: ethers.utils.parseUnits('30', 'gwei'),
            nonce: await provider.getTransactionCount(account),
          });
          console.log("txxxx", tx);
          setconfirmationText('Waiting For Block Confirmation');
          const receipt = await tx.wait();
          console.log("rrrr", receipt);
         return receipt;
        }
      } catch (e) {
        if (e.message.includes("execution reverted")) {
          const revertReason = await contract.provider.getTransactionReceipt(tx.hash)
            .then((receipt) => receipt ? receipt.reason : "No revert reason");
          console.error("Revert Reason:", revertReason);
          toast.error("Revert Reason: ", revertReason, {
            duration: 4000,
            id: "toast-error",
            style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
            },
            });
          setconfirmationShow(true);
          setloading(false);
          setconfirmationText('Waiting For Block Confirmation');
          return false;
        } else {
          if (e.message.includes("insufficient funds")) {
            toast.error("Not enough balance to create a game", {
              duration: 4000,
              id: "toast-error",
              style: {
                  maxWidth: 800,
                  fontSize: "14px",
                  borderRadius: "15px",
                },
              }); 
            }
            console.error('Detailed Error Message: ', e);
            console.error('Error in joinGame: ', e);
          setconfirmationShow(true);
          setloading(false);
          return false;
        }
      }
    };

  const handleSubmit = async(e) => {
    let link;
    try{
    e.preventDefault();
    const isValid = validate();
    if (isValid) {
          setloading(true);
        let gameId = v4();
        gameId = gameId.split("-").join("");
        setconfirmationText('Please Confirm Transaction');
        const scookie = localStorage.getItem("scookie") || localStorage.getItem("idtoken");
        console.log("scookie", scookie);
      const res = await axios.post(UrlConfig.tableCreateAPI + 'error', { 
        service: "createTableApi",
        payload: {
          game: gameType.value,
          gameName,
          tid: gameId,
          betMin: smallBlind,
          ballance: parseFloat(balance),
          coinName: 'Matic',
          buyIn: parseFloat(buyIn),
          smallBlind: parseFloat(smallBlind),
          rTimeout: parseInt(timeoutSelected.value),
          public: public_game,
          allowWat: false,
          adminStart: allow_round,
          invPlayers: playerSelected.map(el => el.id),
          media: media,
          gameTime: selectedTime,
          MaticUSD: parseFloat(balanceInUSD),
          },
        targetHeaders: {
          device: deviceId || device,
        }
    }, {
      headers:{
        scookie: sessionStorage.getItem("scookie") || localStorage.getItem("scookie"),
        Authorization: "Bearer " +idToken ||  localStorage.getItem("idtoken"),
      },
      withCredentials: true,
      credentials: 'include',
      });
      if(res.data.error === "no error"){
       link = res.data.link;
        const tx = await createGame(gameId);
        if(!tx){
          setloading(false);
          console.log("not able to create game");
          await axios.get(UrlConfig.resetSessionAPI, {
            headers:{
              uid: user.uid
            }
          })
          return;
        }
        window.location.href = res.data.link;
      return;
      } else if (res.data.error === "User inGame"){
        link = res.data.link;
        let tableId = link.split("?tableid=");
        gameId = tableId[1].split("&")[0];
        const tx = await createGame(gameId);
        if(!tx){
          setloading(false);
          console.log("not able to create game");
          await axios.get(UrlConfig.resetSessionAPI, {
            headers:{
              uid: user.uid
            }
          })
          return;
        }
        window.location.href = res.data.link;
      return;
      }
    } else {
        console.log("Validation error");
    }
  }catch(e){
    
    console.log("not able to create game",e);
    if(link){
      await axios.get(UrlConfig.resetSessionAPI, {
        headers:{
          uid: user.uid
        }
      })
    }
    const data = e.data;
    console.log("error data",data);
    }
  };

  function validate() {
    let error = "";
    if (balance === "0.00000") {
        error = "Not enough MATIC tokens";
        console.log("MATIC tokens on Polygon network is not enough");
    }
    if(!public_game && playerSelected.length === 0){
      error = "Please invite Player for private table";
    }
    if (error) {
        setmessage(error);
      return false;
    } else {
      return true;
    }
  };

  const handleChange = (event) => {
    if (event.value === 'LudoClassic') {
        setgameType('Classic');
        setmultiplayer(true);
        setpublic_game(true);
    } if (event.value === 'LudoTimer') {
        setgameType('Timer');
        setmultiplayer(true);
        setpublic_game(false);
    } if (event.value === 'LudoMoveableTimer') {
        setgameType('MTimer');
        setmultiplayer(false);
        setpublic_game(false);
    }
  };

  function hendleplayerSelect(event) {
    setplayerSelected(event);
  }

  function hendleTimeSelect(event) {
    settimeoutSelected(event)
  }

  function showSchedule() {
    setpickerSchedule(true);
    setselectDate(new Date());
  }

  function hideSchedule() {
    setpickerSchedule(false);
    setselectDate("no");
  }

  function onChangeDate(data) {
    console.log(data);
    setselectDate(data);
  }

  function toggleAudio(e) {
    if (e.target.checked) {
        setis_Audio(e.target.checked);
        setmedia("audio");
        setvideo_hide(true);
        toast.success (
            <div className="wallet-image">
              Game with Voice
            </div>,
            {
              id: "toast-info",
              duration: 4000,
              style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
                },
            }
          );
    } else {
        setmedia("no-media");
        setvideo_hide(false);
      } 
  };

  function toggleVideo(e) {
    if (e.target.checked) {
        setis_Video(e.target.checked);
        setmedia("video");
        setaudio_hide(true);
        toast.success (
            <div className="wallet-image">
              Game with Video
            </div>,
            {
              id: "toast-info",
              duration: 4000,
              style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
                },
            }
          );
    } else {
        setmedia("no-media");
        setaudio_hide(false);
      }
  };

  function togglePublic(e) {
    setpublic_game(e.target.checked);
  };

  function toggleRound (e) {
    setallow_round(e.target.checked);
  };

  function updatesWalletCoins(event) {
    setwallet_min(parseFloat(event.target.value));
  }


  const confirmationClose = () => {
    setconfirmationShow(!confirmationShow);
  };


  useEffect(() => {
    const getMaticUsdPrice = async(matic) => {
      const res = await axios.get(UrlConfig.maticUsdPriceUrl);
      if(res.data){
        const curentPrice = res.data.market_data.current_price.usd
        setBalanceInUSD(curentPrice * matic);
      }
    }
    const getBalance = async() => {
    if(account){
      const value = await provider?.getBalance(account);
        if(value)
        setBalance(Number(ethers.utils.formatEther(value)).toFixed(5));
        getMaticUsdPrice(Number(ethers.utils.formatEther(value)));
        setwalletConnected(true);
        setmessage("");
    } else {
        setwalletConnected(false);
        setmessage("Crypto wallet is not connected");
    }
  }
  getBalance()
}, [account, provider])

useEffect(() => {
    const checkIsLoggedIn = async () => {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          const us = firebase.auth().currentUser;
          const token = await firebase.auth().currentUser.getIdToken(true);
          setuid(us.uid);
          setidToken(token);
          setnickName(us.displayName);
        }
      });
    };
    if (localStorage.getItem("xtkn") && localStorage.getItem("deviceId"))
      checkIsLoggedIn();
  }, []);

useEffect(()=>{
  if(account && provider){
    const signer = provider.getSigner();
    let cont = new ethers.Contract(process.env.REACT_APP_CONTRACT_ADDRESS, WejeAbi, signer);
    setContract(cont);
  }
},[account, provider])

  const customStyles = {
    option: (provided) => ({
      ...provided,
      borderBottom: "1px solid #2d2d32",
      color: "#ddd",
      backgroundColor: "#191b25",
    }),
    control: () => ({
      border: "1px solid #51525f",
      borderRadius: ".25rem",
      display: "flex",
      padding: "2px 10px",
      backgroundColor: "transparent",
      color: "#fff",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ":hover": {
        backgroundColor: "#8461dc",
        color: "#fff",
      },
    }),
  };


return (
	<>
    {!walletConnected ? (
		<CryptoNOT />
	) : (
<Layout>
<div className="bet-weje">
    <div className="container">
        <div className="row">
            <div className="col-md-8 m-auto">
                <div className="bet-weje-content">

                    <div className="tooltip-text">
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip
                              id={`tooltip-bottom-ludo`}
                              className="ludo-tooltip" >
                              <div className="ludo-table-description">
                                <h6>{gameType}</h6>
                                <p>{gameDesc}</p>
                              </div>
                            </Tooltip>
                          }>
                          <h2>
                            Ludo{" "}
                            <small>
                              {gameType} <img alt="info" src={info} className='info-logo'/>
                            </small>
                          </h2>
                        </OverlayTrigger>
                      </div>
                        <img
                          src={LudoImage}
                          alt="Admin"
                          className="ludo-game"
                        />

                        <Form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-5">
                            <Form.Label>Select a Game</Form.Label>
                              <Select
                                name="game"
                                options={gametypes}
                                styles={customStyles}
                                onChange={handleChange}
                                defaultValue={{
                                  value: "LudoMoveableTimer",
                                  label: "Moveable Timer",
                                }}
                              />
                              </div>

                            <div className="col-md-5">
                                <Form.Group controlId="formBasicFirst">
                                    <Form.Label>Round Timeout</Form.Label>
                                    <Select
                                        name="player"
                                        options={timeoutArray}
                                        styles={customStyles}
                                        onChange={hendleTimeSelect}
                                        defaultValue={{ value: "15", label: "15 second" }}
                                    />
                                </Form.Group>
                            </div>

                            <div className="col-md-5">
                                <Form.Group controlId="formBasicFirst">
                                <Form.Label>Select players</Form.Label>
                                {multiplayer ? (
                                    <Select
                                    id="SelectPlayer"
                                    name="player"
                                    options={playersArray}
                                    styles={customStyles}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    onChange={hendleplayerSelect}
                                    />
                                ) : null}
                                {!multiplayer ? (
                                    <Select
                                    id="SelectPlayer"
                                    name="player"
                                    options={playersArray}
                                    styles={customStyles}
                                    closeMenuOnSelect={true}
                                    onChange={hendleplayerSelect}
                                    />
                                ) : null}
                                </Form.Group>
                            </div>

                              <>
                                  {gameType !== "Classic" ? (
                                <div className="col-sm-5">
                                  <Form.Group
                                    controlId="formBasicFirst"
                                    className="coin-input" >
                                    <Form.Label>Game Time</Form.Label>
                                    <Select
                                      name="gameTime"
                                      options={[
                                        { value: 5, label: "5 minutes" },
                                        { value: 10, label: "10 minutes" },
                                        { value: 15, label: "15 minutes" },
                                      ]}
                                      styles={customStyles}
                                      onChange={hendleTimeSelect}
                                      defaultValue={gameTime}
                                    />
                                  </Form.Group>
                                </div>
                              ) : (
                                ""
                              )}
                            </>

                            <div className="row col-md-12">
                                <div className="col-sm-5">
                                    <Form.Group
                                    controlId="formBasicFirst"
                                    className="coin-input">
                                    <Form.Label>
                                        <b>Matic</b>
                                    </Form.Label>
                                    <div className="tooltipHover">
                                        <img src={walletIcon} alt="info" className="iconInfo" />
                                        <small className="tooltiptext matic-text">
                                          Matic Balance
                                        </small>
                                    </div>
                                    <div className="your-wallet">
                                        <img src={walletIcon} alt="info" className="PolygonCoins" />
                                        <p className="your-wallet-p">
                                            {balance}
                                        </p>
                                    </div>
                                    </Form.Group>
                                </div>
                                
                               
                            </div>

                            <div className="col-md-6">
                                <MinimumBet 
                                    value={wallet_min}
                                    onChange={updatesWalletCoins}
                                    onSelect={updatesWalletCoins}
                                    smallBlind={smallBlind}
                                    buyIn={buyIn}
                                    setSmallBlind={setSmallBlind}
                                    setbuyIn={setbuyIn}
                                />
                            </div>
                            
                            {pickerSchedule ? (
                                <>
                                <div className="col-md-6">
                                    <DatePicker
                                    selected={selectDate}
                                    onChange={onChangeDate}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Form.Control
                                    type="time"
                                    placeholder="Exact time"
                                    name="time"
                                    autoComplete="off"
                                    required
                                    onChange={(e) => {
                                        console.log("sss =>", e.target.value);
                                        setselectedTime(e.target.value);
                                    }}
                                    />
                                </div>
                                </>
                            ) : null}

                            <div className="col-md-6">
                                <div className="invite-radio">
                                {!audio_hide ? (
                                    <div className="filter-input checkbox">
                                        <input
                                        type="checkbox"
                                        id="video"
                                        name="media"
                                        onChange={toggleAudio} />
                                        <div className="tooltipHover">
                                        <img
                                            src={info}
                                            alt="info"
                                            className="iconInfo" />
                                        <small className="tooltiptext">
                                            Live voice interaction{" "}
                                        </small>
                                        </div>
                                        <label className="radio-label">&nbsp;&nbsp;Audio&nbsp;&nbsp;</label>
                                        <i
                                        style={{ color: "#8461dc" }}
                                        className="fa fa-headphones came"
                                        aria-hidden="true"
                                        ></i>
                                    </div>
                                    ) : null}

                                    {!video_hide ? (
                                    <div className="filter-input checkbox">
                                        <input
                                        type="checkbox"
                                        id="video"
                                        name="media"
                                        onChange={toggleVideo} />
                                        <div className="tooltipHover">
                                        <img
                                            src={info}
                                            alt="info"
                                            className="iconInfo" />
                                        <small className="tooltiptext">
                                            Live video chat{" "}
                                        </small>
                                        </div>
                                        <label className="radio-label">&nbsp;&nbsp; Video &nbsp;</label>
                                        <i
                                        style={{ color: "#8461dc" }}
                                        className="fa fa-video-camera came"
                                        aria-hidden="true"
                                        ></i>
                                    </div>
                                    ) : null}

                                    <>
                                    <div className="filter-input checkbox">
                                        <input
                                        type="checkbox"
                                        id="Public"
                                        name="public"
                                        defaultChecked={public_game}
                                        onChange={togglePublic}
                                        />
                                        <div className="tooltipHover">
                                        <img
                                            src={info}
                                            alt="info"
                                            className="iconInfo"
                                        />
                                        <small className="tooltiptext">
                                            Anyone can join the game
                                        </small>
                                        </div>
                                        <label className="radio-label">
                                          &nbsp;&nbsp;Public game
                                        </label>
                                    </div>
                                    </>

                                    <>
                                    <div className="filter-input checkbox">
                                        <input
                                            type="checkbox"
                                            id="round"
                                            name="watcher"
                                            defaultChecked={allow_round}
                                            onChange={toggleRound}
                                        />
                                        <div className="tooltipHover">
                                            <img
                                            src={info}
                                            alt="info"
                                            className="iconInfo" />
                                            <small className="tooltiptext">
                                                Round start automatically
                                            </small>
                                        </div>
                                        <label className="radio-label">
                                            &nbsp;&nbsp;Automatic round
                                        </label>
                                    </div>
                                    </>
                  
                                    <div className="col-md-12">
                                      
                                        {loading ? (

                                            <>
                                                <Modal
                                                    show={true}
                                                    onHide={() => confirmationClose()}
                                                    centered
                                                    className="friends-popup pending-popup">
                                                    <Modal.Header >
                                                      <Modal.Title>
                                                      <Spinner animation="border" />
                                                        <small className='title-pending'>
                                                           {confirmationText}
                                                        </small>
                                                      </Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className="feed-body">
                                                        { confirmationText === "Please Confirm Transaction" ? (
                                                          <Lottie options={confirmLottie} width={250} height={250} />
                                                        ) : <Lottie options={blockLottie} width={300} height={250} /> }
                                                        </div>
                                                    </Modal.Body>
                                                </Modal>
                                            </>

                                        ) : null}

                                        <div className="errorBoxMessage">
                                            <p className="Error">{message}</p>
                                            <a
                                                style={{ color: "#8461dc" }}
                                                href={lobbyLink} >
                                                {textlobbyLink}
                                            </a>
                                        </div>

                                        <div className="login-button">
                                        <Link to={"/profile"}>
                                            <Button className="btn-dark">Cancel</Button>
                                        </Link>
                                        <Button
                                            type="submit"
                                            className="l-btn btn-gold btn-play"
                                            disabled={loading} >
                                            {loading ? (
                                            <Spinner animation="border" />
                                            ) : (
                                            "Play"
                                            )}
                                        </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
			            </div>
		            </div>
	          </div>
          </div>
      </div>
</Layout>
    )}
</>
    );
};

export default LudoCreateTable;