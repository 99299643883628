import React from "react";
import { Link } from "react-router-dom";
import winningAmount from "../../assets/images/popups/handshake2.gif";
import "./popup.css";

const FriendPopup = ({ userName }) => {
  return (
    <div className={`friend-bonus`}>
      <div className="friend-bonus-grid">
        <img
          src={winningAmount}
          alt="winningamount"
          className="wow animate__animated animate__shakeX "
        />
        <h2>Congratulations!!</h2>
        <p>
          You and <span>{userName}</span> are friends now, invite {userName} to
          play...
        </p>
        <Link to="">Invite Now</Link>
      </div>
    </div>
  );
};
export default FriendPopup;
