import React, { useState, useEffect } from "react";
import Loader from "../../loader/loader";
import info from "../../../assets/images/icons/info_30.png";
import { Button, Modal, Spinner } from "react-bootstrap";
import Follome from "../../followme/followme";
import axios from "axios";
import toast from "react-hot-toast";
import Lottie from "react-lottie";
import loading from "../../../assets/animation/loading.json";
import UrlConfig from "../../../utils/ApiConfig";
import { getUserStats } from '../../../dbFetchFunctions';
import firebase from "../../../firebase";

const loadingLottie = {
  loop: true,
  autoplay: true,
  animationData: loading,
};

const PeopleMayKnow = (props) => {
  const [idToken, setIdToken] = useState(localStorage.getItem("idtoken") || props.idToken);
  const [friendsYouKnow, setFriendsYouKnow] = useState([]);
  const [pending, setPending] = useState(true);
  const [isHidden, setIsHidden] = useState(true);
  const [isSpinning, setIsSpinning] = useState(false);
  const [followmeShow, setFollowmeShow] = useState(false);
  const [followDetails, setFollowDetails] = useState({});
  const [disableBtn, setDisableBtn] = useState("");
  const [isFollowing, setIsFollowing] = useState(false);

  const fetchData = async () => {
    try {
      const res = await axios.get(UrlConfig.peopleYouKnow, {
        params: { usid: props.uid, countryCode: props.countryCode },
      });
      if (res.data && res.data.error === "no error") {
        setFriendsYouKnow(res.data.suggested || []);
        localStorage.setItem("suggested", JSON.stringify(res.data.suggested || []));
        setIsHidden(false);
      } else {
        toast.error("Error fetching data", {
          style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
          },
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setPending(false);
      setIsSpinning(false);
    }
  };

  useEffect(() => {
    const suggested = localStorage.getItem("suggested");
    console.log("suggested:::" + suggested);
    if (suggested === null || suggested === "undefined" || suggested === "[]") {
      setIsSpinning(true);
      fetchData();
    } else {
      setFriendsYouKnow(JSON.parse(suggested));
      setPending(false);
      setIsHidden(false);
    }
  }, [props.uid]);

  useEffect(() => {
    const getIdToken = async () => {
      const token = await firebase.auth().currentUser.getIdToken();
      setIdToken(token);
    };
    getIdToken();
  }, []);

  const handleConnect = async (event) => {
    const [FUid, Fname] = event.split(",");
    setDisableBtn(FUid);
    
    try {
      const response = await axios.get(UrlConfig.sendFriendRequest, {
        params: { frId: FUid },
        headers: { wid: localStorage.getItem("walletid") },
        withCredentials: true,
        credentials: "include",
      });
      if (response.data && response.data.error === "no error") {
          toast.success("Friend request sent!", {
            style: {
              maxWidth: 800,
              fontSize: "14px",
              borderRadius: "15px",
            },
          });
        fetchData();
      } else {
          toast.error("Error sending friend request", {
            duration: 4000,
            style: {
              maxWidth: 800,
              fontSize: "14px",
              borderRadius: "15px",
            },
          });
      }
    } catch (error) {
      console.error("Error sending friend request:", error);
    } finally {
      setDisableBtn("");
    }
  };

  const handleFollowMe = async (event) => {
    const [Uid, nickname, avatar] = event.split(",");
    const fetchedUserStats = await getUserStats(Uid);
    let Hplayed = fetchedUserStats.total.HoursPlayed.toFixed(2) || 0; 
    let Friends = fetchedUserStats.social.friends.toLocaleString() || 0;
    let Level = fetchedUserStats.total.Level.toLocaleString() || 0;
    let Games = fetchedUserStats.total.games.toLocaleString() || 0;
    let Wins = fetchedUserStats.total.win.toLocaleString() || 0;
    let Losses = fetchedUserStats.total.lose || 0;
    setFollowDetails({ Uid, nickname, avatar, Hplayed, Friends, Level, Games, Wins, Losses });
    setFollowmeShow(true);
  };

  const followmeClose = () => setFollowmeShow(false);

  if (pending) {
    return <Loader />;
  }

  return (
    <div className="friends-card">
      <div className="friends-content">
        <h6>People you may know
          <i
            className={`fa fa-refresh refresh-pumk ${isSpinning ? 'spinning' : ''}`}
            aria-hidden="true"
            onClick={() => {
              setIsSpinning(true);
              fetchData();
            }}
          ></i>
        </h6>
      </div>
      <br />
      {!isHidden ? (
        <div className="friends-content">
          <div className="row">
            <div className="col-md-12 ml-auto mr-auto">
              <div className="you-know">
                {friendsYouKnow && friendsYouKnow.length > 0 ? friendsYouKnow.map((f, index) => (
                  <div id={f.uid} className="you-know-box" key={index}>
                    
                    <div
                      className="friend-pic"
                      onClick={() =>
                        handleFollowMe(`${f.uid},${f.name},${f.image}`)
                      }
                    >
                      <img
                        className="img-raised1"
                        src={f.image}
                        alt=" Avatar"
                      />
                    </div>
                    <div className="card-body">
                      <h5 className="card-category">
                        <img
                          className="card-icon"
                          onClick={() =>
                            handleFollowMe(`${f.uid},${f.name},${f.image}`)
                          }
                          src={info}
                          alt="info"
                        />{" "}
                        {f.name}
                      </h5>
                      <Button
                        className={`btn btn-gold btn-primary ${disableBtn === f.uid ? "disable-btn" : ""}`}
                        onClick={() => handleConnect(`${f.uid},${f.name}`)}
                      >
                        Connect
                      </Button>
                    </div>
                  </div>
                )) : <p>No suggestions available.</p>}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Spinner animation="border" />
      )}

      <Modal
        show={followmeShow}
        onHide={followmeClose}
        centered
        className="friends-popup"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Follome
            followId={followDetails.Uid}
            nick={followDetails.nickname}
            avatar={followDetails.avatar}
            isFollowing={isFollowing}
            Hplayed={followDetails.Hplayed}
            Friends={followDetails.Friends}
            Level={followDetails.Level}
            Games={followDetails.Games}
            Wins={followDetails.Wins}
            Losses={followDetails.Losses}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PeopleMayKnow;
