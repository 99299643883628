import axios from "axios";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import Lottie from "react-lottie";
import loading from "../../assets/animation/loading.json";
import "./report.css";

const loadingLottie = {
  loop: true,
  autoplay: true,
  animationData: loading,
};

const ReportPostPopup = ({ feedId, idToken, ownerId }) => {
  const [step, setStep] = useState("");
  const [loader, setLoader] = useState(false);
  const [reports, setReports] = useState([]);
  const handleStep = (value, final) => {
    if (reports.includes(value)) {
      setReports(reports.filter((el) => el !== value));
    } else {
      let rep = [...reports];
      rep.push(value);
      setReports(rep);
    }
    setStep(final || value);
  };

  const handleStepBack = () => {
    let rep = [...reports];
    rep.pop();
    if (rep.length) setStep(rep[rep.length - 1]);
    else setStep("");
    setReports(rep);
  };

  const getStepReport = () => {
    let content = "";
    switch (step) {
      case "Sexual content":
        content = (
          <div className="report-popup-content">
            <h4>What kind of Sexual content?</h4>
            <p>
              If you think this post is offensive or with potential violatiog
              content, please let us know.
            </p>

            <ul>
              <li onClick={() => handleStep("Sexually Suggestive", "final")}>
                Sexually Suggestive
              </li>
              <li onClick={() => handleStep("Sexual Activity", "final")}>
                Sexual Activity
              </li>
              <li onClick={() => handleStep("Sexual Exploitation", "final")}>
                Sexual Exploitation
              </li>
              <li onClick={() => handleStep("Sexual Services", "final")}>
                Sexual Services
              </li>
              <li onClick={() => handleStep("Involves a Child", "final")}>
                Involves a Child
              </li>
              <li onClick={() => handleStep("Something else", "final")}>
                Something else
              </li>
            </ul>
          </div>
        );
        break;
      case "Violence":
        content = (
          <div className="report-popup-content">
            <h4>What kind of violence?</h4>
            <p>
              If someone is in immediate danger, get help before reporting to
              Facebook. Don't wait.
            </p>

            <ul>
              <li onClick={() => handleStep("Violent Threat", "final")}>
                Violent Threat
              </li>
              <li onClick={() => handleStep("Animal Abuse", "final")}>
                Animal Abuse
              </li>
              <li onClick={() => handleStep("Sexual Violence", "final")}>
                Sexual Violence
              </li>
              <li onClick={() => handleStep("Something Else", "final")}>
                Something Else
              </li>
            </ul>
          </div>
        );
        break;
      case "False Information":
        content = (
          <div className="report-popup-content">
            <h4>What kind of False Information?</h4>
            <p>
              If someone is in immediate danger, get help before reporting to
              Facebook. Don't wait.
            </p>

            <ul>
              <li onClick={() => handleStep("Health", "final")}>Health</li>
              <li onClick={() => handleStep("Politics", "final")}>Politics</li>
              <li onClick={() => handleStep("Social Issue", "final")}>
                Social Issue
              </li>
              <li onClick={() => handleStep("Something Else", "final")}>
                Something Else
              </li>
            </ul>
          </div>
        );
        break;
      case "Spam":
        content = (
          <div className="report-popup-content">
            <h4>Spam</h4>
            <p>
              We don't allow things such as:
              <li>
                Buying, selling or giving away accounts, roles or permissions
              </li>
              <li>
                Encouraging people to engage with content under false pretences
              </li>
            </p>
            <div className="action">
              <button onClick={handleReport}>
                {loader ? <Spinner animation="border" /> : "Submit"}
              </button>
            </div>
          </div>
        );
        break;
      case "Unauthorized Sale":
        content = (
          <div className="report-popup-content">
            <h4>What kind of unauthorised sales?</h4>
            <p>
              If someone is in immediate danger, get help before reporting to
              Facebook. Don't wait.
            </p>

            <ul>
              <li onClick={() => handleStep("Drugs", "final")}>Drugs</li>
              <li onClick={() => handleStep("Weapons", "final")}>Weapons</li>
              <li onClick={() => handleStep("Endangered animals", "final")}>
                Endangered Animals
              </li>
              <li onClick={() => handleStep("Other animals", "final")}>
                Other animals
              </li>
              <li onClick={() => handleStep("Something Else", "final")}>
                Something Else
              </li>
            </ul>
          </div>
        );
        break;
      case "Hate Speech":
        content = (
          <div className="report-popup-content">
            <h4>What kind of hate speech?</h4>
            <p>
              If someone is in immediate danger, get help before reporting to
              Facebook. Don't wait.
            </p>

            <ul>
              <li onClick={() => handleStep("Race or Ethnicity", "final")}>
                Race or Ethnicity
              </li>
              <li onClick={() => handleStep("National Origin", "final")}>
                National Origin
              </li>
              <li onClick={() => handleStep("Religious Affiliation", "final")}>
                Religious Affiliation
              </li>
              <li onClick={() => handleStep("Social Caste", "final")}>
                Social Caste
              </li>
              <li onClick={() => handleStep("Sexual Orientation", "final")}>
                Sexual Orientation
              </li>
              <li onClick={() => handleStep("Sex or Gender Identity", "final")}>
                Sex or Gender Identity
              </li>
              <li onClick={() => handleStep("Disability or Disease", "final")}>
                Disability or Disease
              </li>
              <li onClick={() => handleStep("Something Else", "final")}>
                Something Else
              </li>
            </ul>
          </div>
        );
        break;
      case "Terrorism":
        content = (
          <div className="report-popup-content">
            <h4>Terrorism</h4>
            <p>
              We only remove content that goes against our Community Standards.
            </p>
            <h6>
              We remove content about any non-governmental group or person that
              engages in or supports planned acts of violence for political,
              religious or ideological reasons.
            </h6>

            <div className="action">
              <button onClick={handleReport}>
                {loader ? <Spinner animation="border" /> : "Submit"}
              </button>
            </div>
          </div>
        );
        break;
      case "final":
        content = (
          <div className="report-popup-content">
            <h4>Submit Report</h4>
            <p>Selected categories...</p>
            <h6>
              {reports.map((el) => (
                <span>&nbsp;{el}</span>
              ))}
            </h6>

            <div className="action">
              <button className="btn-gold" onClick={handleReport}>
                {loader ? <Spinner animation="border" /> : "Submit"}
              </button>
            </div>
          </div>
        );
        break;
      case "success":
        content = (
          <div className="report-popup-success">
            <h4>You have Report this Person successfully.</h4>
            <p>
              Our Standards explain what we do and don't allow on Facebook. We
              review and update our Standards regularly, with the help of
              experts.
            </p>
          </div>
        );
        break;
      default:
        content = (
          <div className="report-popup-content">
            <h4>Please select a problem</h4>
            <p>
              If someone is in immediate danger, get help before reporting to
              Facebook. Don't wait.
            </p>

            <ul>
              <li onClick={() => handleStep("Sexual content")}>
                Sexual content
              </li>
              <li onClick={() => handleStep("Violence")}>Violence</li>
              <li onClick={() => handleStep("False Information")}>
                False Information
              </li>
              <li onClick={() => handleStep("Spam")}>Spam</li>
              <li onClick={() => handleStep("Hate Speech")}>Hate Speech</li>
              <li onClick={() => handleStep("Terrorism")}>Terrorism</li>
            </ul>
          </div>
        );
    }
    return content;
  };

  const handleReport = async () => {
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
      id: "toast-loading",
      duration: 6000,
      style: {
        borderRadius: "5px",
        background: "#333",
        color: "#fff",
      },
    });
    setLoader(true);
    const res = await axios.get(
      "https://report-t3e66zpola-uc.a.run.app/reportContent",
      {
        headers: {
          idtoken: idToken,
          path: `messanger/${ownerId}/feed/${feedId}`,
        },
        params: {
          frId: ownerId,
          subject: reports.join(" "),
        },
      }
    );
    setLoader(false);
    if (res.data.error === "no error") {
      toast.success("Thank you for letting us know.", {
        id: "toast-success",
        style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
        },
      });
      setStep("success");
    } else if (res.data.error === "you already reported this path") {
      toast.error("You've already reported this.", {
        id: "toast-error",
        style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
        },
      });
      setStep("success");
    } else if (res.data.error !== "no error") {
      toast.error(res.data.error, {
        id: "toast-error",
        style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
        },
      });
    }
  };
  return (
    <div className="report-post-popup">
      <div className="report-popup">
        <div className="report-popup-header">
          <h2>Report</h2>
          {step !== "" && step !== "success" && (
            <span onClick={handleStepBack}>
              <i className="fa fa-angle-left" />
            </span>
          )}
        </div>

        {getStepReport(step)}
      </div>
    </div>
  );
};
export default ReportPostPopup;
