import { ethers } from "ethers";

export const getCookie = (name) => {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };
  
  export const deleteCookie = (name) => {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  };

// Define a class for Game

    export const convertToObject = (gameId, gameType, minBet, autoHandStart, players, lastModified, admin, media, isPublic, rTimeout) => ({
      gameId,
      gameType,
      minBet: ethers.utils.formatEther(minBet), // Convert BigNumber to Number
      buyIn: ethers.utils.formatEther(minBet) * 10,
      autoHandStart: autoHandStart,
      players: players.map(player => {
            return {
                playerId: player[0],
                wallet: ethers.utils.formatEther(player[1]), // Convert BigNumber to Number
                walletAddress: player[2],
                photoUrl: player[3],
                name: player[4],
            };
        }),
      lastModified: ethers.utils.formatEther(lastModified), // Convert BigNumber to Number
      admin: admin,
      media: media,
      isPublic: isPublic,
      rTimeout: ethers.utils.formatEther(rTimeout), // Convert BigNumber to Number
})


export const getProfileImage = (img, countryCode) => {
  if(img.includes("yelloPhantom")){
    return `https://storage.cloud.google.com/weje-2023.appspot.com/Countryflags/${countryCode}.png`
  }else{
    return img;
  }
}



export const simplifyTicket = (ticket) => {
  let tick ={
    betId: ethers.BigNumber.from(ticket.betId).toNumber(), // Convert BigNumber to Number,
    amount: ethers.utils.formatEther(ticket.amount), // Convert BigNumber to Number,
    matches: ticket.matches.map(match => ({
      gameId: ethers.BigNumber.from(match.gameId).toNumber(),
      isDrawable:match.isDrawable,
      home: {
        name: match.homeName,
        id: ethers.BigNumber.from(match.homeId).toNumber(),
        logo: match.homeLogo
      },
      away: {
        name: match.awayName,
        id: ethers.BigNumber.from(match.awayId).toNumber(),
        logo: match.awayLogo
      }
    })),
    pool: ethers.utils.formatEther(ticket.pool), // Convert BigNumber to Number,
    status: ethers.BigNumber.from(ticket.status).toNumber(), // Convert BigNumber to Number,
    startDate: ethers.BigNumber.from(ticket.startDate).toNumber(), // Convert BigNumber to Number,
    endDate: ethers.BigNumber.from(ticket.endDate).toNumber(), // Convert BigNumber to Number
    users: ticket.userSelections.map(user => ({
      userId: user.uid,
      name: user.name,
      photoUrl: user.photoUrl,
      selections: user.selections.map(s => ({
        gameId: ethers.BigNumber.from(s.gameId).toNumber(),
        choice: ethers.BigNumber.from(s.choice).toNumber()
      }))
    })),
    results: ticket.results.map(s => ({
      gameId: ethers.BigNumber.from(s.gameId).toNumber(),
      choice: ethers.BigNumber.from(s.choice).toNumber()
    })) || []
  };
   return tick;
}


export function generateUniqueId() {
  const timestamp = Date.now(); // Current timestamp in milliseconds
  const randomNum = Math.floor(Math.random() * 1000000); // Random number between 0 and 999999

  // Combine the components to form a unique integer ID
  const uniqueId = (timestamp * 1000) + (randomNum * 100);

  return uniqueId;
}