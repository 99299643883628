import React, { Component } from "react";
import { isMobile, browserName, getUA } from "react-device-detect";
import { withRouter } from "react-router-dom";
import axios from "axios";
import ConnectButton from "../cryptoWallet/ConnectButton";
import UrlConfig from '../../utils/ApiConfig';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xtkn: localStorage.getItem("xtkn"),
      deviceId: browserName + "_" + getUA.replace(/[^A-Z0-9]/gi, "_"),
      ip: "",
      countryCode: "",
      isMobile: isMobile,
      fmctoken: this.props.fmctoken,
    };
  }

  async componentDidMount() {
    try {
      // Geolocation call    
      const res = await axios.get(UrlConfig.geoLocationUrl);
      const values = res.data.split("\n");
      let obj = {};
      values.forEach((value) => {
        obj[value.split("=")[0]] = value.split("=")[1];
      });
      let data = { IPv4: obj.ip, country_code: obj.loc };
      this.setState({
        ip: data.IPv4,
        countryCode: obj.loc
      });
    } catch (error) {
      console.error("Error fetching geolocation data:", error);
      // Handle error by setting empty default values
      this.setState({
        ip: "",
        countryCode: ""
      });
    }
    // Capture UTM Parameters
    this.captureUTMParameters();
  }

  // Method to capture UTM parameters and store them in session storage
  captureUTMParameters() {
    const getParameterByName = (name) => {
      name = name.replace(/[\[\]]/g, "\\$&");
      const url = window.location.href;
      const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
      const results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    };
    const utmSource = getParameterByName("utm_source");
    const utmCampaign = getParameterByName("utm_campaign");
    const utmMedium = getParameterByName("utm_medium");
    if (utmSource) {
      sessionStorage.setItem("utm_source", utmSource);
    }
    if (utmCampaign) {
      sessionStorage.setItem("utm_campaign", utmCampaign);
    }
    if (utmMedium) {
      sessionStorage.setItem("utm_medium", utmMedium);
    }
  }

  render() {
    return (
      <>
        <div className="profile register-page signup-step-one">
          <div className="google-login">
            <ConnectButton 
              xtkn={this.state.xtkn}
              deviceId={this.state.deviceId}
              ip={this.state.ip}
              isMobile={this.state.isMobile}
              countryCode={this.state.countryCode}
              fmctoken={this.state.fmctoken} 
            />
            <div className="divider">
              <span>DeFi Gaming</span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Register);
