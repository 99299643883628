import {useContext, useEffect, useState} from 'react';
import { Button } from "react-bootstrap";
import { MainContext } from '../../context';
import { useWeb3React } from "@web3-react/core";
import { metaMask } from './metamask';
import axios from 'axios';
import { ethers } from "ethers";
import toast from "react-hot-toast";
import connecting from "../../assets/animation/connecting";
import Lottie from "react-lottie";
import metalogo from "../../assets/images/icons/metamask-icon.png";
import trustlogo from "../../assets/images/icons/trustwallet-icon.png";
import coinbaselogo from "../../assets/images/icons/coinsbase.png";
import UrlConfig from "../../utils/ApiConfig";
import { isMobile } from 'react-device-detect';


const connectOptions = {
    loop: true,
    autoplay: true,
    animationData: connecting,
  };


function ReConnect() {
  const { firebaseUser } = useContext(MainContext);
  const [balanceInUSD, setBalanceInUSD] = useState(0);
  const { account, provider } = useWeb3React();
  const [balance, setBalance] = useState('');
  const pathname = window.location.pathname;
  const [connected, setConnected] = useState(false);
  const [userProvider, setuserProvider] = useState('');
  const isWalletConnected = localStorage.getItem('isWalletConnected');
  const context = useContext(MainContext);

  useEffect(() => {
    let isMounted = true; // Track if the component is mounted

    // Get provider firebase data
    if (!context.firebaseUser || !context.token) return;
    const { baseStats } = context;
    if (baseStats) {
        const provider = baseStats.userDoc.widProvider;
        if (isMounted) setuserProvider(baseStats.userDoc.widProvider);
        if (provider !== ("MetaMask" || "Coinbase" || "TrustWallet")) {
            // Get provider value from local storage
            if (isMounted) setuserProvider(localStorage.getItem("provider"));
        }
    }

    // Check wallet connection
    if (window.ethereum) {
        window.ethereum.request({ method: 'eth_accounts' }).then(handleAccountsChanged).catch(console.error);
        function handleAccountsChanged(accounts) {
            if (accounts.length === 0) {
                localStorage.setItem('isWalletConnected', false);
            }
        }
    } else {
        localStorage.setItem('isWalletConnected', false);
    }

    const getMaticUsdPrice = async (matic) => {
        const res = await axios.get(UrlConfig.maticUsdPriceUrl);
        if (res.data && isMounted) {
            const curentPrice = res.data.market_data.current_price.usd;
            setBalanceInUSD((curentPrice * matic).toFixed(2));
        }
    };

    const getWalletBalance = async () => {
        if (account) {
            localStorage.setItem('isWalletConnected', true);
            const value = await provider?.getBalance(account);
            if (value && isMounted) {
                setBalance(Number(ethers.utils.formatEther(value)).toFixed(2));
                getMaticUsdPrice(Number(ethers.utils.formatEther(value)));
            }
            document.cookie = `walletid= ${account}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
            localStorage.setItem('walletid', account);
        } else {
            localStorage.setItem('isWalletConnected', false);
        }
    };

    if (!account) {
        localStorage.setItem('isWalletConnected', false);
        handleConnectWallet();
    } else if (account) {
        localStorage.setItem('isWalletConnected', true);
        getWalletBalance();
        if (!firebaseUser) {
            // handleLogin();
        }
    }

    return () => {
        isMounted = false; // Cleanup: mark component as unmounted
    };
}, [account, provider, firebaseUser]);



      async function handleConnectWallet () {
                if ( window.ethereum ) {
                setConnected(true);
                const isMetaMask = window.ethereum.isMetaMask;
                const isCoinbase = window.ethereum.isCoinbaseWallet;
                const isTrust = window.ethereum.isTrust;
                const localProvider = localStorage.getItem("provider");
                if ((isMetaMask && localProvider === "MetaMask") ||
                    (isCoinbase && localProvider === "Coinbase") || 
                    (isTrust && localProvider === "TrustWallet")) 
                    {
                    try {
                        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                        const compare = accounts[0].toString().toLowerCase();
                        const compare2 = localStorage.getItem('walletid').toString().toLowerCase();
                        if (compare === compare2) {
                            localStorage.setItem('isWalletConnected', true);
                            metaMask.activate({
                            chainId: 137, // Polygon Mainnet 137
                            chainName: "Polygon Mainnet",
                            nativeCurrency: {
                                name: 'Matic',
                                symbol: 'MATIC',
                                decimals: 18,
                            },
                            rpcUrls: ["https://polygon-rpc.com/"], // TestNet - "https://rpc-amoy.polygon.technology/"
                            blockExplorerUrls: ["https://polygonscan.com/"] 
                            });
                            localStorage.setItem('isWalletConnected', true);
                            toast.success("Connected!", {
                                duration: 4000,
                                id: "toast-success",
                                style: {
                                  maxWidth: 800,
                                  fontSize: "14px",
                                  borderRadius: "15px",
                                },
                              });
                        } else {
                            toast.error("The wallet ID associated incorrect, please connect " + userProvider + " Ethereum wallet provider", {
                                duration: 4000,
                                id: "toast-error",
                                style: {
                                  maxWidth: 800,
                                  fontSize: "14px",
                                  borderRadius: "15px",
                                },
                              });
                           }
                        } catch (error) {
                            console.error("Error connecting:", error);
                        }
                } else {
                    toast.error("Please activate " + userProvider + " Ethereum wallet", {
                        duration: 4000,
                        id: "toast-error",
                        style: {
                          maxWidth: 800,
                          fontSize: "14px",
                          borderRadius: "15px",
                        },
                      });
                }   
            } else {
                localStorage.setItem('isWalletConnected', false);
                toast.error("No Ethereum wallet provider detected", {
                    duration: 4000,
                    id: "toast-error",
                    style: {
                      maxWidth: 800,
                      fontSize: "14px",
                      borderRadius: "15px",
                    },
                  });
            }
      }


    if (pathname === "/profile") {
        return (
            <>
                {connected && isWalletConnected == "false" ? (
                    <div className="connectOptions">
                        <Lottie options={connectOptions} height={250} width={250} />
                    </div>
                ) : null}
                {isWalletConnected == "true" ? (
                    <div>
                    <div>
                        <small><b className={userProvider}>{userProvider}</b></small><br/>
                        <small style={{fontSize: "0.95em"}}><span style={{color:'#8461dc'}}>Matic:</span> {balance}</small><br/>
                        <small style={{fontSize: "0.95em"}}><span style={{color:'#8461dc'}}>USD:</span> {balanceInUSD}</small>
                    </div>
                    </div>
                ) : (
                <>
                <Button
                    id={userProvider}
                    className="google-login-btn"
                    onClick={handleConnectWallet} >
                        Connect Wallet
                        { userProvider === "MetaMask" ? (
                            <img src={metalogo} className="crypto-icon" alt="MetaMask" id="metamask-icon" />
                        ) : null}
                        { userProvider === "TrustWallet" ? (
                            <img src={trustlogo} className="crypto-icon" alt="TrustWallet" id="trust-icon" />
                        ) : null}
                        { userProvider === "Coinbase" ? (
                            <img src={coinbaselogo} className="crypto-icon" alt="Coinbase" id="coinbase-icon" />
                        ) : null}
                </Button>
                </>
            )}
            </>
        );
    } else {
        return (
            <>
                <Button
                    id={userProvider}
                    className="google-login-btn"
                    onClick={handleConnectWallet} >
                        Connect Wallet
                        { userProvider === "MetaMask" ? (
                            <img src={metalogo} className="crypto-icon" alt="MetaMask" id="metamask-icon" />
                        ) : null}
                        { userProvider === "TrustWallet" ? (
                            <img src={trustlogo} className="crypto-icon" alt="TrustWallet" id="trust-icon" />
                        ) : null}
                        { userProvider === "Coinbase" ? (
                            <img src={coinbaselogo} className="crypto-icon" alt="Coinbase" id="coinbase-icon" />
                        ) : null}
                </Button>
            </>
      );
    }
}

export default ReConnect;