import React, { Component } from "react";
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  TumblrShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  TumblrIcon,
  FacebookMessengerIcon,
} from "react-share";
import "./share.css";

class Share extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signedin: false,
      uid: this.props.shareuserId,
    };
  }

  render() {
    const shareUrl = "https://weje.com/?adsId=";
    const title =
      "Start playing live games today with 10,000 Free Coins";
    const adsId = this.state.uid;

    return (
      <div className="row buttons_group">
        <div className="share_button">
          <WhatsappShareButton
            url={shareUrl + adsId}
            title={title}
            separator=":: "
            className="share_button_share-button"
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </div>

        <div className="share_button">
          <FacebookShareButton url={shareUrl + adsId} quote={title}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
        </div>

        <div className="share_button">
          <FacebookMessengerShareButton
            url={shareUrl + adsId}
            appId="183626020236510"
            className="share-button"
          >
            <FacebookMessengerIcon size={32} round />
          </FacebookMessengerShareButton>
        </div>

        <div className="share_button">
          <TwitterShareButton
            url={shareUrl + adsId}
            title={title}
            className="share_button_share-button"
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </div>

        <div className="share_button">
          <TelegramShareButton
            url={shareUrl + adsId}
            title={title}
            className="share_button_share-button"
          >
            <TelegramIcon size={32} round />
          </TelegramShareButton>
        </div>

        <div className="share_button">
          <LinkedinShareButton
            url={shareUrl + adsId}
            className="share_button_share-button"
            title={title}
          >
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>

        <div className="share_button">
          <TumblrShareButton
            url={shareUrl + adsId}
            title={title}
            className="share_button_share-button"
          >
            <TumblrIcon size={32} round />
          </TumblrShareButton>
        </div>
      </div>
    );
  }
}

export default Share;
