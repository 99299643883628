import React from "react";
import "./report.css";
import { Spinner, Button, Form } from "react-bootstrap";

const BlockUserPopUp = ({ nickname, handleBlock, handleClose,spinnerLoading }) => {
  return (
    <>
	 <Form onSubmit={handleBlock}>
                  <div className="block">
                    <small>
                      Blocking{" "}
                      <span style={{ color: "#8461dc" }}>
                        @{nickname.toUpperCase()}
                      </span>{" "}
                      on <span style={{ color: "#8461dc" }}>WeJe</span>{" "}
                      will completely blind that person within your profile and
                      theirs, they will not come up in your network. <br></br>{" "}
                      When a user is blocked you will be able to delete him
                      permanently.
                    </small>
                    <br></br>
                    <Form.Label>
                      Block{" "}
                      <b style={{ color: "#8461dc" }}>
                        @{nickname.toUpperCase()}?
                      </b>
                    </Form.Label>
                    <br></br>

                    <Button
                      type="submit"
                      className="btn-dark"
                      disabled={spinnerLoading}
                    >
                       {spinnerLoading ? <Spinner animation="border" /> : 'Block'}
                    </Button>
                  </div>
                </Form>
	</>
  );
};
export default BlockUserPopUp;
